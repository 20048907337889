import { useEffect } from "react";
import PropTypes from "prop-types";
import List from "./List";

const ChangelogPage = (props) => {
  const setTitle = props.setRouteTitle;
  useEffect(() => {
    setTitle("Historial de Cambios");
  }, [setTitle]);

  return (
    <div className="p-10">
      <List user={props.user} />
    </div>
  );
};

ChangelogPage.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func.isRequired,
};

export default ChangelogPage;
