import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AppBar, Tab, Tabs, Fab, Tooltip } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers";
import SaveIcon from "@material-ui/icons/Save";
import UpdateIcon from "@material-ui/icons/Update";
import { useHistory, useParams } from "react-router-dom";
import { isEmpty, isNull, isUndefined } from "lodash";

import useSubData from "../../../../hooks/useSubData";
import BackDrop from "../../../Shared/BackDrop/BackDrop";
import defaults from "./Constants/Defaults";
import schema from "./Constants/Schema";
import PatientInfo from "./Tabs/PatientInfo";
import InitialStatus from "./Tabs/InitialStatus";
import ExternalHelps from "./Tabs/ExternalHelps";
import CarerInfo from "./Tabs/CarerInfo";
import FamilyInfo from "./Tabs/FamilyInfo";
import HouseInfo from "./Tabs/HouseInfo";
import HousingConditions from "./Tabs/HousingConditions";
import Observations from "./Tabs/Observations";
import TabPanel from "../TabPanel";
import { useGlobal } from "../../../../context/GlobalContext";
import reorderPatient from "../../../../global/utils/reorderPatient";
import preparePatient from "../../../../global/utils/preparePatient";
import dataService from "../../../../services/dataService";
import superjson from "superjson";
import cleanUndefs from "../../../../global/utils/cleanUndefs";
import useBetterData from "../../../../hooks/useBetterData";
import { debounce } from "../../../../global/utils/debounce";
import ConfirmDialog from "../../../Shared/ConfirmDialog";
import { vaccinesList } from "./Constants/VaccinesList";
import { externalHelpsList } from "./Constants/ExternalHelpsList";

// Experimental Features Flags
const allowDrafts = false;

const Details = (props) => {
  // Params
  let { id } = useParams();

  // Props
  const { user, setRouteTitle } = props;

  // Data
  const { setLoading, showSnackBar } = useGlobal();
  const { data, isLoading } = useSubData("patients", "currentPatient.id", id);
  const {
    data: departmentsList,
    isLoading: departmentsListLoading,
    refetch: departmentsRefetch,
  } = useBetterData({
    collection: "departamentos",
    orderOptions: {
      orderField: "name",
    },
  });
  const {
    data: municipioList,
    isLoading: municipioListLoading,
    refetch: municipioRefetch,
  } = useBetterData({
    collection: "municipios",
  });
  const {
    data: cityList,
    isLoading: cityListLoading,
    refetch: cityRefetch,
  } = useBetterData({
    collection: "cities",
  });
  const {
    data: epsList,
    isLoading: epsListLoading,
    refetch: epsRefetch,
  } = useBetterData({
    collection: "eps",
  });
  const {
    data: diagnosticsList,
    isLoading: diagnosticsListLoading,
    refetch: diagnosticsListRefetch,
  } = useBetterData({
    collection: "pathologies",
  });
  const {
    data: specialityList,
    isLoading: specialityListLoading,
    refetch: specialityListRefetch,
  } = useBetterData({
    collection: "speciality",
  });
  const {
    data: doctorsList,
    isLoading: doctorsListLoading,
    refetch: doctorsListRefetch,
  } = useBetterData({
    collection: "medicals",
  });
  const {
    data: centrosAplicacionList,
    isLoading: centrosAplicacionListLoading,
    refetch: centrosAplicacionListRefetch,
  } = useBetterData({
    collection: "centrosAplicacion",
  });

  // Hooks
  const history = useHistory();
  const setTitle = setRouteTitle;
  const [valueTab, setValueTab] = useState(0);
  const [patientInfo, setPatientInfo] = useState(null);
  const [patientTmp, setPatientTmp] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [patientDataLoaded, setPatientDataLoaded] = useState(false);

  // Form
  const {
    register,
    control,
    errors,
    setValue,
    reset,
    handleSubmit,
    watch,
    getValues,
  } = useForm({
    mode: "all",
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: defaults,
  });
  const watchAllFields = watch();
  const previousValues = useRef(watchAllFields);

  // Methods

  const saveToLocalStorage = debounce((data) => {
    if (
      !showConfirm &&
      JSON.stringify(data) !== JSON.stringify(previousValues.current)
    ) {
      localStorage.setItem(
        `patientDraft_tab_${valueTab}`,
        JSON.stringify(data)
      );
      previousValues.current = data;
    }
  }, 1000);

  const goToPatientsPage = useCallback(() => {
    history.push("/patients", {
      direction: "back",
    });
  }, [history]);

  const successPatient = (isEdit) => {
    localStorage.setItem("refetchPatients", true);
    showSnackBar(`Paciente ${isEdit ? "actualizado" : "creado"} correctamente`);
    setLoading(false);
    goToPatientsPage();
  };

  const errorPatient = (error, isEdit) => {
    setLoading(false);
    showSnackBar(
      `Error al intentar ${isEdit ? "actualizar" : "crear"} el Paciente`,
      "error"
    );
    console.error(error);
  };

  const onSubmit = async (submitData) => {
    setLoading(true);
    const patientData = preparePatient(
      submitData,
      user.uid,
      vaccinesList,
      externalHelpsList
    );
    console.log("Patient Data onSubmit: ", patientData);
    if (id === "0" || id === "new") {
      dataService
        .createDocument(patientData, "patients")
        .then(() => {
          successPatient(false);
        })
        .catch((error) => {
          errorPatient(error, false);
        });
    } else {
      patientData.id = data[0].id;
      patientData.creationDate = data[0].creationDate;
      patientData.updateDate = new Date();
      if (
        user.role === "admin" ||
        user.role === "coordinadordezona" ||
        user.role === "coordinadoradezona" ||
        user.role === "administrador" ||
        user.role === "director"
      ) {
        patientData.userId = data[0].userId;
      }
      dataService
        .updateDocument(patientData, "patients")
        .then(() => {
          successPatient(true);
        })
        .catch((error) => {
          errorPatient(error, true);
        });
    }
  };

  // Callbacks

  const savePatient = async () => {
    setLoading(true);

    const patientValues = {
      ...patientTmp,
      ...getValues(),
    };
    const name = getValues("name");
    const patientId = getValues("id");
    const statusData = getValues("status");
    if (
      !isEmpty(statusData) &&
      statusData === "SD" &&
      !isEmpty(name) &&
      !isEmpty(patientId) &&
      id === "0"
    ) {
      patientValues.id = patientId;
      patientValues.name = name;
      const patientData = preparePatient(patientValues, user.uid);
      dataService
        .createDocument(patientData, "patients")
        .then(() => {
          localStorage.setItem("refetchPatients", true);
          showSnackBar(`Paciente creado correctamente`);
          setLoading(false);
          goToPatientsPage();
        })
        .catch((error) => {
          showSnackBar(`No fue posible crear el paciente`, "error");
          setLoading(false);
          console.error(error);
        });
    } else if (
      !isEmpty(statusData) &&
      statusData === "SD" &&
      !isEmpty(name) &&
      !isEmpty(patientId) &&
      id !== "0"
    ) {
      patientValues.id = patientId;
      patientValues.name = name;
      const patientData = preparePatient(patientValues, user.uid);
      patientData.id = data[0].id;
      patientData.creationDate = data[0].creationDate;
      patientData.updateDate = new Date();
      patientData.userId = data[0].userId;
      const patientDataClean = cleanUndefs(patientData);
      dataService
        .updateDocument(patientDataClean, "patients")
        .then(() => {
          localStorage.setItem("refetchPatients", true);
          showSnackBar(`Paciente actualizado correctamente`);
          setLoading(false);
          goToPatientsPage();
        })
        .catch((error) => {
          showSnackBar(`No fue posible actualizar el paciente`, "error");
          setLoading(false);
          console.error(error);
        });
    } else {
      setLoading(false);
      showSnackBar(
        `Hay campos requeridos sin diligenciar en la ficha del Paciente`,
        "error"
      );
    }
  };

  // Handlers

  const handleFormValues = useCallback(
    (field, value) => {
      setValue(field, value);
      setPatientInfo((prevState) => ({
        ...prevState,
        [field]: value,
      }));
      setPatientTmp((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    },
    [setValue]
  );

  const handleTabChange = (_event, value) => {
    const patientValues = getValues();
    setPatientTmp((prevState) => ({
      ...prevState,
      ...patientValues,
    }));
    setValueTab(value);
  };

  const handleListRefetch = (list) => {
    switch (list) {
      case "departamentos":
        departmentsRefetch();
        break;
      case "municipios":
        municipioRefetch();
        break;
      case "cities":
        cityRefetch();
        break;
      case "eps":
        epsRefetch();
        break;
      case "pathologies":
        diagnosticsListRefetch();
        break;
      case "speciality":
        specialityListRefetch();
        break;
      case "medicals":
        doctorsListRefetch();
        break;
      case "centrosAplicacion":
        centrosAplicacionListRefetch();
        break;
      default:
        break;
    }
  };

  // Check if patient form data is loading
  const loadingStatus = useCallback(() => {
    return (
      isLoading &&
      departmentsListLoading &&
      municipioListLoading &&
      cityListLoading &&
      epsListLoading &&
      diagnosticsListLoading &&
      specialityListLoading &&
      doctorsListLoading &&
      centrosAplicacionListLoading
    );
  }, [
    isLoading,
    departmentsListLoading,
    municipioListLoading,
    cityListLoading,
    epsListLoading,
    diagnosticsListLoading,
    specialityListLoading,
    doctorsListLoading,
    centrosAplicacionListLoading,
  ]);

  const checkPatientDraft = useCallback(() => {
    const patientInfo = JSON.parse(localStorage.getItem(`patientDraft_tab_0`));
    return !!patientInfo?.id;
  }, []);

  const loadFromLocalStorage = useCallback(() => {
    let baseTab = 2;
    let carerInfo = {};
    const currentPatient = JSON.parse(
      localStorage.getItem(`patientDraft_tab_0`)
    );
    const initialStatus = JSON.parse(
      localStorage.getItem(`patientDraft_tab_1`)
    );
    if (currentPatient.needCarer) {
      baseTab++;
      carerInfo = JSON.parse(
        localStorage.getItem(`patientDraft_tab_${baseTab}`)
      );
    }
    const familyInfo = JSON.parse(
      localStorage.getItem(`patientDraft_tab_${baseTab + 1}`)
    );
    const houseInfo = JSON.parse(
      localStorage.getItem(`patientDraft_tab_${baseTab + 2}`)
    );
    const housingConditions = JSON.parse(
      localStorage.getItem(`patientDraft_tab_${baseTab + 3}`)
    );
    const observations = JSON.parse(
      localStorage.getItem(`patientDraft_tab_${baseTab + 4}`)
    );

    const preparedPatient = {
      ...currentPatient,
      ...initialStatus,
      ...carerInfo,
      ...familyInfo,
      ...houseInfo,
      ...housingConditions,
      ...observations,
    };
    setPatientInfo(preparedPatient);
    setPatientTmp(preparedPatient);
    reset(preparedPatient);
  }, [reset]);

  const deletePatientDraft = useCallback(() => {
    for (let i = 0; i <= 6; i++) {
      localStorage.removeItem(`patientDraft_tab_${i}`);
    }
  }, []);

  // Effects

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setTitle([
      {
        title: "Pacientes",
        id: "patients",
      },
      {
        title: `${
          id !== "0"
            ? "Editar Paciente" +
              (!isUndefined(data) && !isEmpty(data)
                ? " " + data[0]?.currentPatient?.name?.toUpperCase()
                : "")
            : "Nuevo Paciente"
        }`,
        id: "",
      },
    ]);
  }, [setTitle, id, data]);

  useEffect(() => {
    if (!loadingStatus()) {
      if (data && data.length > 0) {
        const patientData = reorderPatient(data[0]);
        setPatientInfo(patientData);
        setPatientTmp(patientData);
        reset(patientData);
        setPatientDataLoaded(true);
      } else {
        if (id === "0") {
          setPatientInfo(defaults);
          setPatientTmp(defaults);
          reset(defaults);
        }
        setPatientDataLoaded(true);
      }
    }
  }, [data, loadingStatus, reset, id, checkPatientDraft]);

  useEffect(() => {
    if (allowDrafts) {
      saveToLocalStorage(watchAllFields);
    }
  }, [saveToLocalStorage, watchAllFields]);

  useEffect(() => {
    if (patientInfo && typeof patientInfo?.status !== "undefined") {
      if (patientInfo?.status === "SD") {
        console.warn("Paciente sin datos");
      } else {
        if (!isEmpty(errors)) {
          console.error("Hay errores: ", errors);
          showSnackBar(
            `Hay campos requeridos sin diligenciar en la ficha del Paciente`,
            "error"
          );
        }
      }
    }
  }, [errors, patientInfo, showSnackBar]);

  useEffect(() => {
    if (patientInfo && typeof patientInfo?.familyList === "object") {
      const familyList = superjson.stringify(patientInfo?.familyList);
      handleFormValues("familyList", familyList);
    }
  }, [patientInfo, handleFormValues]);

  // Renders

  const a11yProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  };

  const renderForm = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="flex-grow w-full bg-paper mt-8"
      >
        <AppBar
          position="fixed"
          color="default"
          className={"sm:!mt-16 sm:!pl-11"}
        >
          <Tabs
            value={valueTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Información del Paciente" {...a11yProps(0)} />
            <Tab label="Estatus Inicial" {...a11yProps(1)} />
            <Tab label="Ayudas Externas" {...a11yProps(2)} />
            {patientInfo?.needCarer === true && (
              <Tab label="Información Cuidador" {...a11yProps(3)} />
            )}
            <Tab
              label="Información Familiar"
              {...a11yProps(patientInfo?.needCarer === false ? 3 : 4)}
            />
            <Tab
              label="Información De Vivienda"
              {...a11yProps(patientInfo?.needCarer === false ? 4 : 5)}
            />
            <Tab
              label="Condiciones Habitacionales"
              {...a11yProps(patientInfo?.needCarer === false ? 5 : 6)}
            />
            <Tab
              label="Observaciones"
              {...a11yProps(patientInfo?.needCarer === false ? 6 : 7)}
            />
          </Tabs>
        </AppBar>
        {!isNull(patientInfo) && patientDataLoaded && (
          <div>
            <TabPanel value={valueTab} index={0} nomargin={"true"}>
              <PatientInfo
                role={user.role}
                user={user}
                control={control}
                errors={errors}
                register={register}
                patientInfo={patientInfo}
                departmentsList={departmentsList}
                municipioList={municipioList}
                cityList={cityList}
                handleListRefetch={handleListRefetch}
                handleFormValues={handleFormValues}
                watch={watch}
                getValues={getValues}
              />
            </TabPanel>
            <TabPanel value={valueTab} index={1} nomargin={"true"}>
              <InitialStatus
                user={user}
                control={control}
                errors={errors}
                register={register}
                patientInfo={patientInfo}
                epsList={epsList}
                diagnosticsList={diagnosticsList}
                specialityList={specialityList}
                doctorsList={doctorsList}
                centrosAplicacionList={centrosAplicacionList}
                handleListRefetch={handleListRefetch}
                handleFormValues={handleFormValues}
                getValues={getValues}
              />
            </TabPanel>
            <TabPanel value={valueTab} index={2} nomargin={"true"}>
              <ExternalHelps
                user={user}
                control={control}
                errors={errors}
                register={register}
                patientInfo={patientInfo}
                epsList={epsList}
                diagnosticsList={diagnosticsList}
                specialityList={specialityList}
                doctorsList={doctorsList}
                centrosAplicacionList={centrosAplicacionList}
                handleListRefetch={handleListRefetch}
                handleFormValues={handleFormValues}
                getValues={getValues}
                watch={watch}
              />
            </TabPanel>
            {patientInfo?.needCarer === true && (
              <TabPanel value={valueTab} index={3} nomargin={"true"}>
                <CarerInfo
                  user={user}
                  control={control}
                  role={user.role}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  patientInfo={patientInfo}
                  setPatientInfo={setPatientInfo}
                  handleListRefetch={handleListRefetch}
                  handleFormValues={handleFormValues}
                  epsList={epsList}
                  getValues={getValues}
                  watch={watch}
                />
              </TabPanel>
            )}
            <TabPanel
              value={valueTab}
              index={patientInfo?.needCarer === false ? 3 : 4}
              nomargin={"true"}
            >
              <FamilyInfo
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                patientInfo={patientInfo}
                setPatientInfo={setPatientInfo}
                handleFormValues={handleFormValues}
                getValues={getValues}
              />
            </TabPanel>
            <TabPanel
              value={valueTab}
              index={patientInfo?.needCarer === false ? 4 : 5}
              nomargin={"true"}
            >
              <HouseInfo
                control={control}
                errors={errors}
                register={register}
                patientInfo={patientInfo}
                handleFormValues={handleFormValues}
                getValues={getValues}
              />
            </TabPanel>
            <TabPanel
              value={valueTab}
              index={patientInfo?.needCarer === false ? 5 : 6}
              nomargin={"true"}
            >
              <HousingConditions
                setValue={setValue}
                register={register}
                patientInfo={patientInfo}
                handleFormValues={handleFormValues}
                getValues={getValues}
              />
            </TabPanel>
            <TabPanel
              value={valueTab}
              index={patientInfo?.needCarer === false ? 6 : 7}
              nomargin={"true"}
            >
              <Observations
                isNew={id === "0"}
                patientInfo={patientInfo}
                handleFormValues={handleFormValues}
                getValues={getValues}
                control={control}
              />
            </TabPanel>
          </div>
        )}
        <Tooltip
          title={id !== "0" ? "Actualizar" : "Guardar"}
          placement="left"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 99999,
          }}
        >
          {patientInfo?.status === "SD" ? (
            <Fab
              aria-label={"save"}
              type="button"
              color="secondary"
              onClick={savePatient}
            >
              <SaveIcon />
            </Fab>
          ) : (
            <Fab aria-label={"save"} type="submit" color="secondary">
              {id !== "0" ? <UpdateIcon /> : <SaveIcon />}
            </Fab>
          )}
        </Tooltip>
      </form>
    );
  };

  return (
    <div className="p-10">
      {loadingStatus() && isNull(patientInfo) && !patientDataLoaded ? (
        <BackDrop show={true} />
      ) : (
        <div>{renderForm()}</div>
      )}
      {showConfirm && (
        <ConfirmDialog
          defaultTitle={false}
          open={showConfirm}
          handleClose={() => setShowConfirm(false)}
          handleCancel={deletePatientDraft}
          doAction={loadFromLocalStorage}
          elementTitle={"Borrador de Paciente detectado"}
          content={"¿Desea cargar el borrador del paciente en la ficha?"}
          isButtonWarning={false}
        />
      )}
    </div>
  );
};

export default Details;
