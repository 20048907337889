import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Users,
  Pill,
  Settings,
  Friends,
  Checkbox,
  ChartBar,
  Home,
  Stethoscope,
  School,
  BuildingHospital,
} from "tabler-icons-react";
import HealingIcon from "@material-ui/icons/Healing";
import MobileBar from "./MobileBar";
import DesktopBar from "./DesktopBar";
import AboutDialog from "../AboutDialog";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

const Bar = () => {
  const { doLogout, user } = useAuth();
  const [showDialog, setShowDialog] = useState(false);

  const history = useHistory();
  const drawerItems = [
    {
      label: "Inicio",
      icon: <Home style={{ color: "black" }} />,
      rol: [
        "admin",
        "gestor",
        "coordinadorzona",
        "coordinadoradezona",
        "medico",
        "paciente",
        "gestoradministrativo",
        "laboratorio",
        "ucl",
        "aseguradora",
        "medical",
        "acceso",
        "comercial",
      ],
      route: "/",
      isEnabled : true,
    },
    {
      label: "Informes",
      icon: <ChartBar style={{ color: "black" }} />,
      rol: [
        "admin",
        "gestor",
        "coordinadorzona",
        "coordinadoradezona",
        "medico",
      ],
      route: "/stats",
      isEnabled : true,
    },
    {
      label: "Patologías",
      icon: <HealingIcon style={{ color: "black" }} />,
      rol: ["admin", "gestor", "coordinadorzona", "coordinadoradezona"],
      route: "/pathologies",
      isEnabled : true,
    },
    {
      label: "Pacientes",
      icon: <Users color="black" />,
      rol: [
        "admin",
        "gestor",
        "coordinadorzona",
        "coordinadoradezona",
        "medico",
      ],
      route: "/patients",
      isEnabled : true,
    },
    {
      label: "Laboratorios",
      icon: <Pill color="black" />,
      rol: ["admin", "gestor", "coordinadorzona", "coordinadoradezona"],
      route: "/laboratories",
      isEnabled : true,
    },
    {
      label: "Empleados",
      icon: <Friends color="black" />,
      rol: ["admin", "gestoradministrativo"],
      route: "/employees",
      isEnabled : false,
    },
    {
      label: "Encuestas",
      icon: <Checkbox color="black" />,
      rol: ["admin", "gestor", "coordinadorzona", "coordinadoradezona"],
      route: "/surveys",
      isEnabled : false,
    },

    {
      label: "Medicos",
      icon: <Stethoscope color="black" />,
      rol: ["admin", "gestor", "coordinadorzona", "coordinadoradezona"],
      route: "/medical",
      isEnabled : true,
    },

    {
      label: "EPS / Centros de Aplicación",
      icon: <BuildingHospital color="black" />,
      rol: ["admin", "gestor", "coordinadorzona", "coordinadoradezona"],
      route: "/eps",
      isEnabled : true,
    },

    {
      label: "Educación",
      icon: <School color="black" />,
      rol: [
        "admin",
        "gestor",
        "coordinadorzona",
        "coordinadoradezona",
        "paciente",
      ],
      route: "/education",
      isEnabled : false,
    },
    {
      label: "Administración",
      icon: <Settings color="black" />,
      rol: ["admin", "administradorti"],
      route: "/admin",
      isEnabled : true,
    },
  ];

  const menuItems = [
    {
      name: "Acerca De",
      onClick: () => {
        setShowDialog(true);
      },
    },
    {
      name: "Perfil",
      to: user ? `/user` : null,
      hideFor: [
        "laboratorio",
        "ucl",
        "aseguradora",
        "medical",
        "acceso",
        "comercial",
      ],
    },
    {
      name: "Cerrar Sesión",
      divide: true,
      onClick: () => {
        doLogout().then(() => {
          history.push("/login");
        });
      },
    },
  ];

  return (
    <div>
      <MobileBar
        className="!block sm:!hidden"
        drawerItems={drawerItems}
        menuItems={menuItems}
      />
      <DesktopBar
        className="hidden sm:!block"
        drawerItems={drawerItems}
        menuItems={menuItems}
      />
      {showDialog && (
          <AboutDialog open={showDialog} onClose={() => setShowDialog(false)} />
      )}
    </div>
  );
};

Bar.propTypes = {
  // Events
  onAboutClick: PropTypes.func,
  onSettingsClick: PropTypes.func,
};

export default Bar;
