export const vaccinesList = [
  {
    index: 0,
    id: "meningococo",
    label: "Meningococo",
  },
  {
    index: 1,
    id: "neumococo",
    label: "Neumococo",
  },
  {
    index: 2,
    id: "influenza",
    label: "Influenza",
  },
];
