import { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import BackDrop from "../Shared/BackDrop/BackDrop";
import Details from "../PatientsPage/Pages/Details/Details";
import { useGlobal } from "../../context/GlobalContext";
import { useAuth } from "../../context/AuthContext";
import ResetPassword from "../ResetPassword";
import LoginPage from "../LoginPage";
import Main from "../../global/layouts/Main";
import ChangelogPage from "../ChangelogPage";
const HomePage = lazy(() => import("../HomePage"));
const AdminPage = lazy(() => import("../AdminPage"));
const UserPage = lazy(() => import("../UserPage"));
const NotFoundPage = lazy(() => import("../NotFoundPage"));
const PatientsPage = lazy(() => import("../PatientsPage/PatientsPage"));
const EmployeesPage = lazy(() => import("../EmployeesPage/EmployeesPage"));
const PathologyPage = lazy(() => import("../PathologyPage/PathologyPage"));
const PharmacoVigilancePage = lazy(() =>
  import("../PharmacoVigilancePage/PharmacoVigilancePage")
);
const MedicalPage = lazy(() => import("../MedicalPage/MedicalPage"));
const StatsPage = lazy(() => import("../StatsPage/StatsPage"));
const SurveysPage = lazy(() => import("../SurveysPage/SurveysPage"));
const SurveyView = lazy(() => import("../SurveyView/SurveyView"));
const EducationPage = lazy(() => import("../EducationPage"));
const SignaturePatientPage = lazy(() => import("../SignaturePatientPage"));
const List = lazy(() => import("../SignaturePatientPage/Elements/List"));
const EPSListPage = lazy(() => import("../EPSListPage"));
const Assistance = lazy(() =>
  import("../PatientsPage/Pages/Assistance/Assistance")
);
const MedicalRecord = lazy(() => import("../MedicalPage/Pages/Record"));
const UclPatients = lazy(() => import("../StatsPage/Pages/Stats/UclPatients"));

const Router = () => {
  // Hooks
  const { setLoading, isLoading, handleRouteTitle, showSnackBar } = useGlobal();
  const { isAuthenticated, user, roles } = useAuth();
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Suspense fallback={<BackDrop show />}>
        <Switch>
          <Route path="/login" exact>
            <LoginPage
              openSnackbar={showSnackBar}
              setRouteTitle={handleRouteTitle}
              isPerformingAction={setLoading}
              performingAction={isLoading}
            />
          </Route>
          <Route path="/" exact>
            {isAuthenticated ? (
              <Main>
                <HomePage
                  user={user}
                  openSnackbar={showSnackBar}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/user">
            {isAuthenticated ? (
              <Main>
                <UserPage
                  user={user}
                  openSnackbar={showSnackBar}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/admin">
            {isAuthenticated && (roles === "admin" || "administradorti") ? (
              <Main>
                <AdminPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/employees">
            {isAuthenticated &&
            (roles === "admin" || roles === "gestoradministrativo") ? (
              <Main>
                <EmployeesPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/pathologies">
            {isAuthenticated ? (
              <Main>
                <PathologyPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route exact path="/patients">
            {isAuthenticated ? (
              <Main>
                <PatientsPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route exact path="/patients/details/:id">
            {isAuthenticated ? (
              <Main>
                <Details
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route exact path="/patients/assistance/:id">
            {isAuthenticated ? (
              <Main>
                <Assistance
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/signature/:id?">
            <Main>
              <SignaturePatientPage
                setRouteTitle={handleRouteTitle}
                isPerformingAction={setLoading}
                openSnackbar={showSnackBar}
                user={user}
              />
            </Main>
          </Route>

          <Route path="/signatureconfig">
            {isAuthenticated ? (
              <Main>
                <List
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  openSnackbar={showSnackBar}
                  user={user}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/laboratories">
            {isAuthenticated ? (
              <Main>
                <PharmacoVigilancePage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/medicals">
            {isAuthenticated ? (
              <Main>
                <MedicalPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/stats">
            {isAuthenticated ? (
              <Main>
                <StatsPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/surveys">
            {isAuthenticated ? (
              <Main>
                <SurveysPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route
            path="/encuesta/:id"
            render={(renderProps) => (
              <Main>
                <SurveyView
                  {...renderProps}
                  openSnackbar={showSnackBar}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            )}
          ></Route>

          <Route path="/medical">
            {isAuthenticated ? (
              <Main>
                <MedicalPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/education">
            {isAuthenticated ? (
              <Main>
                <EducationPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/eps">
            {isAuthenticated ? (
              <Main>
                <EPSListPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/MedicalRecord">
            {isAuthenticated ? (
              <Main>
                <MedicalRecord />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/UclPatients">
            {isAuthenticated ? (
              <Main>
                <UclPatients />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/changelog">
            {isAuthenticated ? (
              <Main>
                <ChangelogPage
                  openSnackbar={showSnackBar}
                  user={user}
                  setRouteTitle={handleRouteTitle}
                  isPerformingAction={setLoading}
                  performingAction={isLoading}
                />
              </Main>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/resetPassword">
            <Main>
              <ResetPassword />
            </Main>
          </Route>

          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
