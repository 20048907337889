import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "../Details.module.scss";

import dataService from "../../../../../services/dataService";
import {
  filterOptions,
  handleLoadList,
} from "../../../../../global/utils/autoCompleteUtils";
import ControlledDatePicker from "../../../../Shared/ControlledDatePicker";
import SelectComponent from "../../../../Shared/SelectComponent";
import VaccineRenderer from "./elements/VaccineRenderer";
import { vaccinesList } from "../Constants/VaccinesList";

const InitialStatus = (props) => {
  //Hooks and Vars
  const {
    user,
    control,
    errors,
    patientInfo,
    epsList,
    diagnosticsList,
    specialityList,
    doctorsList,
    centrosAplicacionList,
    handleListRefetch,
    handleFormValues,
    getValues,
  } = props;

  const [createdDoctor, setCreatedDoctor] = useState({});

  //Handlers

  const handleFormValuesList = (field, value) => {
    if (value !== null) {
      let formatData = {
        name: value.inputValue ? value.inputValue : value.name,
      };

      handleFormValues(field, formatData.name.toUpperCase());
      handleFormValues("drugName", value.medName?.toUpperCase());
      handleFormValues("drugCode", value.medCode?.toUpperCase());
    }
  };

  const handleFormValuesEPS = (field, value, list) => {
    if (value !== null) {
      let formatData = {
        name: value.inputValue ? value.inputValue : value.name,
      };
      handleFormValues(field, formatData.name.toUpperCase());
      if (
        list.filter(
          (x) => x.name.toUpperCase() === formatData.name.toUpperCase()
        ).length <= 0
      ) {
        dataService
          .createDocument(
            {
              name: formatData.name.toUpperCase(),
            },
            "eps"
          )
          .then((res) => {
            handleListRefetch("eps");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const handleFormValuesGeneric = (field, value, collectionName, list) => {
    if (value !== null) {
      let formatData = {
        name: value.inputValue ? value.inputValue : value.name,
      };
      if (
        value.especialidad !== null &&
        typeof value.especialidad !== "undefined" &&
        value.especialidad !== ""
      ) {
        handleFormValues("speciality", value.especialidad.toUpperCase());
      }
      handleFormValues(field, formatData.name.toUpperCase());
      if (
        collectionName === "speciality" &&
        typeof createdDoctor.id !== "undefined"
      ) {
        let doctorToUpdate = Object.assign({}, createdDoctor);
        doctorToUpdate.especialidad = formatData.name.toUpperCase();
        dataService.updateDocument(doctorToUpdate, "medicals");
      }

      if (collectionName === "medicals") {
        setCreatedDoctor({
          id: value.id,
        });
      }
      if (
        list.filter(
          (x) =>
            x.name.toUpperCase().trim() === formatData.name.toUpperCase().trim()
        ).length <= 0
      ) {
        if (collectionName === "medicals") {
          dataService
            .createDocument(
              {
                name: formatData.name.toUpperCase(),
                isActive: true,
                createdBy: user.uid,
                creationDate: new Date(),
              },
              collectionName
            )
            .then((result) => {
              setCreatedDoctor({
                id: result.id,
              });
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          dataService
            .createDocument(
              {
                name: formatData.name.toUpperCase(),
              },
              collectionName
            )
            .then((result) => {
              if (collectionName === "speciality") {
                let doctorToUpdate = Object.assign({}, createdDoctor);
                doctorToUpdate.especialidad = formatData.name.toUpperCase();
                dataService.updateDocument(doctorToUpdate, "medicals");
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    }
  };

  const handleVaccineFormValues = (field, value) => {
    handleFormValues(field, value);
  };

  return (
    <Box p={3} className={styles.gridContainerBase}>
      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            defaultValue={patientInfo.diagnosis}
            noOptionsText={"Sin resultados"}
            options={diagnosticsList}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option.toUpperCase();
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue.toUpperCase();
              }
              // Regular option
              return option.name.toUpperCase();
            }}
            renderOption={(option) => <span>{option.name.toUpperCase()}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Diagnóstico"
                variant="outlined"
                error={errors?.diagnosis ? true : false}
                helperText={errors?.diagnosis?.message}
              />
            )}
            onChange={(_, data) => handleFormValuesList("diagnosis", data)}
          />
        )}
        name="diagnosis"
        control={control}
      />

      <SelectComponent
        control={control}
        name="dxIsOrphan"
        label="Diagnóstico clasificado como Enfermedad Huérfana"
        error={true}
        errorItem={errors?.dxIsOrphan}
        classes="textUpper"
        defaultValue={patientInfo.dxIsOrphan}
        items={[
          {
            id: "si",
            value: "SI",
          },
          {
            id: "no",
            value: "NO",
          },
        ]}
        handler={handleFormValues}
      />

      <Controller
        name="dxVariant"
        control={control}
        defaultValue={patientInfo.dxVariant}
        label="Variante"
        variant="outlined"
        width={1}
        as={TextField}
        helperText={errors?.dxVariant?.message}
        error={errors?.dxVariant ? true : false}
      />

      <ControlledDatePicker
        name="dxDate"
        control={control}
        value={patientInfo.dxDate}
        handleFormValues={handleFormValues}
        label="Fecha de Diagnóstico"
        errors={errors}
      />

      <FormControl variant="outlined" error={errors?.dxsupport ? true : false}>
        <InputLabel id="dxsupport-label">Cuenta con soporte DX</InputLabel>
        <Controller
          control={control}
          name="dxsupport"
          variant="outlined"
          defaultValue={patientInfo.dxsupport}
          as={({ _onChange, value }) => {
            return (
              <Select
                value={value}
                label="Cuenta con soporte DX"
                labelId="dxsupport-label"
                error={errors?.dxsupport ? true : false}
                onChange={(e) => {
                  handleFormValues("dxsupport", e.target.value);
                }}
              >
                <MenuItem value="si">SI</MenuItem>
                <MenuItem value="no">NO</MenuItem>
              </Select>
            );
          }}
          onChange={(val) => {
            return val;
          }}
        />
        <FormHelperText>{errors?.dxsupport?.message}</FormHelperText>
      </FormControl>

      {getValues("dxsupport") === "no" && (
        <Controller
          name="dxNotSupport"
          control={control}
          defaultValue={patientInfo.dxNotSupport}
          label="No, ¿Porque?"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.dxNotSupport?.message}
          error={errors?.dxNotSupport ? true : false}
        />
      )}

      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            defaultValue={patientInfo.eps}
            noOptionsText={"Sin resultados"}
            filterOptions={filterOptions}
            options={handleLoadList(epsList)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option.toUpperCase();
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue.toUpperCase();
              }
              // Regular option
              return option.name.toUpperCase();
            }}
            renderOption={(option) => <span>{option.name.toUpperCase()}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                label="EPS"
                variant="outlined"
                error={errors?.eps ? true : false}
                helperText={errors?.eps?.message}
              />
            )}
            onChange={(_, data) => handleFormValuesEPS("eps", data, epsList)}
          />
        )}
        name="eps"
        control={control}
      />

      <FormControl variant="outlined" error={errors?.regimen ? true : false}>
        <InputLabel id="regimen-label">Regimen</InputLabel>
        <Controller
          control={control}
          name="regimen"
          variant="outlined"
          defaultValue={patientInfo.regimen}
          as={({ _onChange, value }) => {
            return (
              <Select
                value={value}
                labelId="regimen-label"
                label="Regimen"
                error={errors?.regimen ? true : false}
                onChange={(e) => {
                  handleFormValues("regimen", e.target.value);
                }}
              >
                <MenuItem value="subsidiado">SUBSIDIADO</MenuItem>
                <MenuItem value="contributivo">CONTRIBUTIVO</MenuItem>
                <MenuItem value="especial">ESPECIAL</MenuItem>
              </Select>
            );
          }}
          onChange={(val) => {
            return val;
          }}
        />
        <FormHelperText>{errors?.regimen?.message}</FormHelperText>
      </FormControl>

      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            defaultValue={patientInfo.doctor}
            noOptionsText={"Sin resultados"}
            filterOptions={filterOptions}
            options={handleLoadList(doctorsList)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option.toUpperCase();
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue.toUpperCase();
              }
              // Regular option
              return option.name.toUpperCase();
            }}
            renderOption={(option) => <span>{option.name.toUpperCase()}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Médico Tratante"
                variant="outlined"
                error={errors?.doctor ? true : false}
                helperText={errors?.doctor?.message}
              />
            )}
            onChange={(_, data) =>
              handleFormValuesGeneric("doctor", data, "medicals", doctorsList)
            }
          />
        )}
        name="doctor"
        control={control}
      />

      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            defaultValue={patientInfo.speciality}
            noOptionsText={"Sin resultados"}
            filterOptions={filterOptions}
            options={handleLoadList(specialityList)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option.toUpperCase();
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue.toUpperCase();
              }
              // Regular option
              return option.name.toUpperCase();
            }}
            renderOption={(option) => <span>{option.name.toUpperCase()}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Especialidad"
                variant="outlined"
                error={errors?.speciality ? true : false}
                helperText={errors?.speciality?.message}
              />
            )}
            onChange={(_, data) =>
              handleFormValuesGeneric(
                "speciality",
                data,
                "speciality",
                specialityList
              )
            }
          />
        )}
        name="speciality"
        control={control}
      />

      <Controller
        name="dxMedicalCenter"
        control={control}
        defaultValue={patientInfo.dxMedicalCenter}
        label="Lugar de Atención Médico Tratante"
        variant="outlined"
        width={1}
        as={TextField}
        helperText={errors?.dxMedicalCenter?.message}
        error={errors?.dxMedicalCenter ? true : false}
      />

      <FormControl
        variant="outlined"
        error={errors?.getTreatment ? true : false}
      >
        <InputLabel id="getTreatment-label" htmlFor="getTreatment">
          Recibe Tratamiento
        </InputLabel>
        <Controller
          control={control}
          name="getTreatment"
          variant="outlined"
          defaultValue={patientInfo.getTreatment}
          as={({ _onChange, value }) => {
            return (
              <Select
                value={value}
                label="Recibe Tratamiento"
                labelId="getTreatment-label"
                error={errors?.getTreatment ? true : false}
                onChange={(e) => {
                  handleFormValues("getTreatment", e.target.value);
                }}
              >
                <MenuItem value="si">SI</MenuItem>
                <MenuItem value="no">NO</MenuItem>
              </Select>
            );
          }}
          onChange={(val) => {
            return val;
          }}
        />
        <FormHelperText>{errors?.getTreatment?.message}</FormHelperText>
      </FormControl>

      {patientInfo.getTreatment === "no" && (
        <Controller
          required={patientInfo.getTreatment === false}
          name="notGetTreatment"
          control={control}
          defaultValue={patientInfo.notGetTreatment}
          label="No, ¿Porque?"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.notGetTreatment ? true : false}
          helperText={errors?.notGetTreatment?.message}
        />
      )}

      {patientInfo.getTreatment === "si" && (
        <ControlledDatePicker
          name="treatmentStart"
          control={control}
          value={patientInfo.treatmentStart}
          handleFormValues={handleFormValues}
          label="Fecha de Inicio Tratamiento"
          errors={errors}
        />
      )}

      <Controller
        name="drugName"
        defaultValue={patientInfo.drugName}
        control={control}
        label="Nombre de Medicamento"
        variant="outlined"
        width={1}
        as={TextField}
        error={errors?.drugName ? true : false}
        helperText={errors?.drugName?.message}
      />

      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            defaultValue={patientInfo.centroAplicacion}
            noOptionsText={"Sin resultados"}
            filterOptions={filterOptions}
            options={handleLoadList(centrosAplicacionList)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option.toUpperCase();
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue.toUpperCase();
              }
              // Regular option
              return option.name.toUpperCase();
            }}
            renderOption={(option) => <span>{option.name.toUpperCase()}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Centro de Aplicación"
                variant="outlined"
                error={errors?.centroAplicacion ? true : false}
                helperText={errors?.centroAplicacion?.message}
              />
            )}
            onChange={(_, data) =>
              handleFormValuesGeneric(
                "centroAplicacion",
                data,
                "centrosAplicacion",
                centrosAplicacionList
              )
            }
          />
        )}
        name="centroAplicacion"
        control={control}
      />

      <Controller
        name="dxApplicationCity"
        control={control}
        defaultValue={patientInfo.dxApplicationCity}
        label="Ciudad de Aplicación"
        variant="outlined"
        width={1}
        as={TextField}
        helperText={errors?.dxApplicationCity?.message}
        error={errors?.dxApplicationCity ? true : false}
      />

      <SelectComponent
        control={control}
        name="dxMedicalType"
        label="Presentación del medicamento"
        error={true}
        errorItem={errors?.dxMedicalType}
        classes="textUpper"
        defaultValue={patientInfo.dxMedicalType}
        items={[
          {
            id: "jarabe",
            value: "JARABE",
          },
          {
            id: "viales",
            value: "VIALES",
          },
          {
            id: "pastilla",
            value: "PASTILLA",
          },
          {
            id: "inyeccion",
            value: "INYECCIÓN",
          },
          {
            id: "ampolla",
            value: "AMPOLLA",
          },
          {
            id: "otra",
            value: "OTRA",
          },
        ]}
        handler={handleFormValues}
      />

      {getValues("dxMedicalType") === "otra" && (
        <Controller
          name="dxMedicalTypeOther"
          defaultValue={patientInfo.dxMedicalTypeOther}
          control={control}
          label="¿Cual?"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.dxMedicalTypeOther ? true : false}
          helperText={errors?.dxMedicalTypeOther?.message}
        />
      )}

      <Controller
        name="dxDosis"
        control={control}
        defaultValue={patientInfo.dxDosis}
        label="Dosis"
        variant="outlined"
        width={1}
        as={TextField}
        helperText={errors?.dxDosis?.message}
        error={errors?.dxDosis ? true : false}
      />

      <SelectComponent
        control={control}
        name="dxMedicalFrequency"
        label="Frecuencia"
        error={true}
        errorItem={errors?.dxMedicalFrequency}
        classes="textUpper"
        defaultValue={patientInfo.dxMedicalFrequency}
        items={[
          {
            id: "diaria",
            value: "DIARIA",
          },
          {
            id: "quincenal",
            value: "QUINCENAL",
          },
          {
            id: "semanal",
            value: "SEMANAL",
          },
          {
            id: "mensual",
            value: "MENSUAL",
          },
        ]}
        handler={handleFormValues}
      />

      <SelectComponent
        control={control}
        name="dxAdministration"
        label="Vía de Administración"
        error={true}
        errorItem={errors?.dxAdministration}
        classes="textUpper"
        defaultValue={patientInfo.dxAdministration}
        items={[
          {
            id: "subcutanea",
            value: "SUBCUTÁNEA",
          },
          {
            id: "intravenosa",
            value: "INTRAVENOSA",
          },
          {
            id: "topica",
            value: "TOPICA",
          },
          {
            id: "intramuscular",
            value: "INTRAMUSCULAR",
          },
          {
            id: "oral",
            value: "VIA ORAL",
          },
          {
            id: "respiratoria",
            value: "VIA RESPIRATORIA",
          },
          {
            id: "otra",
            value: "OTRA",
          },
        ]}
        handler={handleFormValues}
      />

      {getValues("dxAdministration") === "otra" && (
        <Controller
          name="dxAdministrationOther"
          defaultValue={patientInfo.dxAdministrationOther}
          control={control}
          label="¿Cual?"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.dxAdministrationOther ? true : false}
          helperText={errors?.dxAdministrationOther?.message}
        />
      )}

      {patientInfo.drugCode !== "" && (
        <Controller
          name="drugCode"
          defaultValue={patientInfo.drugCode}
          control={control}
          label="Código de Medicamento"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.drugCode ? true : false}
          helperText={errors?.drugCode?.message}
        />
      )}

      <div style={{ display: "flex", gap: "5px" }}>
        <Controller
          control={control}
          as={TextField}
          name="weight"
          label="Peso"
          variant="outlined"
          defaultValue={patientInfo.weight}
          type="number"
          style={{ width: "50%" }}
          helperText={errors?.weight?.message}
          error={errors?.weight ? true : false}
          min="0"
        />

        <FormControl
          variant="outlined"
          style={{ width: "50%" }}
          error={errors?.weightInfo ? true : false}
        >
          <InputLabel id="weightInfo-label">Unidad</InputLabel>
          <Controller
            control={control}
            name="weightInfo"
            variant="outlined"
            defaultValue={patientInfo.weightInfo}
            as={({ _onChange, value }) => {
              return (
                <Select
                  value={value}
                  label="Unidad"
                  labelId="weightInfo-label"
                  error={errors?.weightInfo ? true : false}
                  onChange={(e) => {
                    handleFormValues("weightInfo", e.target.value);
                  }}
                >
                  <MenuItem value="gr">GRAMOS</MenuItem>
                  <MenuItem value="kg">KILOGRAMOS</MenuItem>
                  <MenuItem value="lb">LIBRAS</MenuItem>
                </Select>
              );
            }}
            onChange={(val) => {
              return val;
            }}
          />
          <FormHelperText error={true}>
            {errors?.weightInfo?.message}
          </FormHelperText>
        </FormControl>
      </div>

      <div style={{ display: "flex", gap: "5px" }}>
        <Controller
          control={control}
          as={TextField}
          name="size"
          label="Talla"
          variant="outlined"
          defaultValue={patientInfo.size}
          type="number"
          style={{ width: "50%" }}
          helperText={errors?.size?.message}
          error={errors?.size ? true : false}
          min="0"
        />

        <FormControl
          variant="outlined"
          style={{ width: "50%" }}
          error={errors?.sizeInfo ? true : false}
        >
          <InputLabel id="sizeInfo-label">Unidad</InputLabel>
          <Controller
            control={control}
            name="sizeInfo"
            variant="outlined"
            defaultValue={patientInfo.sizeInfo}
            error={errors?.sizeInfo ? true : false}
            as={({ _onChange, value }) => {
              return (
                <Select
                  value={value}
                  label="Unidad"
                  labelId="sizeInfo-label"
                  error={errors?.sizeInfo ? true : false}
                  onChange={(e) => {
                    handleFormValues("sizeInfo", e.target.value);
                  }}
                >
                  <MenuItem value="cm">CENTÍMETROS</MenuItem>
                  <MenuItem value="mt">METROS</MenuItem>
                </Select>
              );
            }}
            onChange={(val) => {
              return val;
            }}
          />
          <FormHelperText error={true}>
            {errors?.sizeInfo?.message}
          </FormHelperText>
        </FormControl>
      </div>

      <SelectComponent
        control={control}
        name="dxOrganTransplant"
        label="Ha recibido trasplante de órganos"
        error={true}
        errorItem={errors?.dxOrganTransplant}
        classes="textUpper"
        defaultValue={patientInfo.dxOrganTransplant}
        items={[
          {
            id: "si",
            value: "SI",
          },
          {
            id: "no",
            value: "NO",
          },
        ]}
        handler={handleFormValues}
      />

      {getValues("dxOrganTransplant") === "si" && (
        <Controller
          name="dxOrganTransplantWhich"
          control={control}
          defaultValue={patientInfo.dxOrganTransplantWhich}
          label="Si, ¿Cual?"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.dxOrganTransplantWhich?.message}
          error={errors?.dxOrganTransplantWhich ? true : false}
        />
      )}

      <FormControl
        variant="outlined"
        error={errors?.discapacidad ? true : false}
      >
        <InputLabel id="discapacidad-label">
          Paciente con discapacidad
        </InputLabel>
        <Controller
          control={control}
          name="discapacidad"
          variant="outlined"
          defaultValue={patientInfo.discapacidad}
          as={({ _onChange, value }) => {
            return (
              <Select
                labelId="discapacidad-label"
                label="Paciente con discapacidad"
                error={errors?.discapacidad ? true : false}
                value={value}
                onChange={(e) => {
                  handleFormValues("discapacidad", e.target.value);
                }}
              >
                <MenuItem value="si">SI</MenuItem>
                <MenuItem value="no">NO</MenuItem>
              </Select>
            );
          }}
          onChange={(val) => {
            return val;
          }}
        />
        <FormHelperText>{errors?.discapacidad?.message}</FormHelperText>
      </FormControl>

      {patientInfo.discapacidad === "si" && (
        <>
          <FormControl
            variant="outlined"
            error={errors?.discapacidadType ? true : false}
          >
            <InputLabel id="discapacidadType-label">
              Tipo de Discapacidad
            </InputLabel>
            <Controller
              control={control}
              name="discapacidadType"
              variant="outlined"
              defaultValue={patientInfo.discapacidadType}
              as={({ _onChange, value }) => {
                return (
                  <Select
                    value={value}
                    labelId="discapacidadType-label"
                    label="Tipo de Discapacidad"
                    error={errors?.discapacidadType ? true : false}
                    onChange={(e) => {
                      handleFormValues("discapacidadType", e.target.value);
                    }}
                  >
                    <MenuItem value="Física">FÍSICA</MenuItem>
                    <MenuItem value="Visual">VISUAL</MenuItem>
                    <MenuItem value="Sensorial">SENSORIAL</MenuItem>
                    <MenuItem value="Auditiva">AUDITIVA</MenuItem>
                    <MenuItem value="Cognitiva">COGNITIVA</MenuItem>
                    <MenuItem value="Motora">MOTORA</MenuItem>
                    <MenuItem value="Mixta">MIXTA</MenuItem>
                  </Select>
                );
              }}
              onChange={(val) => {
                return val;
              }}
            />
            <FormHelperText>{errors?.discapacidadType?.message}</FormHelperText>
          </FormControl>

          <FormControl
            variant="outlined"
            error={errors?.discapacidadCert ? true : false}
          >
            <InputLabel id="discapacidadCert-label">
              ¿Tiene certificación de discapacidad? (EPS)
            </InputLabel>
            <Controller
              control={control}
              name="discapacidadCert"
              variant="outlined"
              defaultValue={patientInfo.discapacidadCert}
              as={({ _onChange, value }) => {
                return (
                  <Select
                    value={value}
                    label="¿Tiene certificación de discapacidad? (EPS)"
                    labelId="discapacidadCert-label"
                    error={errors?.discapacidadCert ? true : false}
                    onChange={(e) => {
                      handleFormValues("discapacidadCert", e.target.value);
                    }}
                  >
                    <MenuItem value="si">SI</MenuItem>
                    <MenuItem value="no">NO</MenuItem>
                  </Select>
                );
              }}
              onChange={(val) => {
                return val;
              }}
            />
            <FormHelperText>{errors?.discapacidadCert?.message}</FormHelperText>
          </FormControl>
          <SelectComponent
            control={control}
            name="discapacidadRegistered"
            label="Registro Único de Caracterización de personas con Discapacidad"
            error={true}
            errorItem={errors?.discapacidadRegistered}
            classes="textUpper"
            defaultValue={patientInfo.discapacidadRegistered}
            items={[
              {
                id: "si",
                value: "SI",
              },
              {
                id: "no",
                value: "NO",
              },
            ]}
            handler={handleFormValues}
          />
        </>
      )}

      <FormControl variant="outlined" error={errors?.needHelp ? true : false}>
        <InputLabel id="needHelp-label">
          ¿Necesita ayuda tecnológica pero no tiene?
        </InputLabel>
        <Controller
          control={control}
          name="needHelp"
          variant="outlined"
          defaultValue={patientInfo.needHelp}
          error={errors?.needHelp ? true : false}
          onChange={(val) => {
            return val;
          }}
          as={({ _onChange, value }) => {
            return (
              <Select
                label="¿Necesita ayuda tecnológica pero no tiene?"
                labelId="needHelp-label"
                value={value}
                error={errors?.needHelp ? true : false}
                onChange={(e) => {
                  handleFormValues("needHelp", e.target.value);
                }}
              >
                <MenuItem value="si">SI</MenuItem>
                <MenuItem value="no">NO</MenuItem>
              </Select>
            );
          }}
        />
        <FormHelperText>{errors?.needHelp?.message}</FormHelperText>
      </FormControl>

      {patientInfo.needHelp === "si" && (
        <Controller
          name="techHelp"
          defaultValue={patientInfo.techHelp}
          control={control}
          label="¿Cual?"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.techHelp ? true : false}
          helperText={errors?.techHelp?.message}
        />
      )}

      <div className="border-t border-t-gray-500 w-full col-span-5"></div>

      {vaccinesList.map((vaccine) => (
        <VaccineRenderer
          key={vaccine.id}
          vaccine={vaccine}
          control={control}
          patientInfo={patientInfo}
          errors={errors}
          handleFormValues={handleVaccineFormValues}
        />
      ))}
    </Box>
  );
};

InitialStatus.propTypes = {
  changeTab: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default InitialStatus;
