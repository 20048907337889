export const externalHelpsList = [
  {
    name: "nutrition",
    label: "Nutrición",
  },
  {
    name: "economic",
    label: "Económica",
  },
  {
    name: "psychological",
    label: "Psicológica",
  },
  {
    name: "transport",
    label: "Transporte",
  },
  {
    name: "health",
    label: "Salud",
  },
  {
    name: "legal",
    label: "Legal",
  },
  {
    name: "other",
    label: "Otro",
  },
  {
    name: "externalAssistance",
    label: "Acompañamiento Externo",
  },
];
