import React from "react";
import PropTypes from "prop-types";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const BackDrop = ({ show, showBack = true }) => (
  <Backdrop className={showBack ? "backdrop" : ""} open={show}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

BackDrop.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default BackDrop;
