import {
  AppBar,
  Breadcrumbs,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import UserAvatar from "../../UserAvatar";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, { useState } from "react";
import { isUndefined } from "lodash";
import { useGlobal } from "../../../context/GlobalContext";

const MainBar = (props) => {
  const { menuItems } = props;
  const { user, isLoading } = useAuth();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const { routeTitle } = useGlobal();

  //Methods

  const openMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  // Renders

  const renderBreadCrumb = () => {
    if (
      !isUndefined(routeTitle) &&
      typeof routeTitle !== "string" &&
      routeTitle.length > 0
    ) {
      return routeTitle.map((item, index) => {
        if (index === routeTitle.length - 1) {
          return (
            <Typography
              key={index}
              variant="h6"
              color="textPrimary"
              className="select-none"
            >
              {item.title}
            </Typography>
          );
        }
        return (
          <Link
            key={index}
            color="inherit"
            to={`/${item.id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="h6"
              color="textPrimary"
              className="select-none"
            >
              {item.title}
            </Typography>
          </Link>
        );
      });
    } else {
      return (
        <Typography variant="h6" color="textPrimary" className="select-none">
          Inicio
        </Typography>
      );
    }
  };

  return (
    <AppBar position="fixed" color="default">
      <Toolbar className="w-full flex gap-5">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="!ml-16"
        >
          {renderBreadCrumb()}
        </Breadcrumbs>
        {user && (
          <div className="ml-auto">
            <IconButton color="inherit" disabled={isLoading} onClick={openMenu}>
              <UserAvatar user={Object.assign(user)} />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={closeMenu}
            >
              {menuItems.map((menuItem, index) => {
                if (
                  menuItem.hasOwnProperty("condition") &&
                  !menuItem.condition
                ) {
                  return <React.Fragment key={index}></React.Fragment>;
                }

                let component = null;

                if (menuItem.hideFor && menuItem.hideFor.includes(user.role)) {
                  component = <div key={index}></div>;
                } else if (menuItem.to) {
                  if (
                    user.role !== "paciente" ||
                    user.role !== "medico" ||
                    user.role !== "laboratorio" ||
                    user.role !== "ucl" ||
                    user.role !== "aseguradora" ||
                    user.role !== "medical" ||
                    user.role !== "acceso" ||
                    user.role !== "comercial"
                  ) {
                    component = (
                      <MenuItem
                        key={index}
                        component={Link}
                        to={menuItem.to}
                        onClick={closeMenu}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  }
                } else {
                  component = (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        closeMenu();

                        menuItem.onClick();
                      }}
                    >
                      {menuItem.name}
                    </MenuItem>
                  );
                }

                if (menuItem.divide) {
                  return (
                    <span key={index + "_divide"}>
                      <Divider />

                      {component}
                    </span>
                  );
                }

                return component;
              })}
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default MainBar;
