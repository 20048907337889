import firebase from "firebase/compat/app";

import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/performance";
import "firebase/compat/database";
import "firebase/compat/functions";
import "firebase/compat/app-check";
import "firebase/compat/remote-config";
import { isString, isUndefined } from "lodash";
import { isLocalhost } from "./global/utils/isLocalHost";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();
const analytics = firebase.analytics();
const performance = firebase.performance();
const database = firebase.database();
const remoteConfig = firebase.remoteConfig();

// Firebase Emulator Config
if (isLocalhost && process.env.REACT_APP_MODE === "offline") {
  auth.useEmulator("http://localhost:9099");
  firestore.useEmulator("localhost", 9080);
  storage.useEmulator("localhost", 9199);
  functions.useEmulator("localhost", 9001);
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = "40c2f93b-3ac8-4a29-98bc-b556de5a32d2";
}

// Firebase App Check

const check = firebase.appCheck();
check.activate(process.env.REACT_APP_FIREBASE_APPCHECK);

// Firestore Settings

firestore.settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  merge: true,
});

let enablePersistencePerUser =
  localStorage.getItem("enablePersistence") || true;

if (isString(enablePersistencePerUser)) {
  enablePersistencePerUser = enablePersistencePerUser === "true";
} else {
  localStorage.setItem("enablePersistence", true);
}

if (
  !isUndefined(enablePersistencePerUser) &&
  enablePersistencePerUser === true
) {
  firestore.enablePersistence().catch((err) => {
    if (err.code === "failed-precondition") {
      console.error(
        "Multiple tabs open, persistence can only be enabled in one tab at a a time."
      );
    } else if (err.code === "unimplemented") {
      console.error("Firebase persistence is not available");
    }
  });
}

export default firebase;

export {
  auth,
  firestore,
  storage,
  functions,
  analytics,
  performance,
  database,
  remoteConfig,
  firebase,
};
