import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useGlobal } from "../../context/GlobalContext";
import { isUndefined } from "lodash";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
}));

const BottomAppBar = (props) => {
  const { handleDrawerOpen } = props;
  const classes = useStyles();
  const { routeTitle } = useGlobal();

  const renderBreadCrumb = () => {
    if (
      !isUndefined(routeTitle) &&
      typeof routeTitle !== "string" &&
      routeTitle.length > 0
    ) {
      return routeTitle.map((item, index) => {
        if (index === routeTitle.length - 1) {
          return (
            <Typography
              key={index}
              variant="h6"
              color="textPrimary"
              className="select-none"
            >
              {item.title}
            </Typography>
          );
        }
        return (
          <Link
            key={index}
            color="inherit"
            to={`/${item.id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="h6"
              color="textPrimary"
              className="select-none"
            >
              {item.title}
            </Typography>
          </Link>
        );
      });
    } else {
      return (
        <Typography variant="h6" color="textPrimary" className="select-none">
          Inicio
        </Typography>
      );
    }
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              className=""
            >
              {renderBreadCrumb()}
            </Breadcrumbs>
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default BottomAppBar;
