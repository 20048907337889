import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField, Box, FormControlLabel, Switch } from "@material-ui/core";
import { CameraPlus } from "tabler-icons-react";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  filterOptions,
  handleLoadList,
} from "../../../../../global/utils/autoCompleteUtils";
import SelectComponent from "../../../../Shared/SelectComponent";
import styles from "../Details.module.scss";
import dataService from "../../../../../services/dataService";
import ControlledDatePicker from "../../../../Shared/ControlledDatePicker";
import { isDate, isNull } from "lodash";
import { intervalToDuration } from "date-fns";
import { firebaseDate } from "../../../../../global/utils";

const PatientInfo = (props) => {
  //Hooks and Vars
  const {
    role,
    control,
    errors,
    patientInfo,
    departmentsList,
    municipioList,
    cityList,
    handleListRefetch,
    handleFormValues,
    watch,
    getValues,
  } = props;

  console.log("PatientInfo -> patientInfo", patientInfo);

  //Handlers

  const handleFormValuesList = (
    field,
    value,
    main,
    collectionName,
    list,
    depto
  ) => {
    if (value !== null) {
      let formatData = {
        name: value.inputValue ? value.inputValue : value.name,
      };
      handleFormValues(field, formatData.name.toUpperCase());
      if (
        list.filter(
          (x) => x.name.toUpperCase() === formatData.name.toUpperCase()
        ).length <= 0
      ) {
        let data = {
          name: formatData.name.toUpperCase(),
        };
        if (main === false) {
          data.depto = depto;
        }
        dataService.createDocument(data, collectionName).then(() => {
          handleFormValues(field, formatData.name.toUpperCase());
          handleListRefetch(collectionName);
        });
      }
    }
  };

  const handleFormValuesCity = (field, value, list, metadata) => {
    if (value !== null) {
      let formatData = {
        name: value.inputValue ? value.inputValue : value.name,
      };
      handleFormValues(field, formatData.name.toUpperCase());
      if (
        list.filter(
          (x) => x.name.toUpperCase() === formatData.name.toUpperCase()
        ).length <= 0
      ) {
        let data = {
          name: formatData.name.toUpperCase(),
          municipio: metadata.municipio,
          depto: metadata.departamento,
        };
        dataService.createDocument(data, "cities").then(() => {
          handleFormValues(field, formatData.name.toUpperCase());
          handleListRefetch("cities");
        });
      }
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let imageResult = btoa(reader.result);
      handleFormValues("photo", imageResult);
    };

    reader.readAsBinaryString(file);
  };

  const renderImage = () => {
    let imagePreview = null;
    if (patientInfo.photo !== "") {
      imagePreview = (
        <label
          htmlFor="upload"
          className="w-full flex flex-1 items-center justify-center"
        >
          <img
            src={`data:image/png;base64,${patientInfo.photo}`}
            alt="Foto de paciente"
            className="!max-w-60 !max-h-80 rounded-sm object-contain my-2"
          />
          <input
            id="upload"
            style={{ display: "none" }}
            className="fileInput"
            type="file"
            accept=".jpeg, .png, .jpg"
            onChange={(e) => handleImageChange(e)}
          />
        </label>
      );
    } else {
      imagePreview = (
        <label htmlFor="upload" className={styles.previewText}>
          <span>Seleccione una foto</span>
          <CameraPlus className={styles.photoIcon} />
          <input
            id="upload"
            style={{ display: "none" }}
            className="fileInput"
            type="file"
            accept=".jpeg, .png, .jpg"
            onChange={(e) => handleImageChange(e)}
          />
        </label>
      );
    }
    return imagePreview;
  };

  const calcAge = useCallback(
    (birthDate) => {
      const { years, months, days } = intervalToDuration({
        start: birthDate,
        end: new Date(),
      });
      if (years > 0) {
        handleFormValues("age", years);
        handleFormValues("ageTime", "years");
      } else if (years <= 0 && months > 0) {
        handleFormValues("age", months);
        handleFormValues("ageTime", "months");
      } else if (years <= 0 && months <= 0 && days > 0) {
        handleFormValues("age", days);
        handleFormValues("ageTime", "days");
      }
    },
    [handleFormValues]
  );

  useEffect(() => {
    if (!isNull(patientInfo.bornDate)) {
      let birthDate;
      if (isDate(patientInfo.bornDate)) {
        birthDate = patientInfo.bornDate;
      } else {
        birthDate = firebaseDate(patientInfo.bornDate);
      }
      calcAge(birthDate);
    }
  }, [patientInfo.bornDate, calcAge]);

  return (
    <Box p={3}>
      <Box p={3} className={styles.gridContainerBase}>
        <Box className={styles.photoPatient}>{renderImage()}</Box>

        <ControlledDatePicker
          name="fillingDate"
          control={control}
          value={patientInfo.fillingDate}
          handleFormValues={handleFormValues}
          label="Fecha de Diligenciamiento"
          errors={errors}
          disabled={role === "paciente"}
          onChange={calcAge}
        />

        <Controller
          as={TextField}
          name="programName"
          label="Nombre del Programa"
          control={control}
          defaultValue={patientInfo.name}
          variant="outlined"
          required={true}
          fullWidth
          id="name"
          error={errors?.name ? true : false}
          helperText={errors?.name?.message}
          disabled={role === "paciente"}
        />

        <ControlledDatePicker
          name="pspStartDate"
          control={control}
          value={patientInfo.fillingDate}
          handleFormValues={handleFormValues}
          label="Fecha en que el laboratorio indica el ingreso al PSP"
          errors={errors}
          disabled={role === "paciente"}
          onChange={calcAge}
        />

        <Controller
          name="id"
          control={control}
          defaultValue={patientInfo.id}
          label="ID"
          variant="outlined"
          width={1}
          as={TextField}
          error={!!errors.id}
          helperText={errors.id?.message}
          required
        />

        <SelectComponent
          disabled={role === "paciente"}
          control={control}
          name="status"
          label="Estatutos"
          error={true}
          errorItem={errors?.status}
          classes="textUpper"
          defaultValue={patientInfo.status}
          items={[
            {
              id: "PFTTO",
              value: "PFTTO - PACIENTE FORMULADO EN TRATAMIENTO",
            },
            {
              id: "PFSTTO",
              value: "PFSTTO - PACIENTE FORMULADO SIN TRATAMIENTO",
            },
            {
              id: "PFSTTOCM",
              value:
                "PFSTTOCM - PACIENTE FORMULADO SIN TRATAMIENTO POR CRITERIO MEDICO.",
            },
            {
              id: "PF",
              value: "PF - PACIENTE FALLECIDO",
            },
            {
              id: "SD",
              value: "SIN DATOS - PACIENTE SIN DATOS",
            },
          ]}
          handler={handleFormValues}
        />

        <Controller
          as={TextField}
          name="name"
          label="Nombres y Apellidos"
          control={control}
          defaultValue={patientInfo.name}
          variant="outlined"
          required={true}
          fullWidth
          id="name"
          error={errors?.name ? true : false}
          helperText={errors?.name?.message}
          disabled={role === "paciente"}
        />

        <ControlledDatePicker
          name="bornDate"
          control={control}
          value={patientInfo.bornDate}
          handleFormValues={handleFormValues}
          label="Fecha de Nacimiento"
          errors={errors}
          disabled={role === "paciente"}
          onChange={calcAge}
        />

        <div style={{ display: "flex", gap: "5px" }}>
          <Controller
            required={true}
            name="age"
            label="Edad"
            variant="outlined"
            defaultValue={patientInfo.age}
            control={control}
            width={1}
            as={TextField}
            error={errors?.age ? true : false}
            helperText={errors?.age?.message}
            disabled={role === "paciente"}
          />

          <SelectComponent
            control={control}
            name="ageTime"
            label="Tiempo"
            error={true}
            errorItem={errors?.ageTime}
            classes="textUpper"
            formClasses="w-mid"
            defaultValue={patientInfo.ageTime}
            items={[
              {
                id: "days",
                value: "DIAS",
              },
              {
                id: "months",
                value: "MESES",
              },
              {
                id: "years",
                value: "AÑOS",
              },
            ]}
            handler={handleFormValues}
            disabled={role === "paciente"}
          />
        </div>

        <SelectComponent
          control={control}
          name="genre"
          label="Genero"
          error={true}
          errorItem={errors?.genre}
          classes="textUpper"
          defaultValue={patientInfo.genre}
          items={[
            {
              id: "male",
              value: "MASCULINO",
            },
            {
              id: "female",
              value: "FEMENINO",
            },
            {
              id: "other",
              value: "OTRO",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />

        <SelectComponent
          control={control}
          name="documentType"
          label="Tipo de Documento"
          error={true}
          errorItem={errors?.documentType}
          classes="textUpper"
          defaultValue={patientInfo.documentType}
          items={[
            {
              id: "cc",
              value: "CÉDULA DE CIUDADANÍA",
            },
            {
              id: "ti",
              value: "TARJETA DE IDENTIDAD",
            },
            {
              id: "rc",
              value: "REGISTRO CIVIL",
            },
            {
              id: "passport",
              value: "PASAPORTE",
            },
            {
              id: "ce",
              value: "CÉDULA DE EXTRANJERO",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />

        <Controller
          name="identification"
          control={control}
          defaultValue={patientInfo.identification}
          label="Número de Identificación"
          variant="outlined"
          as={TextField}
          helperText={errors?.identification?.message}
          error={errors?.identification ? true : false}
          type="number"
          disabled={role === "paciente"}
        />
        <SelectComponent
          control={control}
          name="maritalStatus"
          label="Estado Civil"
          error={true}
          errorItem={errors?.maritalStatus}
          classes="textUpper"
          defaultValue={patientInfo.maritalStatus}
          items={[
            {
              id: "single",
              value: "SOLTERO(A)",
            },
            {
              id: "married",
              value: "CASADO(A)",
            },
            {
              id: "freeunion",
              value: "UNION LIBRE",
            },
            {
              id: "divorced",
              value: "DIVORCIADO(A)",
            },
            {
              id: "widow",
              value: "VIUDO(A)",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />

        <Controller
          render={(props) => (
            <Autocomplete
              {...props}
              disabled={role === "paciente"}
              value={patientInfo.bornDepartment}
              noOptionsText={"Sin resultados"}
              options={handleLoadList(departmentsList)}
              filterOptions={filterOptions}
              getOptionSelected={(option, value) => option.name === value}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option.toUpperCase();
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue.toUpperCase();
                }
                // Regular option
                return option.name.toUpperCase();
              }}
              renderOption={(option) => (
                <span>{option.name.toUpperCase()}</span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Departamento de Nacimiento"
                  variant="outlined"
                  error={errors?.bornDepartment ? true : false}
                  helperText={errors?.bornDepartment?.message}
                  disabled={role === "paciente"}
                />
              )}
              onChange={(_, data) =>
                handleFormValuesList(
                  "bornDepartment",
                  data,
                  true,
                  "departamentos",
                  departmentsList
                )
              }
            />
          )}
          name="bornDepartment"
          control={control}
        />

        <Controller
          render={(props) => (
            <Autocomplete
              {...props}
              disabled={role === "paciente"}
              value={patientInfo.bornMunicipio}
              noOptionsText={"Sin resultados"}
              options={handleLoadList(municipioList, {
                name: patientInfo.bornDepartment,
                field: "depto",
              })}
              filterOptions={filterOptions}
              getOptionSelected={(option, value) => option.name === value}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option.toUpperCase();
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue.toUpperCase();
                }
                // Regular option
                return option.name.toUpperCase();
              }}
              renderOption={(option) => (
                <span>{option.name.toUpperCase()}</span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Municipio de Nacimiento"
                  variant="outlined"
                  error={errors?.bornMunicipio ? true : false}
                  helperText={errors?.bornMunicipio?.message}
                  disabled={role === "paciente"}
                />
              )}
              onChange={(_, data) =>
                handleFormValuesList(
                  "bornMunicipio",
                  data,
                  false,
                  "municipios",
                  municipioList,
                  patientInfo.bornDepartment
                )
              }
            />
          )}
          name="bornMunicipio"
          control={control}
        />

        <Controller
          render={(props) => (
            <Autocomplete
              {...props}
              disabled={role === "paciente"}
              value={patientInfo.bornCity}
              noOptionsText={"Sin resultados"}
              filterOptions={filterOptions}
              options={handleLoadList(
                cityList,
                {
                  name: patientInfo.bornMunicipio,
                  field: "municipio",
                },
                {
                  name: patientInfo.bornDepartment,
                  field: "depto",
                }
              )}
              getOptionSelected={(option, value) => option.name === value}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option.toUpperCase();
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue.toUpperCase();
                }
                // Regular option
                return option.name.toUpperCase();
              }}
              renderOption={(option) => (
                <span>{option.name.toUpperCase()}</span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ciudad de Nacimiento"
                  variant="outlined"
                  error={errors?.bornCity ? true : false}
                  helperText={errors?.bornCity?.message}
                  disabled={role === "paciente"}
                />
              )}
              onChange={(_, data) =>
                handleFormValuesCity("bornCity", data, cityList, {
                  municipio: patientInfo.bornMunicipio,
                  departamento: patientInfo.bornDepartment,
                })
              }
            />
          )}
          name="bornCity"
          control={control}
        />

        <Controller
          render={(props) => (
            <Autocomplete
              {...props}
              disabled={role === "paciente"}
              value={patientInfo.department}
              noOptionsText={"Sin resultados"}
              options={handleLoadList(departmentsList)}
              filterOptions={filterOptions}
              getOptionSelected={(option, value) => option.name === value}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option.toUpperCase();
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue.toUpperCase();
                }
                // Regular option
                return option.name.toUpperCase();
              }}
              renderOption={(option) => (
                <span>{option.name.toUpperCase()}</span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Departamento de Domicilio"
                  variant="outlined"
                  error={errors?.department ? true : false}
                  helperText={errors?.department?.message}
                  disabled={role === "paciente"}
                />
              )}
              onChange={(_, data) =>
                handleFormValuesList(
                  "department",
                  data,
                  true,
                  "departamentos",
                  departmentsList
                )
              }
            />
          )}
          name="department"
          control={control}
        />

        <Controller
          render={(props) => (
            <Autocomplete
              {...props}
              disabled={role === "paciente"}
              value={patientInfo.municipio}
              noOptionsText={"Sin resultados"}
              options={handleLoadList(municipioList, {
                name: patientInfo.department,
                field: "depto",
              })}
              filterOptions={filterOptions}
              getOptionSelected={(option, value) => option.name === value}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option.toUpperCase();
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue.toUpperCase();
                }
                // Regular option
                return option.name.toUpperCase();
              }}
              renderOption={(option) => (
                <span>{option.name.toUpperCase()}</span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Municipio de Domicilio"
                  variant="outlined"
                  error={errors?.municipio ? true : false}
                  helperText={errors?.municipio?.message}
                  disabled={role === "paciente"}
                />
              )}
              onChange={(_, data) =>
                handleFormValuesList(
                  "municipio",
                  data,
                  false,
                  "municipios",
                  municipioList,
                  patientInfo.department
                )
              }
            />
          )}
          name="municipio"
          control={control}
        />

        <Controller
          render={(props) => (
            <Autocomplete
              {...props}
              disabled={role === "paciente"}
              value={patientInfo.city}
              noOptionsText={"Sin resultados"}
              filterOptions={filterOptions}
              options={handleLoadList(
                cityList,
                {
                  name: patientInfo.municipio,
                  field: "municipio",
                },
                {
                  name: patientInfo.department,
                  field: "depto",
                }
              )}
              getOptionSelected={(option, value) => option.name === value}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option.toUpperCase();
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue.toUpperCase();
                }
                // Regular option
                return option.name.toUpperCase();
              }}
              renderOption={(option) => (
                <span>{option.name.toUpperCase()}</span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ciudad de Domicilio"
                  variant="outlined"
                  error={errors?.city ? true : false}
                  helperText={errors?.city?.message}
                  disabled={role === "paciente"}
                />
              )}
              onChange={(_, data) =>
                handleFormValuesCity("city", data, cityList, {
                  municipio: patientInfo.municipio,
                  departamento: patientInfo.department,
                })
              }
            />
          )}
          name="city"
          control={control}
        />

        <SelectComponent
          control={control}
          name="zone"
          label="Zona"
          error={true}
          errorItem={errors?.zone}
          classes="textUpper"
          value={patientInfo.zone}
          handler={handleFormValues}
          items={[
            {
              id: "urbana",
              value: "URBANA",
            },
            {
              id: "rural",
              value: "RURAL",
            },
          ]}
          disabled={role === "paciente"}
        />

        <Controller
          name="address"
          control={control}
          defaultValue={patientInfo.address}
          label="Dirección"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.address?.message}
          error={errors?.address ? true : false}
          disabled={role === "paciente"}
        />

        <Controller
          name="neighborhood"
          control={control}
          defaultValue={patientInfo.neighborhood}
          label="Barrio"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.neighborhood?.message}
          error={errors?.neighborhood ? true : false}
          disabled={role === "paciente"}
        />

        <Controller
          name="locality"
          control={control}
          defaultValue={patientInfo.locality}
          label={patientInfo.zone === "rural" ? "Vereda" : "Localidad"}
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.locality?.message}
          error={errors?.locality ? true : false}
          disabled={role === "paciente"}
        />

        <Controller
          name="strate"
          control={control}
          defaultValue={patientInfo.strate}
          label="Estrato"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.strate?.message}
          error={errors?.strate ? true : false}
          disabled={role === "paciente"}
        />

        <Controller
          name="phone"
          control={control}
          defaultValue={patientInfo.phone}
          label="Teléfono(s)"
          variant="outlined"
          width={1}
          maxLength="40"
          as={TextField}
          inputProps={{ maxLength: 40 }}
          helperText={errors?.phone?.message}
          error={errors?.phone ? true : false}
          disabled={role === "paciente"}
        />

        <Controller
          name="email"
          control={control}
          defaultValue={patientInfo.email}
          label="E-mail"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.email?.message}
          error={errors?.email ? true : false}
          disabled={role === "paciente"}
        />

        <SelectComponent
          control={control}
          name="academic"
          label="Vinculado Académicamente"
          error={true}
          errorItem={errors?.academic}
          classes="textUpper"
          defaultValue={patientInfo.academic}
          items={[
            {
              id: "si",
              value: "SI",
            },
            {
              id: "no",
              value: "NO",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />

        <SelectComponent
          control={control}
          name="academicLevel"
          label="Nivel Educativo"
          error={true}
          errorItem={errors?.academicLevel}
          classes="textUpper"
          defaultValue={patientInfo.academicLevel}
          items={[
            {
              id: "Preescolar",
              value: "PREESCOLAR",
            },
            {
              id: "Primaria",
              value: "PRIMARIA",
            },
            {
              id: "Bachillerato",
              value: "BACHILLERATO",
            },
            {
              id: "Técnico",
              value: "TÉCNICO",
            },
            {
              id: "Tecnologo",
              value: "TECNOLOGO",
            },
            {
              id: "Pregrado",
              value: "PREGRADO",
            },
            {
              id: "Posgrado",
              value: "POSGRADO",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />

        {/* New Fields 9/9/2024 */}

        <Controller
          name="academicInstitution"
          control={control}
          defaultValue={patientInfo.academicInstitution}
          label="Institución"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.academicInstitution?.message}
          error={errors?.academicInstitution ? true : false}
          disabled={role === "paciente"}
          className={getValues("academic") === "si" ? "" : "hidden"}
        />
        <SelectComponent
          control={control}
          name="academicType"
          label="Tipo de Institución"
          error={true}
          errorItem={errors?.academicType}
          classes={`textUpper`}
          formClasses={`${getValues("academic") === "si" ? "" : "hidden"}`}
          defaultValue={patientInfo.academicType}
          items={[
            {
              id: "privada",
              value: "PRIVADA",
            },
            {
              id: "publica",
              value: "PUBLICA",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />
        <SelectComponent
          control={control}
          name="academicHasHelp"
          label="¿La institución cuenta con la estructura adecuada para su movilización?"
          error={true}
          errorItem={errors?.academicHasHelp}
          classes={`textUpper`}
          formClasses={`${getValues("academic") === "si" ? "" : "hidden"}`}
          defaultValue={patientInfo.academicHasHelp}
          items={[
            {
              id: "si",
              value: "SI",
            },
            {
              id: "no",
              value: "NO",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />
        <SelectComponent
          control={control}
          name="academicIsSpecial"
          label="¿La institución es especial?"
          error={true}
          errorItem={errors?.academicIsSpecial}
          classes={`textUpper`}
          formClasses={`${getValues("academic") === "si" ? "" : "hidden"}`}
          defaultValue={patientInfo.academicIsSpecial}
          items={[
            {
              id: "si",
              value: "SI",
            },
            {
              id: "no",
              value: "NO",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />
        <Controller
          name="academicInstitutionLevel"
          control={control}
          defaultValue={patientInfo.academicInstitutionLevel}
          label="Curso/Nivel"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.academicInstitutionLevel?.message}
          error={errors?.academicInstitutionLevel ? true : false}
          disabled={role === "paciente"}
          className={`textUpper ${
            getValues("academic") === "si" ? "" : "hidden"
          }`}
        />

        <Controller
          name="occupation"
          control={control}
          defaultValue={patientInfo.occupation}
          label="Ocupación"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.occupation?.message}
          error={errors?.occupation ? true : false}
          disabled={role === "paciente"}
          className={`textUpper ${
            getValues("academic") === "si" ? "" : "hidden"
          }`}
        />

        <SelectComponent
          control={control}
          name="labour"
          label="Vinculado Laboralmente"
          error={true}
          errorItem={errors?.labour}
          classes={`textUpper`}
          defaultValue={patientInfo.labour}
          items={[
            {
              id: "si",
              value: "SI",
            },
            {
              id: "no",
              value: "NO",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />

        <Controller
          name="labourType"
          control={control}
          defaultValue={patientInfo.labourType}
          label="Tipo de vinculación laboral"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.labourType?.message}
          error={errors?.labourType ? true : false}
          disabled={role === "paciente"}
          className={`${getValues("labour") === "si" ? "" : "hidden"}`}
        />
        <SelectComponent
          control={control}
          name="labourEconomicContributor"
          label="Paciente aporta económicamente a su núcleo"
          error={true}
          errorItem={errors?.labourEconomicContributor}
          classes={`textUpper`}
          formClasses={`${getValues("labour") === "si" ? "" : "hidden"}`}
          defaultValue={patientInfo.labourEconomicContributor}
          items={[
            {
              id: "si",
              value: "SI",
            },
            {
              id: "no",
              value: "NO",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />
        <SelectComponent
          control={control}
          name="labourIncome"
          label="Ingreso Económico"
          error={true}
          errorItem={errors?.labourIncome}
          classes={`textUpper`}
          formClasses={`${getValues("labour") === "si" ? "" : "hidden"}`}
          defaultValue={patientInfo.labourIncome}
          items={[
            {
              id: "menor",
              value: "MENOR A 1 SMMLV",
            },
            {
              id: "entre",
              value: "ENTRE 1 Y 2 SMMLV",
            },
            {
              id: "mayor",
              value: "MAYOR A 2 SMMLV",
            },
            {
              id: "na",
              value: "NO APLICA",
            },
          ]}
          handler={handleFormValues}
          disabled={role === "paciente"}
        />

        <Controller
          name="patientAssociation"
          control={control}
          defaultValue={patientInfo.patientAssociation}
          label="Asociación a la que pertenece el paciente"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.patientAssociation?.message}
          error={errors?.patientAssociation ? true : false}
          disabled={role === "paciente"}
        />

        {/* End of New Fields 9/9/2024 */}

        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={patientInfo.needCarer}
              disabled={role === "paciente"}
              onChange={(event) => {
                handleFormValues("needCarer", event.target.checked);
              }}
              name="needCarer"
            />
          }
          label="¿Requiere Cuidador?"
        />
      </Box>
      <Box
        style={{
          padding: "0px 24px 0px 24px",
        }}
      >
        <Controller
          name="observations"
          control={control}
          value={patientInfo.observations}
          label="Observaciones"
          variant="outlined"
          width={1}
          multiline={true}
          minRows={3}
          style={{ width: "100%" }}
          as={TextField}
          error={errors?.observations ? true : false}
          disabled={role === "paciente"}
          helperText={`${
            watch("observations") ? watch("observations").length : 0
          }/400`}
          inputProps={{ style: { maxLength: 400, textTransform: "uppercase" } }}
        />
      </Box>
    </Box>
  );
};

PatientInfo.propTypes = {
  changeTab: PropTypes.func,
  closeDialog: PropTypes.func,
  setCarer: PropTypes.func,
};

export default PatientInfo;
