const defaults = {
  fillingDate: null,
  pspStartDate: null,
  id: "",
  programName: "",
  name: "",
  identification: "",
  bornMunicipio: "",
  locality: "",
  occupation: "",
  ageTime: "",
  maritalStatus: "",
  bornCity: "",
  genre: "",
  bornDate: null,
  email: "",
  department: "",
  observationsInitial: "",
  bornDepartment: "",
  academicLevel: "",
  academicInstitutionLevel: "",
  address: "",
  documentType: "",
  age: "",
  status: "",
  phone: "",
  neighborhood: "",
  city: "",
  photo: "",
  observationsInfo: "",
  academic: "",
  municipio: "",
  strate: "",
  observacionVivienda: "",
  tenenciaVivienda: "",
  viviendaOtro: "",
  vivienda: "",
  agua: false,
  gas: false,
  luz: false,
  internet: false,
  basuras: false,
  telefono: false,
  alcantarillado: false,
  educativas: false,
  bibliotecas: false,
  transporte: false,
  salud: false,
  parques: false,
  tenenciaViviendaCual: "",
  nombreVereda: "",
  observationsFinal: "",
  signature: "",
  movementForm: "",
  conditionsList:
    '[{"name":"Pavimentada","excelente":false,"buena":false,"regular":false,"mala":false},{"name":"Destapada","excelente":false,"buena":false,"regular":false,"mala":false},{"name":"Pendiente y no llegan vehículos","excelente":false,"buena":false,"regular":false,"mala":false}]',
  observationsConditions: "",
  educativeCare: "",
  regimenCare: "",
  phoneCare: "",
  carerName: "",
  occupationCare: "",
  ageCare: "",
  parentalCare: "",
  correoCare: "",
  identificationCare: "",
  epsCare: "",
  documentTypeCare: "",
  ageTimeCarer: "",
  sizeInfo: "",
  doctor: "",
  size: "",
  drugCode: "",
  eps: "",
  weight: "",
  dxDate: null,
  treatmentStart: null,
  notGetTreatment: "",
  drugName: "",
  getTreatment: "",
  dxsupport: "",
  weightInfo: "",
  discapacidad: "",
  diagnosis: "",
  speciality: "",
  discapacidadCert: "",
  needHelp: "",
  needCarer: false,
  centroAplicacion: "",
  techHelp: "",
  regimen: "",
  discapacidadType: "",
  nameTreatment: "",
  familiaMismaPatologia: false,
  parteRegistro: false,
  recibeTratamiento: false,
  familiaOtrasPatologias: false,
  familyList: "[]",
  whatPatology: "",
  personsNumber: "",
  treatmentObservations: "",
  whoPatology: "",
  yearRegisterVictim: "",
  whoFamily: "",
  familyInfo: {
    familyList: "[]",
    familyType: "",
    personsNumber: "",
    yearRegisterVictim: "",
    whoFamily: "",
    whatPatology: "",
    whoPatology: "",
    nameTreatment: "",
    treatmentObservations: "",
    state: {
      parteRegistro: false,
      familiaMismaPatologia: false,
      familiaOtrasPatologias: false,
      recibeTratamiento: false,
    },
  },

  // New Fields
  // PatientInfo
  academicInstitution: "",
  academicType: "",
  academicHasHelp: "",
  academicIsSpecial: "",
  labour: "",
  labourType: "",
  labourEconomicContributor: "",
  labourIncome: "",
  patientAssociation: "",

  // Initial Status
  dxVariant: "",
  dxIsOrphan: "",
  dxNotSupport: "",
  dxMedicalCenter: "",
  dxApplicationCity: "",
  dxMedicalType: "",
  dxDosis: "",
  dxMedicalFrequency: "",
  dxAdministration: "",
  dxAdministrationOther: "",
  dxOrganTransplant: "",
  dxOrganTransplantWhich: "",
  discapacidadRegistered: "",

  // Vaccines need to be added dynamically

  // Carer Info
  carerIsViable: "",
  carerAbility: "",
  carerObservations: "",

  // Observations
  observationsViolence: "",
  observationsPsychosocial: "",
  signatureMaker: "",
};

export default defaults;
