import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import styles from "../Details.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dataService from "../../../../../services/dataService";
import {
  filterOptions,
  handleLoadList,
} from "../../../../../global/utils/autoCompleteUtils";
import SelectComponent from "../../../../Shared/SelectComponent";

const CarerInfo = (props) => {
  //Hooks and Vars
  const {
    control,
    errors,
    register,
    patientInfo,
    epsList,
    handleListRefetch,
    handleFormValues,
    watch,
  } = props;

  //Effects

  const handleFormValuesEPS = (field, value, list) => {
    if (value !== null) {
      let formatData = {
        name: value.inputValue ? value.inputValue : value.name,
      };
      handleFormValues("epsCare", formatData.name.toUpperCase());
      if (
        list.filter(
          (x) => x.name.toUpperCase() === formatData.name.toUpperCase()
        ).length <= 0
      ) {
        dataService
          .createDocument("eps", {
            name: formatData.name.toUpperCase(),
          })
          .then(() => {
            handleListRefetch("eps");
          });
      }
    }
  };

  return (
    <Box p={3}>
      <Box p={3} className={styles.gridContainerBase}>
        <Controller
          name="carerName"
          control={control}
          defaultValue={patientInfo.carerName}
          label="Nombres y Apellidos"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.carerName ? true : false}
          helperText={errors?.carerName?.message}
        />
        <FormControl
          variant="outlined"
          error={errors?.documentTypeCare ? true : false}
        >
          <InputLabel id="documentTypeCare-label">Tipo de Documento</InputLabel>
          <Controller
            control={control}
            name="documentTypeCare"
            variant="outlined"
            defaultValue={patientInfo.documentTypeCare}
            error={errors?.documentTypeCare ? true : false}
            as={({ _onChange, value }) => {
              return (
                <Select
                  label="Tipo de Documento"
                  labelId="documentTypeCare-label"
                  value={value}
                  onChange={(e) => {
                    handleFormValues("documentTypeCare", e.target.value);
                  }}
                >
                  <MenuItem value="cc">CEDULA DE CIUDADANÍA</MenuItem>
                  <MenuItem value="ti">TARJETA DE IDENTIDAD</MenuItem>
                  <MenuItem value="rc">REGISTRO CIVIL</MenuItem>
                  <MenuItem value="passport">PASAPORTE</MenuItem>
                  <MenuItem value="ce">CEDULA DE EXTRANJERO</MenuItem>
                </Select>
              );
            }}
            onChange={(val) => {
              return val;
            }}
          />
          <FormHelperText error={errors?.documentTypeCare}>
            {errors?.documentTypeCare?.message}
          </FormHelperText>
        </FormControl>

        <Controller
          name="identificationCare"
          control={control}
          defaultValue={patientInfo.identificationCare}
          label="Número de Identificación"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.identificationCare ? true : false}
          helperText={errors?.identificationCare?.message}
        />

        <Controller
          name="parentalCare"
          control={control}
          defaultValue={patientInfo.parentalCare}
          label="Parentesco"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.parentalCare ? true : false}
          helperText={errors?.parentalCare?.message}
        />

        <div style={{ display: "flex", gap: "5px" }}>
          <TextField
            name="ageCare"
            label="Edad"
            variant="outlined"
            defaultValue={patientInfo.ageCare}
            inputRef={register}
            type="number"
            style={{ width: "50%" }}
            helperText={errors?.ageCare?.message}
            error={errors?.ageCare ? true : false}
            min="0"
          />

          <FormControl
            variant="outlined"
            style={{ width: "50%" }}
            error={errors?.ageTimeCarer ? true : false}
          >
            <InputLabel id="ageTime-label">Tiempo</InputLabel>
            <Controller
              control={control}
              name="ageTimeCarer"
              variant="outlined"
              defaultValue={patientInfo.ageTimeCarer}
              as={({ _onChange, value }) => {
                return (
                  <Select
                    labelId="ageTime-label"
                    error={errors?.ageTimeCarer ? true : false}
                    label="Tiempo"
                    value={value}
                    onChange={(e) => {
                      handleFormValues("ageTimeCarer", e.target.value);
                    }}
                  >
                    <MenuItem value="days">DIAS</MenuItem>
                    <MenuItem value="months">MESES</MenuItem>
                    <MenuItem value="years">AÑOS</MenuItem>
                  </Select>
                );
              }}
              onChange={(val) => {
                return val;
              }}
            />
            <FormHelperText error={true}>
              {errors?.ageTimeCarer?.message}
            </FormHelperText>
          </FormControl>
        </div>

        <Controller
          render={(props) => (
            <Autocomplete
              {...props}
              defaultValue={patientInfo.epsCare}
              noOptionsText={"Sin resultados"}
              filterOptions={filterOptions}
              options={handleLoadList(epsList)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option.toUpperCase();
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue.toUpperCase();
                }
                // Regular option
                return option.name.toUpperCase();
              }}
              renderOption={(option) => (
                <span>{option.name.toUpperCase()}</span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="EPS"
                  variant="outlined"
                  error={errors?.epsCare ? true : false}
                  helperText={errors?.epsCare?.message}
                />
              )}
              onChange={(_, data) =>
                handleFormValuesEPS("epsCare", data, epsList)
              }
            />
          )}
          name="epsCare"
          control={control}
        />

        <FormControl
          variant="outlined"
          error={errors?.regimenCare ? true : false}
        >
          <InputLabel id="regimenCare-label">Regimen</InputLabel>
          <Controller
            control={control}
            name="regimenCare"
            variant="outlined"
            defaultValue={patientInfo.regimenCare}
            as={({ _onChange, value }) => {
              return (
                <Select
                  label="Regimen"
                  labelId="regimenCare-label"
                  error={errors?.regimenCare ? true : false}
                  value={value}
                  onChange={(e) => {
                    handleFormValues("regimenCare", e.target.value);
                  }}
                >
                  <MenuItem value="subsidiado">SUBSIDIADO</MenuItem>
                  <MenuItem value="contributivo">CONTRIBUTIVO</MenuItem>
                  <MenuItem value="especial">ESPECIAL</MenuItem>
                </Select>
              );
            }}
            onChange={(val) => {
              return val;
            }}
          />
          <FormHelperText error={true}>
            {errors?.regimenCare?.message}
          </FormHelperText>
        </FormControl>

        <Controller
          name="correoCare"
          control={control}
          defaultValue={patientInfo.correoCare}
          label="Correo electrónico"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.correoCare ? true : false}
          helperText={errors?.correoCare?.message}
        />

        <FormControl
          variant="outlined"
          error={errors?.educativeCare ? true : false}
        >
          <InputLabel id="educativeCare-label">Nivel Educativo</InputLabel>
          <Controller
            control={control}
            name="educativeCare"
            variant="outlined"
            defaultValue={patientInfo.educativeCare}
            as={({ _onChange, value }) => {
              return (
                <Select
                  label="Nivel Educativo"
                  labelId="educativeCare-label"
                  error={errors?.educativeCare ? true : false}
                  value={value}
                  onChange={(e) => {
                    handleFormValues("educativeCare", e.target.value);
                  }}
                >
                  <MenuItem value="Preescolar">PREESCOLAR</MenuItem>
                  <MenuItem value="Primaria">PRIMARIA</MenuItem>
                  <MenuItem value="Bachillerato">BACHILLERATO</MenuItem>
                  <MenuItem value="Técnico">TÉCNICO</MenuItem>
                  <MenuItem value="Tecnologo">TECNOLOGO</MenuItem>
                  <MenuItem value="Pregrado">PREGRADO</MenuItem>
                  <MenuItem value="Posgrado">POSGRADO</MenuItem>
                </Select>
              );
            }}
            onChange={(val) => {
              return val;
            }}
          />
          <FormHelperText error={true}>
            {errors?.educativeCare?.message}
          </FormHelperText>
        </FormControl>

        <Controller
          name="occupationCare"
          control={control}
          defaultValue={patientInfo.occupationCare}
          label="Ocupación"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.occupationCare ? true : false}
          helperText={errors?.occupationCare?.message}
        />
        <Controller
          name="phoneCare"
          control={control}
          defaultValue={patientInfo.phoneCare}
          label="Teléfono"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.phoneCare ? true : false}
          helperText={errors?.phoneCare?.message}
        />

        <SelectComponent
          control={control}
          name="carerIsViable"
          label="Apto para realizar gestiones administrativas y acompañamientos"
          error={true}
          errorItem={errors?.carerIsViable}
          classes="textUpper"
          defaultValue={patientInfo.carerIsViable}
          items={[
            {
              id: "si",
              value: "SI",
            },
            {
              id: "no",
              value: "NO",
            },
          ]}
          handler={handleFormValues}
        />
        <Controller
          name="carerAbility"
          control={control}
          defaultValue={patientInfo.carerAbility}
          label="Habilidades"
          variant="outlined"
          width={1}
          as={TextField}
          helperText={errors?.carerAbility?.message}
          error={errors?.carerAbility ? true : false}
          className="col-span-4 w-full"
        />
      </Box>
      <Box
        style={{
          padding: "0px 24px 0px 24px",
        }}
      >
        <Controller
          name="carerObservations"
          control={control}
          defaultValue={patientInfo.carerObservations}
          label="Observaciones"
          variant="outlined"
          width={1}
          multiline={true}
          minRows={3}
          style={{ width: "100%" }}
          as={TextField}
          error={errors?.carerObservations ? true : false}
          helperText={`${
            watch("carerObservations") ? watch("carerObservations").length : 0
          }/400`}
          inputProps={{
            style: { maxLength: 400, textTransform: "uppercase" },
          }}
        />
      </Box>
    </Box>
  );
};

CarerInfo.propTypes = {
  changeTab: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default CarerInfo;
