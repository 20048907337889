import React, { useState, useCallback, useContext, useEffect } from "react";
import { analytics, auth, firestore, firebase } from "../firebase";
import { useGlobal } from "./GlobalContext";
import { mapCollection } from "../global/utils/mapCollection";
import { isEmpty, isNull, isUndefined } from "lodash";

export const AuthContext = React.createContext({
  isAuthenticated: false,
  user: null,
  roles: [],
  doLogin: () => {},
  doLogout: () => {},
  auth: auth,
  handleMicrosoftLogin: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : null || null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("currentUser") ? true : false
  );
  const [roles, setRoles] = useState([]);
  const { setCurrentTheme, showSnackBar, setLoading } = useGlobal();

  const handleError = useCallback(
    (error, showMessage = true) => {
      const code = error.code;
      const message = error.message;
      console.error("AuthContext", code, message);
      if (showMessage) {
        showSnackBar(message);
      }
    },
    [showSnackBar]
  );

  const handleMicrosoftLogin = () => {
    const provider = new firebase.auth.OAuthProvider("microsoft.com");
    auth
      .signInWithPopup(provider)
      .then(async (result) => {
        let credential = result.credential;
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        const resultData = {
          idToken,
          accessToken,
        };
        localStorage.setItem("currentUserMetaData", JSON.stringify(resultData));
        localStorage.setItem("isFederated", "true");
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const unlisten = auth.onAuthStateChanged(
      (authUser) => {
        // The user is not signed in or doesn’t have a user ID.
        if (!authUser) {
          setUser(null);
          setIsAuthenticated(false);
          setRoles(null);
          localStorage.removeItem("currentUser");
          return;
        }
        const authUserData = authUser;
        // The user is signed in, begin retrieval of external user data.
        firestore
          .collection("users")
          .get({ source: "server" })
          .then(
            (result) => {
              const collectionMapped = mapCollection(result);
              const checkUser = collectionMapped.find(
                (x) =>
                  x.mail?.toUpperCase() === authUserData.email?.toUpperCase()
              );
              // The user doesn’t have a data point, equivalent to not signed in.
              if (
                isNull(checkUser) ||
                isUndefined(checkUser) ||
                isEmpty(checkUser)
              ) {
                setUser(null);
                setIsAuthenticated(false);
                setRoles(null);
                localStorage.removeItem("currentUser");
                return;
              } else {
                const userData = {
                  uid: checkUser.id,
                  ...authUserData,
                  ...checkUser,
                  id: checkUser.id,
                  roles: checkUser?.role || "nonConnected",
                };
                if (typeof checkUser.theme !== "undefined") {
                  setCurrentTheme(checkUser.theme);
                }
                setUser(userData);
                setIsAuthenticated(true);
                setRoles(userData.roles);
                localStorage.setItem("currentUser", JSON.stringify(userData));
              }
            },
            (error) => {
              handleError(error);
              setIsAuthenticated(false);
              setUser(null);
              setRoles(null);
              localStorage.removeItem("currentUser");
            }
          );
      },
      (error) => {
        handleError(error, false);
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem("currentUser");
      }
    );
    return () => {
      unlisten();
    };
  }, [setCurrentTheme, showSnackBar, handleError]);

  const doLogout = useCallback(() => {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error("No hay usuario con sesión iniciada"));
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem("currentUser");
        return;
      }

      auth
        .signOut()
        .then((signOutResult) => {
          analytics.logEvent("sign_out");
          setIsAuthenticated(false);
          setUser(null);
          localStorage.removeItem("currentUser");
          resolve(signOutResult);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }, []);

  const doLogin = useCallback(
    (emailAddress, password) => {
      return new Promise((resolve, reject) => {
        if (!emailAddress || !password) {
          reject(new Error("Email y contraseña requeridos"));
          return;
        }

        if (auth.currentUser) {
          doLogout();
          showSnackBar(
            "Existe una sesión activa, cerrando sesión, vuelva a intentar"
          );
          reject(new Error("Usuario con sesión iniciada"));
          return;
        }

        auth
          .signInWithEmailAndPassword(emailAddress, password)
          .then((signInResult) => {
            const userResult = signInResult.user;
            if (!userResult) {
              reject(
                new Error("No se pudo iniciar sesión, usuario no encontrado")
              );
              return;
            }

            const uid = userResult.uid;

            if (!uid) {
              reject(new Error("No se pudo iniciar sesión, sin UID"));
              return;
            }

            const userDocumentReference = firestore
              .collection("users")
              .doc(uid);

            userDocumentReference
              .get({ source: "server" })
              .then(() => {
                userDocumentReference
                  .set({ loginDate: new Date() }, { merge: true })
                  .then(() => {
                    analytics.logEvent("login", {
                      method: "password",
                    });
                    resolve(userResult);
                  })
                  .catch((reason) => {
                    reject(reason);
                  });
              })
              .catch((reason) => {
                reject(reason);
              });
          })
          .catch((reason) => {
            reject(reason);
          });
      });
    },
    [doLogout, showSnackBar]
  );

  const value = {
    isAuthenticated,
    roles,
    user,
    doLogin,
    doLogout,
    auth,
    handleMicrosoftLogin,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
