import PropTypes from "prop-types";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";

const BarItem = (props) => {
  return (
    <Tooltip
      title={props.label}
      placement={"right"}
      disableHoverListener={props.hidden}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <ListItem button component={props.component} to={props.route}>
        <ListItemIcon className={props.classes}>{props.icon}</ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItem>
    </Tooltip>
  );
};

BarItem.propTypes = {
  hidden: PropTypes.any.isRequired,
  component: PropTypes.any.isRequired,
  icon: PropTypes.any.isRequired,
  route: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
};

export default BarItem;
