import SelectComponent from "../../../../../Shared/SelectComponent";
import ControlledDatePicker from "../../../../../Shared/ControlledDatePicker";
import { Box } from "@material-ui/core";

const VaccineRenderer = ({
  vaccine,
  control,
  patientInfo,
  errors,
  handleFormValues,
}) => {
  const vaccineId = `vaccine${vaccine.id}`;
  console.log("patientInfo", patientInfo);
  return (
    <Box p={3} className={"gridContainerBase"}>
      <SelectComponent
        control={control}
        name={`${vaccineId}_status`}
        label={`Vacuna ${vaccine.label}`}
        error={true}
        errorItem={errors?.[`${vaccineId}_status`]}
        classes="textUpper"
        defaultValue={patientInfo[`${vaccineId}_status`]}
        items={[
          {
            id: "si",
            value: "SI",
          },
          {
            id: "no",
            value: "NO",
          },
          {
            id: "noAplica",
            value: "NA",
          },
        ]}
        handler={handleFormValues}
      />
      <ControlledDatePicker
        name={`${vaccineId}_fecha`}
        control={control}
        value={patientInfo[`${vaccineId}_fecha`]}
        handleFormValues={handleFormValues}
        label={`Fecha vacuna ${vaccine.label}`}
        errors={errors}
      />

      <SelectComponent
        control={control}
        name={`${vaccineId}_refuerzo`}
        label={`Refuerzo ${vaccine.label}`}
        error={true}
        errorItem={errors?.[`${vaccineId}_refuerzo`]}
        classes="textUpper"
        defaultValue={patientInfo[`${vaccineId}_refuerzo`]}
        items={[
          {
            id: "si",
            value: "SI",
          },
          {
            id: "no",
            value: "NO",
          },
          {
            id: "noAplica",
            value: "NA",
          },
        ]}
        handler={handleFormValues}
      />
    </Box>
  );
};

export default VaccineRenderer;
