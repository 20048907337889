const cleanUndefs = (obj) => {
  const clean = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      clean[key] = "";
    } else {
      clean[key] = obj[key];
    }
  });
  return clean;
};

export default cleanUndefs;
