import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { IconButton, Drawer, List, CssBaseline } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import BarItem from "./elements/BarItem";
import { useAuth } from "../../context/AuthContext";
import MainBar from "./elements/MainBar";

const DesktopBar = (props) => {
  const version = process.env.REACT_APP_VERSION;
  const { drawerItems, className, menuItems } = props;
  const [open, setOpen] = React.useState(false);
  const [devMode, setDevMode] = React.useState(0);
  const drawerWidth = 320;
  const { user } = useAuth();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      background: "#37b373",
    },
    drawerOpen: {
      background: "#37b373",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      background: "#37b373",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(9) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    list: {
      marginTop: "20vh",
    },
    iconList: {
      marginLeft: "6px",
    },
    title: {
      marginLeft: "64px",
    },
    toolbarGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    },
    buttonFix: {
      justifySelf: "end",
    },
    mainColor: {
      backgroundColor: "#ffff",
    },
  }));

  const classes = useStyles();

  //Methods

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const checkRole = () => {
    return (
      user.role === "laboratorio" ||
      user.role === "ucl" ||
      user.role === "aseguradora" ||
      user.role === "medical" ||
      user.role === "acceso" ||
      user.role === "comercial"
    );
  };

  const enableDevMode = () => {
    if (devMode === 7) {
      localStorage.setItem("toggleDevtools", true);
      window.location.reload();
    } else {
      setDevMode(devMode + 1);
    }
  };

  return (
    <div className={`${className} z-[99999]`}>
      <CssBaseline />
      <MainBar menuItems={menuItems} />
      <Drawer
        onBlur={() => setOpen(false)}
        open={open}
        variant={"permanent"}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          invisible: user && checkRole(),
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {open === true ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <List className={classes.list}>
          {drawerItems.filter(x => x.isEnabled).map((item, index) => {
            if (
              typeof user?.role !== "undefined" &&
              (item.rol.includes(user.role) || item.rol.includes("all"))
            ) {
              return (
                <BarItem
                  key={item.label}
                  component={Link}
                  icon={item.icon}
                  route={item.route}
                  label={item.label}
                  classes={classes.iconList}
                  hidden={open}
                />
              );
            } else {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}
        </List>
        <div className="fixed bottom-2 left-5 select-none">
          <span
            className="text-xs text-gray-700"
            onClick={() => enableDevMode()}
          >
            {version}
          </span>
        </div>
      </Drawer>
    </div>
  );
};

DesktopBar.propTypes = {
  // Events
  drawerItems: PropTypes.array.isRequired,
};

export default DesktopBar;
