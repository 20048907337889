import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

const SelectComponent = (props) => {
  return (
    <FormControl
      variant="outlined"
      className={props.formClasses}
      style={props.style}
      fullWidth={true}
      disabled={props.disabled || false}
      error={props.errorItem ? true : false}
    >
      <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
      {props.control ? (
        <Controller
          control={props.control}
          name={props.name}
          defaultValue={props.defaultValue}
          as={({ _onChange, value }) => (
            <Select
              value={value}
              disabled={props.disabled || false}
              labelId={`${props.name}-label`}
              label={props.label}
              error={props.errorItem ? true : false}
              className={props.classes}
              onChange={(e) => {
                if (props.handler) {
                  props.handler(props.name, e.target.value);
                }
              }}
              fullWidth={true}
            >
              {props.items &&
                props.items.map((item, index) => (
                  <MenuItem key={index} value={item[props.idKey || "id"]}>
                    {item.value?.toUpperCase() || item.name?.toUpperCase()}
                  </MenuItem>
                ))}
            </Select>
          )}
          onChange={(val) => {
            return val;
          }}
        />
      ) : (
        <Select
          name={props.name}
          disabled={props.disabled || false}
          labelId={`${props.name}-label`}
          label={props.label}
          defaultValue={props.defaultValue}
          error={props.errorItem ? true : false}
          className={props.classes}
          fullWidth={true}
          onChange={(e) => {
            if (props.handler) {
              props.handler(props.name, e.target.value);
            }
          }}
        >
          {props.items &&
            props.items.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.value}
              </MenuItem>
            ))}
        </Select>
      )}

      <FormHelperText error={props.error}>
        {props.errorItem?.message}
      </FormHelperText>
    </FormControl>
  );
};

SelectComponent.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  error: PropTypes.bool.isRequired,
  errorItem: PropTypes.object,
  classes: PropTypes.string,
  formClasses: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  items: PropTypes.array.isRequired,
  handler: PropTypes.func,
};

export default SelectComponent;
