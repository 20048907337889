import { isEmpty, isUndefined } from "lodash";
import { firestore } from "../firebase";
import { useQuery } from "@tanstack/react-query";

const mapCollection = (result, order, orderField = "name") => {
  const results = result.docs.map((docSnapshot) => {
    let itemEl = docSnapshot.data();
    itemEl.id = docSnapshot.id;
    return itemEl;
  });

  if (typeof order !== "undefined") {
    return results.sort((a, b) => a[orderField].localeCompare(b[orderField]));
  }
  return results;
};

const getData = async (
  collection,
  field,
  data,
  order,
  orderField,
  queryVars,
  ignoreQuery
) => {
  let query;
  const collectionRef = firestore.collection(collection);

  //Validate Params
  if (typeof field !== "undefined" && typeof data !== "undefined") {
    query = collectionRef.where(field, "==", data);
  } else {
    query = collectionRef;
  }

  console.log("Ignore Query", ignoreQuery);

  if (!ignoreQuery) {
    if (!isUndefined(queryVars) && !isEmpty(queryVars)) {
      queryVars.forEach((queryVar) => {
        query = query.where(queryVar.field, queryVar.operator, queryVar.value);
      });
    }
  }

  return query
    .get({
      source: "server",
    })
    .then((result) => {
      return mapCollection(result, order, orderField) || [];
    })
    .catch((error) => {
      console.error(`Error Fetching ${collection} data -`, error);
      return [];
    });
};

export default function useData(
  collection,
  field,
  data,
  order,
  orderField,
  queryVars,
  ignoreQuery = false
) {
  return useQuery(
    [
      collection,
      collection,
      field,
      data,
      order,
      orderField,
      queryVars,
      ignoreQuery,
    ],
    () =>
      getData(
        collection,
        field,
        data,
        order,
        orderField,
        queryVars,
        ignoreQuery
      ),
    {
      enabled: true,
    }
  );
}
