import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import BackDrop from "../Shared/BackDrop/BackDrop";
import { isEmpty } from "lodash";
import { useRef, useState } from "react";
import { useGlobal } from "../../context/GlobalContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { Plus } from "tabler-icons-react";
import { Delete } from "@material-ui/icons";
import dataService from "../../services/dataService";
import useBetterData from "../../hooks/useBetterData";

//Form Def
const schema = yup.object().shape({
  version: yup.string().required("El campo es requerido"),
  changes: yup.array().of(yup.string()).notRequired(),
  fixes: yup.array().of(yup.string()).notRequired(),
});

const defaultValues = {
  version: "",
  changes: [],
  fixes: [],
};

const List = (props) => {
  //Props
  const { user } = props;

  //Hooks
  const userRole = user.role;
  const {
    data,
    isLoading: dataIsLoading,
    error,
    refetch,
  } = useBetterData({
    collection: "changelog",
    orderOptions: { orderField: "version" },
  });
  const { setLoading, isLoading, showSnackBar } = useGlobal();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [changesList, setChangesList] = useState([]);
  const [fixesList, setFixesList] = useState([]);
  const changeInput = useRef(null);
  const fixInput = useRef(null);

  // Form Methods

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { handleSubmit, control, errors } = methods;

  const renderLogs = (changelog) => {
    const fixes = changelog.fixes || [];
    const changes = changelog.changes || [];
    return (
      <Card className="p-4">
        <CardHeader
          title={
            <div className="flex flex-1 flex-row gap-2">
              <div className="font-bold">Versión:</div>
              <div>{changelog.version}</div>
              {userRole === "admin" && (
                <div className="flex flex-1 flex-row gap-2 justify-end">
                  <Button
                    onClick={() => {
                      dataService
                        .deleteDocument(changelog, "changelog")
                        .then(() => {
                          showSnackBar(
                            "Registro eliminado exitosamente",
                            "success"
                          );
                          refetch();
                        })
                        .catch((error) => {
                          showSnackBar(
                            "Error al eliminar el registro",
                            "error"
                          );
                          console.error("Error al eliminar el registro", error);
                        });
                    }}
                    color="secondary"
                    variant="contained"
                  >
                    Eliminar
                  </Button>
                </div>
              )}
            </div>
          }
        />
        <CardContent>
          <div className="flex flex-col gap-2">
            {!isEmpty(changes) && (
              <>
                <div className="font-bold">Cambios</div>
                {changes?.map((change, index) => (
                  <div key={index}>{change}</div>
                ))}
              </>
            )}
            {!isEmpty(fixes) && (
              <>
                <div className="font-bold">Ajustes</div>
                {fixes?.map((fix, index) => (
                  <div key={index}>{fix}</div>
                ))}
              </>
            )}
          </div>
        </CardContent>
      </Card>
    );
  };

  // Handlers

  const checkIfVersionExists = (version) => {
    return data.some((item) => item.version === version);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const { version } = data;
    if (checkIfVersionExists(version)) {
      showSnackBar("La versión ya existe", "error");
      setLoading(false);
      return;
    }
    const payload = {
      version: version,
      changes: changesList,
      fixes: fixesList,
      creationDate: new Date(),
    };
    dataService
      .createDocument(payload, "changelog")
      .then((response) => {
        if (response) {
          showSnackBar("Registro creado exitosamente", "success");
          setOpenAddDialog(false);
          setChangesList([]);
          setFixesList([]);
          refetch();
          setLoading(false);
        } else {
          showSnackBar("Error al crear el registro", "error");
          setLoading(false);
        }
      })
      .catch((error) => {
        showSnackBar("Error al crear el registro", "error");
        setLoading(false);
        console.error("Error al crear el registro", error);
      });
  };

  const handleAddChange = () => {
    if (changeInput && !isEmpty(changeInput.current.value)) {
      setChangesList([...changesList, changeInput.current.value]);
      changeInput.current.value = "";
    }
  };

  const handleAddFix = () => {
    if (fixInput && !isEmpty(fixInput.current.value)) {
      setFixesList([...fixesList, fixInput.current.value]);
      fixInput.current.value = "";
    }
  };

  const orderDataByVersion = (data) => {
    return (
      data?.sort((a, b) => {
        const [majorA, minorA, patchA] = a?.version.split(".").map(Number);
        const [majorB, minorB, patchB] = b?.version.split(".").map(Number);

        // Compara las versiones de mayor a menor
        if (majorA !== majorB) {
          return majorB - majorA;
        } else if (minorA !== minorB) {
          return minorB - minorA;
        } else {
          return patchB - patchA;
        }
      }) || []
    );
  };

  return (
    <div className="w-full flex flex-1 flex-col gap-2">
      {userRole === "admin" && (
        <div className="flex justify-end">
          <Button
            className="btn btn-primary"
            color="primary"
            variant="contained"
            onClick={() => setOpenAddDialog(true)}
          >
            Crear
          </Button>
        </div>
      )}
      {error && <div>Error: {error.message}</div>}
      {isLoading && dataIsLoading ? (
        <BackDrop show={true} />
      ) : (
        <div className="flex flex-1 flex-col gap-2">
          {orderDataByVersion(data)?.map((changelog, index) => (
            <div key={index}>{renderLogs(changelog)}</div>
          ))}
        </div>
      )}
      {openAddDialog && (
        <Dialog
          open={openAddDialog}
          onClose={(_event, reason) => {
            if (reason !== "backdropClick") {
              setOpenAddDialog(false);
            }
          }}
          disableEscapeKeyDown={true}
          maxWidth="md"
          fullWidth={true}
        >
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <DialogTitle
              id="documents-dialog-title"
              disableTypography={true}
              className="bg-gray-300 shadow-md"
            >
              <div className="flex flex-1 gap-2 justify-between w-full items-center">
                <span className="text-xl font-bold">
                  {"Crear registro de cambios"}
                </span>
                <div className="flex flex-1 flex-row justify-end gap-2">
                  <Button
                    onClick={() => setOpenAddDialog(false)}
                    color="default"
                    className="justify-self-end"
                    variant="contained"
                  >
                    Cerrar
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="justify-self-end"
                    variant="contained"
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <Box p={3} className={"gridDialog"}>
                <Controller
                  name="version"
                  control={control}
                  defaultValue={defaultValues.version}
                  label="Version"
                  variant="outlined"
                  width={1}
                  as={TextField}
                  error={errors.version ? true : false}
                  helperText={errors.version?.message}
                  className={"titleField col-span-2"}
                  margin="normal"
                />
                <div className="flex flex-1 flex-row gap-2 w-full col-span-2">
                  <div className="flex flex-1 flex-row gap-1">
                    <TextField
                      name="changesField"
                      label="Cambio"
                      variant="outlined"
                      fullWidth
                      inputRef={changeInput}
                    />
                    <Tooltip title="Agregar cambio" placement="top">
                      <IconButton onClick={handleAddChange}>
                        <Plus size={24} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="flex flex-1 flex-row gap-1">
                    <TextField
                      name="fixField"
                      label="Ajuste"
                      variant="outlined"
                      fullWidth
                      inputRef={fixInput}
                    />
                    <Tooltip title="Agregar ajuste" placement="top">
                      <IconButton onClick={handleAddFix}>
                        <Plus size={24} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex flex-1 flex-row gap-2 w-full col-span-2">
                  <div className="flex flex-1 flex-col gap-2">
                    <div className="font-bold">Cambios</div>
                    {changesList.map((change, index) => (
                      <div key={index}>
                        {change}
                        <IconButton
                          onClick={() => {
                            setChangesList(
                              changesList.filter((item, i) => i !== index)
                            );
                          }}
                        >
                          <Delete size={20} />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-1 flex-col gap-2">
                    <div className="font-bold">Ajustes</div>
                    {fixesList.map((fix, index) => (
                      <div key={index}>
                        {fix}
                        <IconButton
                          onClick={() => {
                            setFixesList(
                              fixesList.filter((item, i) => i !== index)
                            );
                          }}
                        >
                          <Delete size={20} />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </div>
              </Box>
            </DialogContent>
          </form>
        </Dialog>
      )}
    </div>
  );
};

export default List;
