import { parse, isValid, parseISO } from "date-fns";
import {
  isDate,
  isEmpty,
  isNull,
  isObject,
  isString,
  isUndefined,
} from "lodash";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (field, debug) => {
  if (isUndefined(field) || isEmpty(field) || isNull(field)) {
    if (isDate(field)) {
      return new Date(field);
    } else {
      return undefined;
    }
  } else if (isString(field)) {
    let date = parse(field, "dd/MM/yyyy", new Date());
    if (!isValid(date)) {
      date = parse(field, "MM/dd/yyyy", new Date());
      if (!isValid(date)) {
        date = parse(field, "yyyy-MM-dd", new Date());
        if (!isValid(date)) {
          date = parseISO(field);
          if (!isValid(date)) {
            date = new Date();
          }
        }
      }
    }
    return new Date(date);
  } else {
    if (isObject(field) && !isUndefined(field.seconds)) {
      return new Date(field.seconds * 1000);
    } else if (isDate(field)) {
      return new Date(field);
    } else {
      return undefined;
    }
  }
};
