import * as yup from "yup";

const schema = yup.object().shape({
  photo: yup.string(),
  fillingDate: yup.date().required("Fecha de Diligenciamiento requerida"),
  programName: yup.string().required("Nombre de programa requerido"),
  pspStartDate: yup.date().required("Fecha de Ingreso al PSP requerida"),
  id: yup.string().required("ID requerido"),
  status: yup.string().required("Estatuto requerido"),
  email: yup
    .string()
    .email("Correo electrónico invalido")
    .required("Correo electrónico requerido"),
  name: yup.string().required("Nombre(s) requerido(s)"),
  age: yup.string().required("Edad requerida"),
  ageTime: yup.string().required("Tiempo de edad requerido"),
  genre: yup.string().required("Genero requerido"),
  documentType: yup.string().required("Tipo de documento requerido"),
  identification: yup.string().required("Numero de identificación requerido"),
  bornDate: yup
    .date("Formato de fecha incorrecto")
    .required("Fecha de Nacimiento requerido")
    .nullable()
    .default(undefined),
  bornDepartment: yup.string().required("Departamento de nacimiento requerido"),
  bornMunicipio: yup.string().required("Municipio de nacimiento requerido"),
  bornCity: yup.string().required("Ciudad de nacimiento requerida"),
  maritalStatus: yup.string().required("Estado Civil requerido"),
  zone: yup.string().required("Zona requerida"),
  city: yup.string().required("Ciudad requerida"),
  department: yup.string().required("Departamento requerido"),
  municipio: yup.string().required("Municipio requerido"),
  address: yup.string().required("Dirección requerida"),
  neighborhood: yup.string().required("Barrio requerido"),
  locality: yup.string().required("Requerido"),
  strate: yup.string().required("Estrato requerido"),
  phone: yup
    .string()
    .required("Teléfono requerido")
    .max(40, "Numero de teléfono invalido"),
  academic: yup.string().required("Vinculación académica requerida"),
  academicLevel: yup.string().required("Nivel académico requerido"),
  academicInstitutionLevel: yup.string(),
  occupation: yup.string().required("Ocupación requerida"),
  observationsInfo: yup.string().max(400, "Máximo 400 caracteres"),
  observationsInitial: yup.string().max(400, "Máximo 400 caracteres"),
  needCarer: yup.boolean(),
  diagnosis: yup.string().required("Diagnostico requerido"),
  dxDate: yup
    .date("Formato de fecha incorrecto")
    .required("Fecha de Diagnostico requerida")
    .nullable()
    .default(undefined),
  dxsupport: yup.string().required("Cuenta con soporte DX requerido"),
  eps: yup.string().required("EPS requerida"),
  regimen: yup.string().required("Regimen requerida"),
  doctor: yup.string().required("Medico Tratante requerido"),
  speciality: yup.string().required("Especialidad requerida"),
  getTreatment: yup.string().required("Recibe Tratamiento requerido"),
  notGetTreatment: yup.string(),
  treatmentStart: yup
    .date("Formato de fecha incorrecto")
    .nullable()
    .default(undefined),
  drugName: yup.string().required("Nombre de Medicamento requerido"),
  drugCode: yup.string(),
  size: yup.string().required("Talla requerida"),
  sizeInfo: yup.string().required("Unidad de medida requerida"),
  weight: yup.string().required("Peso requerido"),
  weightInfo: yup.string().required("Unidad de medida requerida"),
  discapacidad: yup.string().required("Campo requerido"),
  centroAplicacion: yup.string().required("Centro de Aplicación requerido"),
  discapacidadCert: yup.string(),
  discapacidadType: yup.string(),
  needHelp: yup.string().required("Campo requerido"),
  techHelp: yup.string(),
  carerName: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Nombres y Apellidos requeridos"),
    otherwise: yup.string(),
  }),
  documentTypeCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Tipo de Documento"),
    otherwise: yup.string(),
  }),
  identificationCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Identificación requerida"),
    otherwise: yup.string(),
  }),
  parentalCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Parentesco requerido"),
    otherwise: yup.string(),
  }),
  ageCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Edad requerida"),
    otherwise: yup.string(),
  }),
  epsCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("EPS requerida"),
    otherwise: yup.string(),
  }),
  ageTimeCarer: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Tiempo de edad requerido"),
    otherwise: yup.string(),
  }),
  regimenCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Regimen requerido"),
    otherwise: yup.string(),
  }),
  correoCare: yup.string().when("needCarer", {
    is: true,
    then: yup
      .string()
      .email("Correo electrónico no valido")
      .required("Correo electrónico requerido"),
    otherwise: yup.string(),
  }),
  educativeCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Nivel Educativo requerido"),
    otherwise: yup.string(),
  }),
  occupationCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Ocupación requerida"),
    otherwise: yup.string(),
  }),
  phoneCare: yup.string().when("needCarer", {
    is: true,
    then: yup.string().required("Teléfono requerido"),
    otherwise: yup.string(),
  }),
  familyList: yup.string().nullable(),
  familyType: yup.string().required("Tipo de familia requerido"),
  personsNumber: yup.string().required("No de personas con que vive requerido"),
  parteRegistro: yup.boolean(),
  yearRegisterVictim: yup.string(),
  familiaMismaPatologia: yup.boolean(),
  whoFamily: yup.string(),
  familiaOtrasPatologias: yup.boolean(),
  whatPatology: yup.string(),
  whoPatology: yup.string(),
  recibeTratamiento: yup.boolean(),
  nameTreatment: yup.string(),
  treatmentObservations: yup.string(),
  vivienda: yup.string().required("Tipo de Vivienda requerida"),
  viviendaOtro: yup.string(),
  nombreVereda: yup.string(),
  observacionVivienda: yup.string(),
  tenenciaVivienda: yup.string().required("Tenencia de la vivienda requerida"),
  tenenciaViviendaCual: yup.string(),
  movementForm: yup.string(),
  observationsConditions: yup.string(),
  observationsFinal: yup.string(),
  signature: yup.string(),

  // New Fields
  // PatientInfo
  academicInstitution: yup.string(),
  academicType: yup.string(),
  academicHasHelp: yup.string(),
  academicIsSpecial: yup.string(),
  labour: yup.string(),
  labourType: yup.string(),
  labourEconomicContributor: yup.string(),
  labourIncome: yup.string(),
  patientAssociation: yup.string(),

  // Initial Status
  dxVariant: yup.string(),
  dxIsOrphan: yup.string(),
  dxNotSupport: yup.string(),
  dxMedicalCenter: yup.string(),
  dxApplicationCity: yup.string(),
  dxMedicalType: yup.string(),
  dxMedicalTypeOther: yup.string(),
  dxDosis: yup.string(),
  dxMedicalFrequency: yup.string(),
  dxAdministration: yup.string(),
  dxAdministrationOther: yup.string(),
  dxOrganTransplant: yup.string(),
  dxOrganTransplantWhich: yup.string(),
  discapacidadRegistered: yup.string(),

  // Vaccines need to be added dynamically

  // Carer Info
  carerIsViable: yup.string(),
  carerAbility: yup.string(),
  carerObservations: yup.string(),

  // Observations
  observationsViolence: yup.string(),
  observationsPsychosocial: yup.string(),
  signatureMaker: yup.string(),
  observationsHelps: yup.string(),

  hasAttachments: yup.boolean(),
  hasAttachmentsWhich: yup.string(),
});

export default schema;
