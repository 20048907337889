import { firestore, functions } from "../firebase";

const dataService = {};

dataService.callFunction = (func, data) => {
  return new Promise((resolve, reject) => {
    let sendMail = functions.httpsCallable(func);
    sendMail(data)
      .then((result) => {
        resolve(result.data.message);
      })
      .catch((err) => {
        console.error("sendMail error:", err);
        reject(err);
      });
  });
};

dataService.getDocumentsPromise = (collection) => {
  return firestore.collection(collection).get();
};

dataService.getDocumentsActivePromise = (collection) => {
  return firestore.collection(collection).where("isActive", "==", true).get();
};

dataService.getDocument = (collection, id) => {
  return firestore.collection(collection).doc(id).get();
};

dataService.getDocumentsByIdPromise = (collection, field, id) => {
  return firestore.collection(collection).where(field, "==", id).get();
};

dataService.getDocumentsByDoubleWherePromise = (
  collection,
  field,
  id,
  field2,
  id2
) => {
  return firestore
    .collection(collection)
    .where(field, "==", id)
    .where(field2, "==", id2)
    .get();
};

dataService.getDocuments = (observer, collection) => {
  return firestore
    .collection(collection)
    .onSnapshot({ includeMetadataChanges: true }, observer);
};

dataService.getDocumentsUser = (observer, collection, user) => {
  if (user.role === "admin" || user.role === "coordinadoradezona") {
    return firestore
      .collection(collection)
      .onSnapshot({ includeMetadataChanges: true }, observer);
  } else {
    return firestore
      .collection(collection)
      .where("createdBy", "==", user.uid)
      .onSnapshot({ includeMetadataChanges: true }, observer);
  }
};

dataService.getDocumentsByPermission = (
  observer,
  collection,
  permission,
  user
) => {
  if (permission === true) {
    return firestore
      .collection(collection)
      .onSnapshot({ includeMetadataChanges: true }, observer);
  } else {
    return firestore
      .collection(collection)
      .where("createdBy", "==", user.uid)
      .onSnapshot({ includeMetadataChanges: true }, observer);
  }
};

dataService.getDocumentsByFieldId = (observer, collection, field, id) => {
  return firestore
    .collection(collection)
    .where(field, "==", id)
    .onSnapshot({ includeMetadataChanges: true }, observer);
};

dataService.getDocumentsByDoubleWhere = (
  observer,
  collection,
  field,
  id,
  field2,
  id2
) => {
  return firestore
    .collection(collection)
    .where(field, "==", id)
    .where(field2, "==", id2)
    .onSnapshot({ includeMetadataChanges: true }, observer);
};

dataService.getActiveDocuments = (observer, collection) => {
  return firestore
    .collection(collection)
    .where("isActive", "==", true)
    .onSnapshot({ includeMetadataChanges: true }, observer);
};

dataService.createDocument = (data, collection) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection(collection)
      .add({ ...data })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

dataService.updateDocument = (data, collection) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection(collection)
      .doc(data.id)
      .set({ ...data }, { merge: true })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

dataService.deleteDocument = (data, collection) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection(collection)
      .doc(data.id)
      .delete()
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default dataService;
