import React from "react";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";

const HouseInfo = (props) => {
  //Hooks and Vars
  const { control, errors, register, patientInfo, handleFormValues } = props;

  //Handlers

  const handleChange = (event) => {
    handleFormValues(event.target.name, event.target.checked);
  };

  //Effects

  return (
    <Box
      p={3}
      className="grid"
      style={{
        gap: "1rem",
      }}
    >
      <FormControl variant="outlined" error={errors?.vivienda ? true : false}>
        <InputLabel id="vivienda-label">Tipo de Vivienda</InputLabel>
        <Controller
          control={control}
          name="vivienda"
          variant="outlined"
          defaultValue={patientInfo.vivienda}
          as={({ onChange, value }) => {
            return (
              <Select
                label="Tipo de Vivienda"
                labelId="vivienda-label"
                value={value}
                onChange={(e) => {
                  handleFormValues("vivienda", e.target.value);
                }}
                error={errors?.vivienda ? true : false}
              >
                <MenuItem value="casa">CASA</MenuItem>
                <MenuItem value="apartamento">APARTAMENTO</MenuItem>
                <MenuItem value="habitacion">HABITACIÓN</MenuItem>
                <MenuItem value="inquilinato">INQUILINATO</MenuItem>
                <MenuItem value="finca">FINCA</MenuItem>
                <MenuItem value="otro">OTRO</MenuItem>
              </Select>
            );
          }}
          onChange={(val) => {
            return val;
          }}
        />
        <FormHelperText error={errors?.vivienda}>
          {errors?.vivienda?.message}
        </FormHelperText>
      </FormControl>
      {patientInfo.vivienda === "otro" && (
        <Controller
          required={patientInfo.vivienda === "otro"}
          name="viviendaOtro"
          control={control}
          defaultValue={patientInfo.viviendaOtro}
          label="¿Cual?"
          variant="outlined"
          width={1}
          as={TextField}
        />
      )}
      {patientInfo.vivienda === "finca" && (
        <Controller
          name="nombreVereda"
          control={control}
          defaultValue={patientInfo.nombreVereda}
          label="Nombre de Vereda"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.nombreVereda ? true : false}
          helperText={errors?.nombreVereda?.message}
        />
      )}

      <FormControl
        variant="outlined"
        error={errors?.tenenciaVivienda ? true : false}
      >
        <InputLabel id="tenenciaVivienda-label">
          Tenencia de la vivienda
        </InputLabel>
        <Controller
          required
          control={control}
          name="tenenciaVivienda"
          variant="outlined"
          defaultValue={patientInfo.tenenciaVivienda}
          error={errors?.tenenciaVivienda ? true : false}
          as={({ onChange, value }) => {
            return (
              <Select
                label="Tenencia de la vivienda"
                labelId="tenenciaVivienda-label"
                value={value}
                onChange={(e) => {
                  handleFormValues("tenenciaVivienda", e.target.value);
                }}
                error={errors?.tenenciaVivienda ? true : false}
              >
                <MenuItem value="propia">PROPIA</MenuItem>
                <MenuItem value="arriendo">ARRIENDO</MenuItem>
                <MenuItem value="familiar">FAMILIAR</MenuItem>
                <MenuItem value="otro">OTRO</MenuItem>
              </Select>
            );
          }}
          onChange={(val) => {
            return val;
          }}
        />
        <FormHelperText error={errors?.tenenciaVivienda}>
          {errors?.tenenciaVivienda?.message}
        </FormHelperText>
      </FormControl>

      {patientInfo.tenenciaVivienda === "otro" && (
        <Controller
          required={patientInfo.tenenciaVivienda === "otro"}
          name="tenenciaViviendaCual"
          control={control}
          defaultValue={patientInfo.tenenciaViviendaCual}
          label="¿Cual?"
          variant="outlined"
          width={1}
          as={TextField}
        />
      )}

      <FormControl variant="outlined">
        <FormLabel component="legend">Acceso a servicios básicos</FormLabel>
        <FormGroup>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.agua}
                  name="agua"
                  onChange={handleChange}
                />
              }
              label="Agua"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.luz}
                  name="luz"
                  onChange={handleChange}
                />
              }
              label="Luz"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.telefono}
                  name="telefono"
                  onChange={handleChange}
                />
              }
              label="Teléfono"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.alcantarillado}
                  name="alcantarillado"
                  onChange={handleChange}
                />
              }
              label="Alcantarillado"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.gas}
                  name="gas"
                  onChange={handleChange}
                />
              }
              label="Gas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.internet}
                  name="internet"
                  onChange={handleChange}
                />
              }
              label="Internet"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.basuras}
                  name="basuras"
                  onChange={handleChange}
                />
              }
              label="Recolección de Basuras"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.educativas}
                  name="educativas"
                  onChange={handleChange}
                />
              }
              label="Sedes educativas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.bibliotecas}
                  name="bibliotecas"
                  onChange={handleChange}
                />
              }
              label="Bibliotecas Públicas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.transporte}
                  name="transporte"
                  onChange={handleChange}
                />
              }
              label="Transporte Público"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.salud}
                  name="salud"
                  onChange={handleChange}
                />
              }
              label="Centros de Salud"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={patientInfo.parques}
                  name="parques"
                  onChange={handleChange}
                />
              }
              label="Parques"
            />
          </div>
        </FormGroup>
        <FormHelperText>{errors?.role?.message}</FormHelperText>
      </FormControl>

      <TextField
        name="observacionVivienda"
        label="Observaciones"
        variant="outlined"
        multiline={true}
        minRows={3}
        style={{ width: "100%" }}
        defaultValue={patientInfo.observacionVivienda}
        onChange={(e) => {
          handleFormValues("observacionVivienda", e.target.value);
        }}
        helperText={`${
          patientInfo.observacionVivienda
            ? patientInfo.observacionVivienda.length
            : 0
        }/400`}
        inputProps={{
          style: {
            maxLength: 400,
            textTransform: "uppercase",
          },
        }}
        inputRef={register({ required: false })}
      />
    </Box>
  );
};

HouseInfo.propTypes = {
  changeTab: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default HouseInfo;
