import BackDrop from "../components/Shared/BackDrop/BackDrop";
import React, { useState, useCallback, useContext } from "react";
import { useSnackbar } from "notistack";
import appearance from "../services/appearance";
import { isMobile } from "react-device-detect";
import useBreakpoint from "use-breakpoint";
import { analytics } from "../firebase";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

export const GlobalContext = React.createContext({
  theme: appearance.defaultTheme,
  routeTitle: "",
  setLoading: () => {},
  isLoading: false,
  language: "es",
  setLanguage: () => {},
  showSnackBar: (message, severity, ...props) => {},
  setCurrentTheme: (theme) => {},
  handleRouteTitle: (title) => {},
  isMobile: false,
  breakpoint: "desktop",
  maxWidth: 1280,
  minWidth: 0,
  setAnalytics: (key) => {},
  version: "1.0.0",
});

export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(
    BREAKPOINTS,
    "desktop"
  );
  const { enqueueSnackbar } = useSnackbar();
  const [showBack, setShowBack] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("userLanguage") || "es"
  );
  const [theme, setTheme] = useState(appearance.defaultTheme);
  const [routeTitle, setRouteTitle] = useState([]);

  const setCurrentTheme = useCallback((theme) => {
    setTheme(appearance.createTheme(theme));
  }, []);

  const setLoading = useCallback((status) => {
    setShowBack(status);
  }, []);

  const setUserLanguage = useCallback((userLang) => {
    setShowBack(true);
    setLanguage(userLang);
    localStorage.setItem("userLanguage", userLang);
    window.location.reload();
  }, []);

  const showSnackBar = useCallback(
    (message, ...props) => {
      if (typeof props[0] === "string") {
        return enqueueSnackbar(message, {
          variant: props[0],
        });
      } else {
        return enqueueSnackbar(message, {
          ...props[0],
        });
      }
    },
    [enqueueSnackbar]
  );

  const handleRouteTitle = useCallback((title) => {
    let titleData = [
      {
        title: "",
        id: "",
      },
    ];
    if (typeof title === "string") {
      titleData[0].title = title;
      titleData[0].id = title;
    } else {
      titleData = title;
    }
    setRouteTitle(titleData);
  }, []);

  const setAnalytics = useCallback((key) => {
    analytics.logEvent(key);
  }, []);

  const version = process.env.REACT_APP_VERSION;

  const value = {
    theme,
    routeTitle,
    setLoading,
    isLoading: showBack,
    language,
    setLanguage: setUserLanguage,
    showSnackBar,
    setCurrentTheme,
    handleRouteTitle,
    isMobile,
    breakpoint,
    maxWidth,
    minWidth,
    setAnalytics,
    version,
  };
  return (
    <GlobalContext.Provider value={value}>
      {children}
      <BackDrop show={showBack} />
    </GlobalContext.Provider>
  );
};
