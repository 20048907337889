import { createFilterOptions } from "@material-ui/lab";
import { isUndefined } from "lodash";

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);
  if (params.inputValue !== "") {
    filtered.push({
      inputValue: params.inputValue,
      name: `AGREGAR "${params.inputValue.toUpperCase()}"`,
    });
  }
  return filtered;
};

const optionsLabel = (option, field) => {
  if (typeof option === "string") {
    return option;
  }
  if (option.inputValue) {
    return option.inputValue;
  }
  return typeof field !== "undefined" ? option[field] : option.name;
};

const handleLoadList = (list, filter, filterTwo,field = "name", defaultValue="Cargando...") => {
  try {
    let resultList = !list
      ? [{ [field]: defaultValue, id: 0 }]
      : list.filter(x => !isUndefined(x[field])).sort((a, b) => a[field].localeCompare(b[field]));
    if (typeof filter !== "undefined") {
      resultList = resultList.filter((x) => x[filter.field] === filter[field]);
    }
    if (typeof filterTwo !== "undefined") {
      resultList = resultList.filter(
        (x) => x[filterTwo.field] === filterTwo[field]
      );
    }
    return resultList;
  } catch (error) {
    console.error(error);
    return [{ [field]: defaultValue, id: 0 }];
  }
};

export { filter, filterOptions, optionsLabel, handleLoadList };
