import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const ChangelogDialog = ({ version, changes, fixes, ...props }) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <DialogTitle>
        <Typography variant="h6">✨ VERSION {version} ✨</Typography>
        <Tooltip title="Close">
          <IconButton className={classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent className="mb-5 flex flex-col gap-2">
        {!isEmpty(changes) && (
          <div>
            <Typography variant="h6">Nuevo: </Typography>
            <ul className="list-disc gap-1">
              {changes.map((change, index) => (
                <li className="m-2" key={index}>{change}</li>
              ))}
            </ul>
          </div>
        )}
        {!isEmpty(fixes) && (
          <div>
            <Typography variant="h6">Ajustes: </Typography>
            <ul className="list-disc">
              {fixes.map((fix, index) => (
                <li className="m-2" key={index}>{fix}</li>
              ))}
            </ul>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChangelogDialog;
