import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import {
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Select,
  MenuItem,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import superjson from "superjson";

import { columnsFamily, optionsFamily } from "./elements/FamilyTable";
import styles from "../Details.module.scss";
import { isString, isUndefined } from "lodash";
const FamilyInfo = (props) => {
  //Hooks and Vars
  const { control, errors, register, patientInfo, handleFormValues } = props;
  let famList = "";
  if (typeof patientInfo.familyList === "string") {
    if (patientInfo.familyList.indexOf("json") > -1) {
      famList = patientInfo.familyList;
    } else {
      let familyList = JSON.parse(patientInfo.familyList);
      while (isString(familyList)) {
        familyList = JSON.parse(familyList);
      }
      famList = superjson.stringify(familyList);
    }
  } else {
    famList = patientInfo.familyList;
  }
  const [isNew, setIsNew] = useState(false);

  const [familyList, setFamilyList] = useState(famList);
  const [addFamily, setAddFamily] = useState(false);
  const [selectedFamily, setSelectedFamily] = useState({});

  //Handlers
  const toggleSetAddFamily = () => {
    setIsNew(true);
    setAddFamily(!addFamily);
    setSelectedFamily({});
  };

  const toggleSetEditFamily = (item) => {
    setIsNew(false);
    setAddFamily(true);
    setSelectedFamily(item);
  };

  const handleFamilyFieldChange = (field, value) => {
    setSelectedFamily((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  //Methods
  const addNewFamilyMember = (data) => {
    data.id = new Date().getTime().toString();
    data.actions = ["delete", "edit"];
    if (typeof data.familyEconomy === "undefined") {
      data.familyEconomy = false;
    }
    let familyListData = superjson.parse(patientInfo.familyList);
    if (isUndefined(familyListData)) familyListData = [];
    const familyNew = superjson.stringify([...familyListData, data]);
    handleFormValues("familyList", familyNew);
    setFamilyList(familyNew);
  };

  const updateFamilyMember = (data) => {
    data.actions = ["delete", "edit"];
    let newList;
    if (isString(familyList)) {
      let fData = superjson.parse(familyList);
      if (isUndefined(fData)) fData = [];
      newList = fData.filter((item) => item.id !== data.id);
    } else {
      newList = familyList.filter((item) => item.id !== data.id);
    }
    newList.push(data);
    const familyNew = superjson.stringify(newList);
    setFamilyList(familyNew);
    handleFormValues("familyList", familyNew);
  };

  const removeFamilyMember = (familyItem) => {
    let newList;
    if (isString(familyList)) {
      let fData = superjson.parse(familyList);
      if (isUndefined(fData)) fData = [];
      newList = fData.filter((item) => item.id !== familyItem.id);
    } else {
      newList = familyList.filter((item) => item.id !== familyItem.id);
    }
    const familyNew = superjson.stringify(newList);
    setFamilyList(familyNew);
    handleFormValues("familyList", familyNew);
  };

  const addFamilyMember = () => {
    if (isNew === true) {
      addNewFamilyMember(selectedFamily);
    } else {
      updateFamilyMember(selectedFamily);
    }
    setAddFamily(false);
    setIsNew(false);
  };

  //Settings

  return (
    <Box p={3}>
      <MUIDataTable
        data={superjson.parse(familyList)}
        columns={columnsFamily(
          superjson.parse(familyList),
          removeFamilyMember,
          toggleSetEditFamily
        )}
        options={optionsFamily(toggleSetAddFamily)}
      />
      <div className={styles.gridContainerBase} style={{ marginTop: "20px" }}>
        <FormControl
          variant="outlined"
          error={errors?.familyType ? true : false}
        >
          <InputLabel id="familyType-label">Tipo de Familia</InputLabel>
          <Controller
            required
            control={control}
            name="familyType"
            variant="outlined"
            defaultValue={patientInfo.familyType}
            error={errors?.familyType ? true : false}
            as={({ _onChange, value }) => {
              return (
                <Select
                  labelId="familyType-label"
                  value={value}
                  label="Tipo de Familia"
                  onChange={(e) => {
                    handleFormValues("familyType", e.target.value);
                  }}
                >
                  <MenuItem value="UNIPERSONAL"> UNIPERSONAL</MenuItem>
                  <MenuItem value="Monoparental">MONOPARENTAL</MenuItem>
                  <MenuItem value="Nuclear">NUCLEAR</MenuItem>
                  <MenuItem value="Extensa">EXTENSA</MenuItem>
                  <MenuItem value="Adoptiva">ADOPTIVA</MenuItem>
                  <MenuItem value="SinHijos">SIN HIJOS</MenuItem>
                  <MenuItem value="Padres Separados">PADRES SEPARADOS</MenuItem>
                  <MenuItem value="Compuesta">COMPUESTA</MenuItem>
                  <MenuItem value="Homoparental">HOMOPARENTAL</MenuItem>
                </Select>
              );
            }}
            onChange={(val) => {
              return val;
            }}
          />
          <FormHelperText style={{ color: "#f44336" }}>
            {errors?.familyType?.message}
          </FormHelperText>
        </FormControl>
        <TextField
          required
          name="personsNumber"
          control={control}
          type="number"
          defaultValue={patientInfo.personsNumber}
          label="No de personas con que vive"
          variant="outlined"
          width={1}
          as={TextField}
          error={errors?.personsNumber ? true : false}
          helperText={errors?.personsNumber?.message}
          onChange={(e) => handleFormValues("personsNumber", e.target.value)}
          inputRef={register}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={patientInfo.parteRegistro}
              name="parteRegistro"
              color="primary"
              onChange={(e) => {
                handleFormValues("parteRegistro", e.target.checked);
              }}
            />
          }
          label="¿Hacen parte del registro único de victimas por desplazamiento?"
        />
        {patientInfo.parteRegistro && patientInfo.parteRegistro === true && (
          <TextField
            required={patientInfo.parteRegistro}
            name="yearRegisterVictim"
            control={control}
            defaultValue={patientInfo.yearRegisterVictim}
            label="Año de Inscripción"
            variant="outlined"
            width={1}
            onChange={(e) =>
              handleFormValues("yearRegisterVictim", e.target.value)
            }
            inputRef={register}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={patientInfo.familiaMismaPatologia}
              name="familiaMismaPatologia"
              color="primary"
              onChange={(e) => {
                handleFormValues("familiaMismaPatologia", e.target.checked);
              }}
            />
          }
          label="¿Dentro de la familia existen mas casos de padecer la misma patología?"
        />

        {patientInfo.familiaMismaPatologia === true && (
          <TextField
            required={patientInfo.familiaMismaPatologia}
            name="whoFamily"
            control={control}
            defaultValue={patientInfo.whoFamily}
            label="¿Quien?"
            variant="outlined"
            width={1}
            onChange={(e) => handleFormValues("whoFamily", e.target.value)}
            inputRef={register}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={patientInfo.familiaOtrasPatologias}
              name="familiaOtrasPatologias"
              color="primary"
              onChange={(e) => {
                handleFormValues("familiaOtrasPatologias", e.target.checked);
              }}
            />
          }
          label="¿Dentro de la familia existen casos de padecer una patología huérfana o rara?"
        />

        {patientInfo.familiaOtrasPatologias === true && (
          <>
            <TextField
              required={patientInfo.familiaOtrasPatologias}
              name="whatPatology"
              control={control}
              defaultValue={patientInfo.whatPatology}
              label="¿Cual patología?"
              variant="outlined"
              width={1}
              onChange={(e) => handleFormValues("whatPatology", e.target.value)}
              inputRef={register}
            />
            <TextField
              required={patientInfo.familiaOtrasPatologias}
              name="whoPatology"
              control={control}
              defaultValue={patientInfo.whoPatology}
              label="¿Quien?"
              variant="outlined"
              width={1}
              onChange={(e) => handleFormValues("whoPatology", e.target.value)}
              inputRef={register}
            />
          </>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={patientInfo.recibeTratamiento}
              name="recibeTratamiento"
              color="primary"
              onChange={(e) => {
                handleFormValues("recibeTratamiento", e.target.checked);
              }}
            />
          }
          label="¿Recibe tratamiento para dicha patología?"
        />

        {patientInfo.recibeTratamiento === true && (
          <TextField
            required={patientInfo.recibeTratamiento}
            name="nameTreatment"
            control={control}
            defaultValue={patientInfo.nameTreatment}
            label="Nombre"
            variant="outlined"
            width={1}
            onChange={(e) => handleFormValues("nameTreatment", e.target.value)}
            inputRef={register}
          />
        )}
      </div>
      <Box
        style={{
          marginTop: "20px",
        }}
      >
        <TextField
          label="Observaciones"
          name="treatmentObservations"
          defaultValue={patientInfo.treatmentObservations}
          variant="outlined"
          style={{ width: "100%" }}
          helperText={`${
            patientInfo.treatmentObservations
              ? patientInfo.treatmentObservations.length
              : 0
          }/100`}
          minRows={2}
          inputProps={{ maxLength: 100 }}
          onChange={(e) =>
            handleFormValues("treatmentObservations", e.target.value)
          }
          inputRef={register}
        />
      </Box>
      <Dialog
        open={addFamily}
        aria-labelledby="responsive-dialog-title"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            return;
          }
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          {isNew === true ? "Agregar Familiar" : "Editar Familiar"}
        </DialogTitle>
        <div name="formFamily" id="formFamily">
          <DialogContent className={styles.gridAddFamily}>
            <TextField
              name="familyName"
              value={selectedFamily.familyName}
              label="Nombre"
              variant="outlined"
              onChange={(e) => {
                handleFamilyFieldChange("familyName", e.target.value);
              }}
            />

            <TextField
              name="familyAge"
              value={selectedFamily.familyAge}
              label="Edad"
              variant="outlined"
              onChange={(e) => {
                handleFamilyFieldChange("familyAge", e.target.value);
              }}
            />

            <TextField
              name="familyDocument"
              value={selectedFamily.familyDocument}
              label="Documento"
              variant="outlined"
              onChange={(e) => {
                handleFamilyFieldChange("familyDocument", e.target.value);
              }}
            />

            <TextField
              name="familyParent"
              value={selectedFamily.familyParent}
              label="Parentesco"
              variant="outlined"
              onChange={(e) => {
                handleFamilyFieldChange("familyParent", e.target.value);
              }}
            />

            <TextField
              name="familyOccupation"
              value={selectedFamily.familyOccupation}
              label="Ocupación"
              variant="outlined"
              onChange={(e) => {
                handleFamilyFieldChange("familyOccupation", e.target.value);
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFamily.familyEconomy}
                  name="familyEconomy"
                  color="primary"
                  onChange={(e) => {
                    handleFamilyFieldChange("familyEconomy", e.target.checked);
                  }}
                />
              }
              label="Aporta económicamente al sustento del hogar"
            />

            <TextField
              name="familyPhone"
              value={selectedFamily.familyPhone}
              label="Teléfono"
              variant="outlined"
              onChange={(e) => {
                handleFamilyFieldChange("familyPhone", e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              color="secondary"
              onClick={() => setAddFamily(false)}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              form="formFamily"
              onClick={addFamilyMember}
            >
              {isNew === true ? "Agregar" : "Actualizar"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
};

FamilyInfo.propTypes = {
  changeTab: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default FamilyInfo;
