import {isEmpty, isUndefined} from "lodash";
import {firestore} from "../firebase";
import {useQuery} from "@tanstack/react-query";

const mapCollection = (result, orderOptions) => {
  const results = result.docs.map((docSnapshot) => {
    let itemEl = docSnapshot.data();
    itemEl.id = docSnapshot.id;
    return itemEl;
  });

  if (!isUndefined(orderOptions)) {
    const field = orderOptions.orderField || "name";
    return results.sort((a, b) => a[field].localeCompare(b[field]));
  }
  return results;
};

const getData = async (collection, queryOptions, orderOptions) => {
  let query = firestore.collection(collection);

  if (!isUndefined(queryOptions) && !isEmpty(queryOptions)) {
    queryOptions.forEach((queryVar) => {
      query = query.where(queryVar.field, queryVar.operator, queryVar.value);
    });
  }

  return query
    .get({
      source: "server",
    })
    .then((result) => {
      return mapCollection(result, orderOptions) || [];
    })
    .catch((error) => {
      console.error(`Error Fetching ${collection} data -`, error);
      return [];
    });
};

export default function useBetterData({
  collection,
  queryOptions,
  orderOptions,
}) {
  return useQuery(
    [collection, collection, queryOptions, orderOptions],
    () => getData(collection, queryOptions, orderOptions),
    {
      enabled: true,
    }
  );
}
