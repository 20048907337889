import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Eraser } from "tabler-icons-react";
import SignatureCanvas from "react-signature-canvas";
import { Controller } from "react-hook-form";

const Observations = (props) => {
  //Hooks and Vars
  const { isNew, patientInfo, handleFormValues, getValues, control, errors } =
    props;
  const [sigPad, setSigPad] = useState({});
  const [sigPadMaker, setSigPadMaker] = useState({});

  //Effects

  useEffect(() => {
    if (
      sigPad &&
      typeof sigPad !== "undefined" &&
      sigPad.fromDataURL !== undefined
    ) {
      if (
        patientInfo.signature !== "" &&
        typeof patientInfo.signature !== "undefined" &&
        isNew === false
      ) {
        sigPad.fromDataURL(patientInfo.signature);
        sigPad.off();
      } else {
        sigPad.on();
      }
    }
  }, [sigPad, patientInfo.signature, isNew]);

  useEffect(() => {
    if (
      sigPadMaker &&
      typeof sigPadMaker !== "undefined" &&
      sigPadMaker.fromDataURL !== undefined
    ) {
      if (
        patientInfo.signatureMaker !== "" &&
        typeof patientInfo.signatureMaker !== "undefined" &&
        isNew === false
      ) {
        sigPadMaker.fromDataURL(patientInfo.signatureMaker);
        sigPadMaker.off();
      } else {
        sigPadMaker.on();
      }
    }
  }, [sigPadMaker, patientInfo.signatureMaker, isNew]);

  return (
    <Box p={3}>
      <div>
        <TextField
          name="observationsViolence"
          label="Identifica (Gestor) algún tipo de violencia/negligencia al paciente"
          variant="outlined"
          width={1}
          style={{ width: "100%" }}
          defaultValue={patientInfo.observationsViolence}
          onChange={(e) => {
            handleFormValues(
              "observationsViolence",
              e.target.value.toUpperCase()
            );
          }}
          helperText={`${
            patientInfo.observationsViolence
              ? patientInfo.observationsViolence.length
              : 0
          }/1000`}
          inputProps={{
            style: { maxLength: 1000, textTransform: "uppercase" },
          }}
          multiline
          minRows={4}
          maxRows={10}
        />
        <TextField
          name="observationsFinal"
          label="Observaciones"
          variant="outlined"
          width={1}
          style={{ width: "100%" }}
          defaultValue={patientInfo.observationsFinal}
          onChange={(e) => {
            handleFormValues("observationsFinal", e.target.value.toUpperCase());
          }}
          helperText={`${
            patientInfo.observationsFinal
              ? patientInfo.observationsFinal.length
              : 0
          }/1000`}
          inputProps={{
            style: { maxLength: 1000, textTransform: "uppercase" },
          }}
          multiline
          minRows={4}
          maxRows={10}
        />
        <TextField
          name="observationsPsychosocial"
          label="Análisis Psicosocial"
          variant="outlined"
          width={1}
          style={{ width: "100%" }}
          defaultValue={patientInfo.observationsPsychosocial}
          onChange={(e) => {
            handleFormValues(
              "observationsPsychosocial",
              e.target.value.toUpperCase()
            );
          }}
          helperText={`${
            patientInfo.observationsPsychosocial
              ? patientInfo.observationsPsychosocial.length
              : 0
          }/1000`}
          inputProps={{
            style: { maxLength: 1000, textTransform: "uppercase" },
          }}
          multiline
          minRows={4}
          maxRows={10}
        />
        <TextField
          name="observationsHelps"
          label="Ayudas aprobadas por el laboratorio"
          variant="outlined"
          width={1}
          style={{ width: "100%" }}
          defaultValue={patientInfo.observationsHelps}
          onChange={(e) => {
            handleFormValues("observationsHelps", e.target.value.toUpperCase());
          }}
          helperText={`${
            patientInfo.observationsHelps
              ? patientInfo.observationsHelps.length
              : 0
          }/1000`}
          inputProps={{
            style: { maxLength: 1000, textTransform: "uppercase" },
          }}
          multiline
          minRows={4}
          maxRows={10}
        />
        <div
          className={
            "grid grid-cols-1 md:grid-cols-2 gap-2 w-full border border-gray-300 p-2"
          }
        >
          <FormControlLabel
            control={
              <Switch
                checked={patientInfo.hasAttachments}
                onChange={(e) => {
                  handleFormValues("hasAttachments", e.target.checked);
                }}
                name={"hasAttachments"}
                color="primary"
              />
            }
            label={"¿Se adjuntan documentos?"}
          />
          <Controller
            name={`hasAttachmentsWhich`}
            control={control}
            defaultValue={patientInfo.hasAttachmentsWhich}
            label="¿Cuáles?"
            variant="outlined"
            width={1}
            as={TextField}
            helperText={errors?.hasAttachmentsWhich?.message}
            error={errors?.hasAttachmentsWhich ? true : false}
            disabled={!getValues("hasAttachments")}
            className="w-full"
          />
        </div>
        <div className="flex w-full gap-10 flex-col md:flex-row">
          <div>
            <Typography>Firma del paciente / acudiente del paciente</Typography>
            {isNew === true && (
              <IconButton
                aria-label="delete"
                onClick={() => {
                  sigPad.clear();
                  sigPad.on();
                }}
              >
                <Eraser />
              </IconButton>
            )}
            <div
              className="w-[355px] h-[355px] sm:w-[455px]"
              style={{ border: "1px solid black" }}
            >
              <SignatureCanvas
                ref={(ref) => {
                  setSigPad(ref);
                }}
                penColor="black"
                canvasProps={{
                  border: "1px solid black",
                  className: "sigCanvas w-[353px] h-[353px] sm:w-[453px]",
                }}
                backgroundColor={"white"}
                onEnd={(e) => {
                  handleFormValues("signature", sigPad.toDataURL());
                }}
              />
            </div>
          </div>
          <div>
            <Typography>Firma de quien realiza formato</Typography>
            {isNew === true && (
              <IconButton
                aria-label="delete"
                onClick={() => {
                  sigPadMaker.clear();
                  sigPadMaker.on();
                }}
              >
                <Eraser />
              </IconButton>
            )}
            <div
              className="w-[355px] h-[355px] sm:w-[455px]"
              style={{ border: "1px solid black" }}
            >
              <SignatureCanvas
                ref={(ref) => {
                  setSigPadMaker(ref);
                }}
                penColor="black"
                canvasProps={{
                  border: "1px solid black",
                  className: "sigCanvas w-[353px] h-[353px] sm:w-[453px]",
                }}
                backgroundColor={"white"}
                onEnd={(e) => {
                  handleFormValues("signatureMaker", sigPadMaker.toDataURL());
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Observations;
