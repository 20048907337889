import React from "react";
import { useForm, Controller } from "react-hook-form";
import { withRouter, useHistory, Redirect } from "react-router-dom";
import { TextField, Button, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import * as Sentry from "@sentry/browser";
import styles from "./LoginPage.module.scss";
import logo from "../../assets/images/logo.png";
import { useGlobal } from "../../context/GlobalContext";
import { useAuth } from "../../context/AuthContext";
import ConfirmDialog from "../Shared/ConfirmDialog";
import authentication from "../../services/authentication";
import { BrandWindows } from "tabler-icons-react";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Correo electrónico inválido")
    .required("Correo electrónico requerido"),
  password: yup.string().required("Contraseña requerida"),
});

const LoginPage = () => {
  const allowExternalLogin =
    process.env.REACT_APP_ALLOW_EXTERNAL_LOGIN === "true";
  const isFederated = process.env.REACT_APP_FEDERATED_LOGIN === "true";
  const version = process.env.REACT_APP_VERSION;
  const history = useHistory();
  const [resetPasswordDialog, showResetPasswordDialog] = React.useState(false);
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, control, errors } = methods;

  const resetFormMethods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email("Correo electrónico inválido")
          .required("Correo electrónico requerido"),
      })
    ),
  });

  const {
    handleSubmit: handleSubmitReset,
    control: controlReset,
    errors: errorsReset,
  } = resetFormMethods;

  const onSubmit = (data) => {
    handleLogin(data);
  };

  const { setLoading, isLoading, showSnackBar } = useGlobal();
  const { doLogin, isAuthenticated, handleMicrosoftLogin } = useAuth();

  // isAuthenticated is true navigate to home
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const handleLogin = (data) => {
    setLoading(true);
    localStorage.setItem("isFederated", "false");
    data.email = data.email.trim();
    data.password = data.password.trim();
    doLogin(data.email, data.password)
      .then((result) => {
        const displayName = result.displayName;
        const emailAddress = result.email;
        showSnackBar(`Hola ${displayName || emailAddress}!`, "success");
        Sentry.setUser({ email: emailAddress, username: displayName });
        history.push("/");
      })
      .catch((reason) => {
        console.error(reason);
        showSnackBar(`No fue posible iniciar sesión`, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetPassword = () => {
    showResetPasswordDialog(true);
  };

  const onSubmitReset = (data) => {
    setLoading(true);
    authentication
      .resetPassword(data.email)
      .then(() => {
        showResetPasswordDialog(false);
        showSnackBar(
          `Se ha enviado un correo electrónico a ${data.email}`,
          "success"
        );
        setLoading(false);
      })
      .catch((reason) => {
        console.error(reason);
        showSnackBar(`No fue posible enviar el correo electrónico`, "error");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderEmailPasswordForm = () => {
    return (
      <>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          as={TextField}
          label="Correo electrónico"
          variant="outlined"
          error={errors.email ? true : false}
          helperText={errors.email?.message}
          width={1}
          inputProps={{
            style: { textTransform: "lowercase !important" },
          }}
          autoComplete="username"
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          label="Contraseña"
          variant="outlined"
          type="password"
          error={errors.password ? true : false}
          helperText={errors.password?.message}
          width={1}
          as={TextField}
          autoComplete="current-password"
          inputProps={{
            style: { textTransform: "lowercase !important" },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          size="large"
        >
          Iniciar Sesión
        </Button>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={() => resetPassword()}
        >
          ¿Olvidaste tu contraseña?
        </Button>
      </>
    );
  };

  const renderFederatedLogin = () => (
    <>
      {allowExternalLogin === true && <>{renderEmailPasswordForm()}</>}
      <Button
        variant="outlined"
        startIcon={<BrandWindows />}
        fullWidth
        size="large"
        className="loginButton bg-blue-300"
        onClick={() => {
          setLoading(true);
          localStorage.setItem("isFederated", "true");
          handleMicrosoftLogin();
        }}
      >
        Iniciar Sesión con Microsoft 365
      </Button>
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <form
          className={styles.loginForm}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="flex flex-1 w-full flex-col text-center content-center items-center">
            <Typography
              component="h1"
              gutterBottom
              className="select-none !font-bold text-md"
            >
              SISTEMA DE INFORMACIÓN
            </Typography>
            <div className="flex flex-1 flex-row w-full text-center justify-center">
              <Typography
                component="h1"
                gutterBottom
                color="primary"
                className="select-none !font-bold text-xl"
              >
                RS
              </Typography>
              <div className="w-1"></div>
              <Typography
                component="h1"
                gutterBottom
                className="select-none !font-bold text-xl"
              >
                P
              </Typography>
              <Typography
                component="h1"
                gutterBottom
                color="primary"
                className="select-none !font-bold text-xl"
              >
                ASI
              </Typography>
              <Typography
                component="h1"
                gutterBottom
                className="select-none !font-bold text-xl"
              >
                ENTES
              </Typography>
            </div>
            <img src={logo} alt="" className="w-[180px]" />
          </div>
          {isFederated === true
            ? renderFederatedLogin()
            : renderEmailPasswordForm()}
        </form>
        <div className="fixed bottom-2 left-2 select-none">
          <span className="text-xs text-gray-500">Versión {version}</span>
        </div>
      </div>
      <div className={styles.heroContainer}></div>
      {resetPasswordDialog && (
        <ConfirmDialog
          open={resetPasswordDialog}
          handleClose={() => showResetPasswordDialog(false)}
          elementTitle={"¿Olvidaste tu contraseña?"}
          defaultTitle={false}
          selfAction={true}
          disableEscapeKeyDown={false}
        >
          <form onSubmit={handleSubmitReset(onSubmitReset)} noValidate>
            <div className="flex flex-col w-full">
              <Typography
                component="h4"
                gutterBottom
                className="select-none !font-bold text-md"
              >
                Vamos a enviarte un correo electrónico para que puedas
                restablecer tu contraseña.
              </Typography>
              <Controller
                name="email"
                control={controlReset}
                defaultValue=""
                as={TextField}
                label="Correo electrónico"
                variant="outlined"
                error={errorsReset.email ? true : false}
                helperText={errorsReset.email?.message}
                width={1}
                inputProps={{
                  style: { textTransform: "lowercase !important" },
                }}
                autoComplete="username"
              />
              <div className="resetActions">
                <Button
                  variant="contained"
                  color="default"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    showResetPasswordDialog(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Enviar
                </Button>
              </div>
            </div>
          </form>
        </ConfirmDialog>
      )}
    </div>
  );
};

export default withRouter(LoginPage);
