import { Button, Checkbox, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

//Renders
const renderStatus = (status) => {
  return (
    <Checkbox
      label={status ? "Activo" : "Inactivo"}
      checked={status}
      disabled
    />
  );
};

const renderActions = (
  index,
  familyList,
  removeFamilyMember,
  toggleSetEditFamily
) => {
  return (
    <div>
      <IconButton onClick={() => removeFamilyMember(familyList[index])}>
        <DeleteIcon />
      </IconButton>
      <IconButton onClick={() => toggleSetEditFamily(familyList[index])}>
        <EditIcon />
      </IconButton>
    </div>
  );
};

const columnsFamily = (familyList, removeFamilyMember, toggleSetEditFamily) => {
  return [
    {
      label: "Acciones",
      name: "actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return renderActions(
            dataIndex,
            familyList,
            removeFamilyMember,
            toggleSetEditFamily
          );
        },
      },
    },
    {
      label: "Nombre",
      name: "familyName",
      options: {
        setCellProps: () => {
          return {
            style: {
              textTransform: "uppercase",
            },
          };
        },
      },
    },
    {
      label: "Edad",
      name: "familyAge",
      options: {
        setCellProps: () => {
          return {
            style: {
              textTransform: "uppercase",
            },
          };
        },
      },
    },
    {
      label: "Documento",
      name: "familyDocument",
      options: {
        setCellProps: () => {
          return {
            style: {
              textTransform: "uppercase",
            },
          };
        },
      },
    },
    {
      label: "Parentesco",
      name: "familyParent",
      options: {
        setCellProps: () => {
          return {
            style: {
              textTransform: "uppercase",
            },
          };
        },
      },
    },
    {
      label: "Ocupación",
      name: "familyOccupation",
      options: {
        setCellProps: () => {
          return {
            style: {
              textTransform: "uppercase",
            },
          };
        },
      },
    },
    {
      label: "Teléfono",
      name: "familyPhone",
      options: {
        setCellProps: () => {
          return {
            style: {
              textTransform: "uppercase",
            },
          };
        },
      },
    },
    {
      label: "Aporta económicamente al sustento del hogar",
      name: "familyEconomy",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return renderStatus(familyList[dataIndex].familyEconomy);
        },
      },
    },
  ];
};

const optionsFamily = (toggleSetAddFamily) => {
  return {
    filter: false,
    download: false,
    print: false,
    jumpToPage: false,
    pagination: false,
    search: false,
    sort: false,
    selectableRows: "none",
    viewColumns: false,
    customToolbar: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<PersonAddIcon />}
          onClick={toggleSetAddFamily}
        >
          Agregar Familiar
        </Button>
      );
    },
    textLabels: {
      body: {
        noMatch: "No se han registrado familiares para este paciente",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente Página",
        previous: "Página Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar",
        print: "Imprimir",
        viewColumns: "Columnas",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Columnas",
      },
      selectedRows: {
        text: "fila(s) seleccionada(s)",
        delete: "Eliminar",
        deleteAria: "Eliminar Filas Seleccionadas",
      },
    },
  };
};

export { optionsFamily, columnsFamily };
