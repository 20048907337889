import { createRoot } from "react-dom/client";
import "@fontsource/roboto";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import reportWebVitals from "./reportWebVitals";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import es from "date-fns/locale/es";
import "moment/locale/es";
import { GlobalProvider } from "./context/GlobalContext";
import { AuthProvider } from "./context/AuthContext";
import * as Sentry from "@sentry/react";
import ModalProvider from "mui-modal-provider";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./index.scss";
import { RemoteConfigProvider } from "./context/RemoteConfigContext";

const POSITION = {
  vertical: "bottom",
  horizontal: "center",
};
const AUTO_HIDE_DURATION = 2000;
const MAX_SNACK = 3;

const localeMap = {
  es: es,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
});

const toggleDevtools = localStorage.getItem("toggleDevtools") === "true";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <RemoteConfigProvider>
      {toggleDevtools === true && <ReactQueryDevtools initialIsOpen />}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]}>
        <SnackbarProvider
          maxSnack={MAX_SNACK}
          autoHideDuration={AUTO_HIDE_DURATION}
          anchorOrigin={POSITION}
        >
          <ModalProvider legacy={true}>
            <GlobalProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </GlobalProvider>
          </ModalProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </RemoteConfigProvider>
  </QueryClientProvider>
);

reportWebVitals();
