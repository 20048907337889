import { isUndefined } from "lodash";
import Bar from "../../components/Bar";
import { useAuth } from "../../context/AuthContext";
import { firebaseDate } from "../utils";
import { useGlobal } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom";

const Main = ({ children }) => {
  const isFederated = localStorage.getItem("isFederated") === "true";
  const { user, isAuthenticated } = useAuth();
  const { showSnackBar } = useGlobal();

  const history = useHistory();

  const barRender = () => {
    if (
      window.location.pathname.toUpperCase().indexOf("ENCUESTA") > -1 ||
      window.location.pathname.toUpperCase().indexOf("SIGNATURE") > -1
    ) {
      return "";
    } else {
      return isAuthenticated ? <Bar /> : null;
    }
  };

  const checkRole = () => {
    return (
      user.role === "laboratorio" ||
      user.role === "ucl" ||
      user.role === "aseguradora" ||
      user.role === "medical" ||
      user.role === "acceso" ||
      user.role === "comercial"
    );
  };

  const checkRoleNot = () => {
    return (
      user.role !== "laboratorio" &&
      user.role !== "ucl" &&
      user.role !== "aseguradora" &&
      user.role !== "medical" &&
      user.role !== "acceso" &&
      user.role !== "comercial"
    );
  };

  const isResetPassword = () => {
    return window.location.pathname.toUpperCase().indexOf("RESETPASSWORD") > -1;
  };

  const isLogin = () => {
    return window.location.pathname.toUpperCase().indexOf("LOGIN") > -1;
  };

  const checkPasswordLastUpdate = (children) => {
    if (isFederated) {
      return children;
    }
    if (!isResetPassword() && !isLogin()) {
      if (!isUndefined(user?.lastPasswordUpdate)) {
        const lastPasswordUpdate = firebaseDate(user.lastPasswordUpdate);
        const now = new Date();
        const diff = now.getTime() - lastPasswordUpdate.getTime();
        const days = diff / (1000 * 60 * 60 * 24);
        if (days > 90) {
          showSnackBar(
            "Su contraseña tiene más de 90 días de antigüedad, por favor actualícela",
            "error"
          );
          history.push("/resetPassword?from=router");
        } else {
          return children;
        }
      } else {
        showSnackBar("Su contraseña debe ser actualizada", "error");
        history.push("/resetPassword?from=router");
      }
    } else {
      return children;
    }
  };

  return (
    <div
      className={`${
        user && checkRole()
          ? ""
          : isResetPassword()
          ? ""
          : "mb-14 sm:!mt-10 sm:ml-12"
      }`}
    >
      {user && checkRoleNot() && barRender()}
      {checkPasswordLastUpdate(children)}
    </div>
  );
};

export default Main;
