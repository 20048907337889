import React from "react";
import PropTypes from "prop-types";
import { ErrorBoundary as ErrorComponent } from "react-error-boundary";
import EmptyState from "../EmptyState";
import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { useGlobal } from "../../context/GlobalContext";

const ErrorBoundary = (props) => {
  const { children } = props;
  const { setLoading } = useGlobal();

  const FallbackComponent = () => {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Algo salió mal"
        description="No fue posible cargar la aplicación"
        button={{
          label: "Volver a inicio",
          action: () => {
            window.location.href = "/";
          }
        }}
      />
    );
  };

  const errorHandler = (error, info) => {
    setLoading(false);
    console.error("ERROR LOGGER: ", error, " ERROR INFO: ", info);
  };

  return (
    <ErrorComponent
      FallbackComponent={FallbackComponent}
      onError={errorHandler}
    >
      {children}
    </ErrorComponent>
  );
};

ErrorBoundary.propTypes = {
  // Properties
  children: PropTypes.any,
};

export default ErrorBoundary;
