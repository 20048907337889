import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Checkbox, Box } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import styles from "../Details.module.scss";
import { isEmpty, isUndefined } from "lodash";

const HousingConditions = (props) => {
  //Hooks and Vars
  const { handleFormValues, patientInfo, register } = props;

  const defaults = {
    movementForm: patientInfo.movementForm,
    observations: patientInfo.movementForm,
    conditionsList: [
      {
        name: "Pavimentada",
        excelente: false,
        buena: false,
        regular: false,
        mala: false,
      },
      {
        name: "Destapada",
        excelente: false,
        buena: false,
        regular: false,
        mala: false,
      },
      {
        name: "Pendiente y no llegan vehículos",
        excelente: false,
        buena: false,
        regular: false,
        mala: false,
      },
    ],
  };

  //Hooks and Vars
  // const [conditionsList, setConditionsList] = useState(
  //   typeof patientInfo.conditionsList !== "undefined" &&
  //     typeof patientInfo.conditionsList !== "object"
  //     ? JSON.parse(
  //         patientInfo.conditionsList || JSON.stringify(defaults.conditionsList)
  //       ) || defaults.conditionsList
  //     : patientInfo.conditionsList || defaults.conditionsList
  // );

  const [conditionsList, setConditionsList] = useState(
    !isUndefined(patientInfo.conditionsList) &&
      !isEmpty(patientInfo.conditionsList) &&
      typeof patientInfo.conditionsList !== "object"
      ? JSON.parse(patientInfo.conditionsList).length === 0
        ? defaults.conditionsList
        : JSON.parse(patientInfo.conditionsList) || defaults.conditionsList
      : patientInfo.conditionsList || defaults.conditionsList
  );

  //Handlers

  const updateConditions = (item, index, field) => {
    let newArr = [...conditionsList];
    newArr[index][field] = item;
    setConditionsList(newArr);
    handleFormValues("conditionsList", JSON.stringify(newArr));
  };

  const handleConditions = (item, index, field) => {
    item = !item;
    updateConditions(item, index, field);
  };

  //Renders
  const renderCondition = (item, index, field) => {
    return (
      <Checkbox
        onChange={() => handleConditions(item[field], index, field)}
        checked={item[field]}
      ></Checkbox>
    );
  };

  //Effects

  //Settings

  const optionsCondition = {
    filter: false,
    download: false,
    print: false,
    jumpToPage: false,
    pagination: false,
    search: false,
    sort: false,
    selectableRows: "none",
    viewColumns: false,
  };

  const columnsCondition = [
    {
      label: "Estado de las Vias de Acceso",
      name: "name",
    },
    {
      label: "Excelente",
      name: "excelente",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return renderCondition(
            conditionsList[dataIndex],
            dataIndex,
            "excelente"
          );
        },
      },
    },
    {
      label: "Buena",
      name: "buena",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return renderCondition(conditionsList[dataIndex], dataIndex, "buena");
        },
      },
    },
    {
      label: "Regular",
      name: "regular",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return renderCondition(
            conditionsList[dataIndex],
            dataIndex,
            "regular"
          );
        },
      },
    },
    {
      label: "Mala",
      name: "mala",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return renderCondition(conditionsList[dataIndex], dataIndex, "mala");
        },
      },
    },
  ];

  return (
    <Box p={3} width={1} className={styles.gridFamily}>
      <MUIDataTable
        elevation={4}
        data={conditionsList}
        columns={columnsCondition}
        options={optionsCondition}
      />

      <Box p={3} width={1}>
        <TextField
          name="movementForm"
          label="Forma de movilización para acceder a servicios de salud."
          variant="outlined"
          width={1}
          inputRef={register({ required: false })}
          style={{ width: "100%" }}
          defaultValue={patientInfo.movementInfo}
          margin="normal"
          onChange={(e) => {
            handleFormValues("movementForm", e.target.value.toUpperCase());
          }}
        />
        <TextField
          name="observationsConditions"
          label="Observaciones"
          variant="outlined"
          width={1}
          style={{ width: "100%" }}
          defaultValue={patientInfo.observationsConditions}
          onChange={(e) =>
            handleFormValues("observationsConditions", e.target.value)
          }
          helperText={`${
            patientInfo.observationsConditions
              ? patientInfo.observationsConditions.length
              : 0
          }/100`}
          inputProps={{ maxLength: 100, texttransform: "uppercase" }}
          margin="normal"
          inputRef={register({ required: false })}
        />
      </Box>
    </Box>
  );
};

HousingConditions.propTypes = {
  changeTab: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default HousingConditions;
