import { Box, FormControlLabel, Switch, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { externalHelpsList } from "../Constants/ExternalHelpsList";

const ExternalHelps = (props) => {
  const { patientInfo, handleFormValues, getValues, control, errors, watch } =
    props;

  return (
    <Box p={3} className={"gridContainerBase"}>
      {externalHelpsList.map((item, index) => {
        return (
          <div
            key={index}
            className={
              "grid grid-cols-1 md:grid-cols-3 gap-2 w-full border border-gray-300 p-2"
            }
          >
            <FormControlLabel
              key={index}
              control={
                <Switch
                  checked={patientInfo[item.name]}
                  onChange={(e) => {
                    handleFormValues(item.name, e.target.checked);
                  }}
                  name={item.name}
                  color="primary"
                />
              }
              label={item.label}
            />
            <Controller
              name={`${item.name}Which`}
              control={control}
              defaultValue={patientInfo[`${item.name}Which`]}
              label="¿Cual?"
              variant="outlined"
              width={1}
              as={TextField}
              helperText={errors[`${item.name}Which`]?.message}
              error={errors[`${item.name}Which`] ? true : false}
              disabled={!getValues(item.name)}
            />
            <Controller
              name={`${item.name}HelpInstitution`}
              control={control}
              defaultValue={patientInfo[`${item.name}HelpInstitution`]}
              label="Institución de Apoyo"
              variant="outlined"
              width={1}
              as={TextField}
              helperText={errors[`${item.name}HelpInstitution`]?.message}
              error={errors[`${item.name}HelpInstitution`] ? true : false}
              disabled={!getValues(item.name)}
            />
          </div>
        );
      })}
      <Box
        style={{
          padding: "0px 24px 0px 24px",
        }}
      >
        <Controller
          name="observationsExtHelp"
          control={control}
          defaultValue={patientInfo.observationsExtHelp}
          label="Observaciones"
          variant="outlined"
          width={1}
          multiline={true}
          minRows={3}
          style={{ width: "100%" }}
          as={TextField}
          error={errors?.observationsExtHelp ? true : false}
          helperText={`${
            watch("observationsExtHelp")
              ? watch("observationsExtHelp").length
              : 0
          }/400`}
          inputProps={{ style: { maxLength: 400, textTransform: "uppercase" } }}
        />
      </Box>
    </Box>
  );
};

export default ExternalHelps;
