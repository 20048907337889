import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton, Button,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";
import { isEmpty } from "lodash";
import useData from "../../hooks/useData";
import {AppInfo} from "../Shared/AppInfo";
import {useHistory} from "react-router-dom";

const AboutDialog = (props) => {
  const version = process.env.REACT_APP_VERSION;
  const history = useHistory();
  const { data, isLoading } = useData("changelog", "version", version);

  const showFullChangelog = () => {
    history.push("/changelog");
    props.onClose();
  }

  return (
    <Dialog fullWidth maxWidth="xs" {...props}>
      <DialogTitle className={"flex flex-1 flex-row gap-4 w-full justify-between items-center"} disableTypography>
        <Typography variant="h6">Acerca de</Typography>
        <Tooltip title="Close" className={"justify-end"}>
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent className={"flex flex-1 flex-col gap-2"}>
        <AppInfo />
        <Typography variant="h5">Notas de Version {version}</Typography>
        {!isLoading && (
          <div>
            {!isEmpty(data[0]) && (
              <div>
                <div>
                  <Typography variant="h6">Nuevo: </Typography>
                  <ul className="list-disc gap-1">
                    {data[0].changes.map((change, index) => (
                      <li className="m-2" key={index}>
                        {change}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <Typography variant="h6">Ajustes: </Typography>
                  <ul className="list-disc">
                    {data[0].fixes.map((fix, index) => (
                      <li className="m-2" key={index}>
                        {fix}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
        <Button variant="contained" color="primary" onClick={showFullChangelog}>
          Ver historial de cambios
        </Button>
      </DialogContent>
    </Dialog>
  );
}

AboutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

export default AboutDialog;
