import { useCallback, useEffect, useState } from "react";
import { Button, CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { useGlobal } from "./context/GlobalContext";
import Router from "./components/Router";
import ErrorBoundary from "./components/ErrorBoundary";
import ChangelogDialog from "./components/ChangelogDialog";
import * as serviceWorker from "./serviceWorker";
import { useModal } from "mui-modal-provider";
import { isEmpty, isUndefined } from "lodash";
import useData from "./hooks/useData";

const App = () => {
  //Hooks
  const version = process.env.REACT_APP_VERSION;
  const { data, isLoading } = useData("changelog", "version", version);
  const { theme, showSnackBar } = useGlobal();
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [showChangelog, setShowChangelog] = useState(
    !isUndefined(localStorage.getItem("changelog"))
      ? localStorage.getItem("changelog") !== process.env.REACT_APP_VERSION
      : true
  );
  const { showModal } = useModal();

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    window.location.reload(true);
  }, [waitingWorker]);

  const action = useCallback(
    () => <Button onClick={reloadPage}>Actualizar</Button>,
    [reloadPage]
  );

  const onSWUpdate = useCallback(
    (registration) => {
      showSnackBar("Hay una nueva version disponible", {
        variant: "info",
        persist: true,
        action: action,
      });
      setWaitingWorker(registration.waiting);
    },
    [showSnackBar, action]
  );

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, [onSWUpdate, showSnackBar, action]);

  useEffect(() => {
    if (!isLoading && showChangelog && !isEmpty(data)) {
      showModal(ChangelogDialog, {
        version: version,
        changes: data[0].changes,
        fixes: data[0].fixes,
      });
      localStorage.setItem("changelog", process.env.REACT_APP_VERSION);
      setShowChangelog(false);
    }
  }, [showChangelog, showModal, version, isLoading, data]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </MuiThemeProvider>
  );
};

export default App;
