import styles from "./ResetPassword.module.scss";
import logo from "../../assets/images/logo.png";
import { TextField, Button, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useGlobal } from "../../context/GlobalContext";
import authentication from "../../services/authentication";
import { useHistory } from "react-router-dom";
import { useUrlQuery } from "../../global/hooks";
import firebase from "firebase/compat/app";
import { useAuth } from "../../context/AuthContext";
import { isUndefined } from "lodash";
import dataService from "../../services/dataService";

const ResetPassword = () => {
  let query = useUrlQuery();
  const oobCode = query.get("oobCode");
  const from = query.get("from");
  let schema = {};

  if (!isUndefined(from)) {
    schema = yup.object().shape({
      email: yup
        .string()
        .email("Correo electrónico inválido")
        .required("Correo electrónico requerido"),
      currentPassword: yup.string().required("Contraseña requerida"),
      password: yup
        .string()
        .min(6, "La contraseña debe tener al menos 6 caracteres")
        .required("Contraseña requerida"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
        .required("Confirmar contraseña requerida"),
    });
  } else {
    schema = yup.object().shape({
      email: yup
        .string()
        .email("Correo electrónico inválido")
        .required("Correo electrónico requerido"),
      password: yup
        .string()
        .min(6, "La contraseña debe tener al menos 6 caracteres")
        .required("Contraseña requerida"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
        .required("Confirmar contraseña requerida"),
    });
  }

  const history = useHistory();
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, control, errors, reset } = methods;
  const { setLoading, isLoading, showSnackBar } = useGlobal();
  const { auth, doLogout } = useAuth();

  const updateUser = async () => {
    await dataService.updateDocument(
      {
        lastPasswordUpdate: new Date(),
        id: auth.currentUser.uid,
      },
      "users"
    );
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (!oobCode && !from) {
      showSnackBar(`No fue posible actualizar la contraseña`, "error");
      return;
    }
    if (oobCode) {
      authentication
        .verifyPasswordResetCode(oobCode, data.email, data.password)
        .then(() => {
          showSnackBar("Contraseña actualizada correctamente", "success");
          history.push("/login");
        })
        .catch((reason) => {
          console.error(reason);
          showSnackBar(`No fue posible actualizar la contraseña`, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (from) {
      const userContext = auth.currentUser;
      // user is logged in and exists
      if (!userContext) {
        setLoading(false);
        showSnackBar("Error al actualizar contraseña", "error");
        return;
      }

      // email is the same
      if (data.email.toLowerCase() !== userContext.email.toLowerCase()) {
        setLoading(false);
        showSnackBar("El correo electrónico no coincide", "error");
        return;
      }

      // both passwords are the same
      if (data.password === data.currentPassword) {
        setLoading(false);
        showSnackBar(
          "La nueva contraseña debe ser diferente a la actual",
          "error"
        );
        return;
      }

      // both passwords are different
      if (data.password !== data.confirmPassword) {
        setLoading(false);
        showSnackBar("Las contraseñas nuevas no coinciden", "error");
        return;
      }

      userContext
        .reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(
            data.email,
            data.currentPassword
          )
        )
        .then(() => {
          userContext
            .updatePassword(data.password)
            .then(async () => {
              await updateUser();
              reset();
              showSnackBar(
                "Contraseña actualizada, por su seguridad vuelva a iniciar sesión",
                "success"
              );
              doLogout()
                .then(() => {
                  history.push("/login");
                  setLoading(false);
                })
                .catch((error) => {
                  console.error("Logout: ", error);
                  showSnackBar("Error al cerrar sesión", "error");
                  setLoading(false);
                });
            })
            .catch((error) => {
              console.error("Update password: ", error);
              showSnackBar("Error al actualizar contraseña", "error");
              setLoading(false);
            });
        })
        .catch((error) => {
          console.error("Reauthenticate: ", error);
          showSnackBar("La contraseña actual es incorrecta", "error");
          setLoading(false);
        });
    }
  };

  return (
    <div className="w-full">
      <div className={styles.loginContainer}>
        {!isLoading && (
          <form
            className={styles.loginForm}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <img src={logo} alt="" />
            {!isUndefined(from) && (
              <Typography variant="h5" className={styles.title}>
                Por política de seguridad, debe actualizar su contraseña cada 90
                días.
              </Typography>
            )}
            <Controller
              name="email"
              control={control}
              defaultValue=""
              as={TextField}
              label="Confirme su Correo electrónico"
              variant="outlined"
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              width={1}
              inputProps={{
                style: { textTransform: "lowercase !important" },
              }}
              id="newEmail"
              autocomplete="off"
            />
            {!isUndefined(from) && (
              <Controller
                name="currentPassword"
                control={control}
                defaultValue=""
                as={TextField}
                label="Contraseña actual"
                variant="outlined"
                type="password"
                error={errors.currentPassword ? true : false}
                helperText={errors.currentPassword?.message}
                width={1}
                id="currentPassword"
                autocomplete="off"
              />
            )}

            <Controller
              name="password"
              control={control}
              defaultValue=""
              as={TextField}
              label="Nueva contraseña"
              variant="outlined"
              type="password"
              error={errors.password ? true : false}
              helperText={errors.password?.message}
              width={1}
              id="newPs"
              autocomplete="off"
            />
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              as={TextField}
              label="Confirmar nueva contraseña"
              variant="outlined"
              type="password"
              error={errors.confirmPassword ? true : false}
              helperText={errors.confirmPassword?.message}
              width={1}
              id="confirmNewPs"
              autocomplete="off"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              size="large"
            >
              Reiniciar contraseña
            </Button>
          </form>
        )}
      </div>
      <div className={styles.heroContainer}></div>
    </div>
  );
};

export default ResetPassword;
