import DialogComponent from "./DialogComponent";
import { Button } from "@material-ui/core";

const ConfirmDialog = (props) => {
  const {
    open,
    handleClose,
    elementTitle,
    doAction,
    content,
    defaultTitle = true,
    children,
    selfAction = false,
    disableEscapeKeyDown = true,
    handleCancel,
    isButtonWarning = true,
  } = props;
  return (
    <DialogComponent
      open={open}
      closeDialog={() => {
        handleClose();
      }}
      title={`${defaultTitle ? "¿Desea eliminar " : ""}${elementTitle} ${
        defaultTitle ? "?" : ""
      }`}
      isForm={false}
      maxWidth="md"
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEscapeKeyDown={disableEscapeKeyDown ?? true}
      actions={
        !selfAction && (
          <div>
            <Button
              variant="contained"
              color="default"
              style={{ marginRight: "10px" }}
              onClick={() => {
                if (handleCancel) handleCancel();
                handleClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color={isButtonWarning ? "default" : "primary"}
              onClick={() => {
                handleClose();
                if (doAction) doAction();
              }}
              className={isButtonWarning ? "buttonWarning" : ""}
            >
              Aceptar
            </Button>
          </div>
        )
      }
    >
      <div>
        {content && <h4>{content}</h4>}
        {children}
      </div>
    </DialogComponent>
  );
};

export default ConfirmDialog;
