import firebaseDate from "./firebaseDate";

const reorderPatient = (patientData) => {
  let resultItem = [];
  let result = {};
  const patientKeys = [
    "currentPatient",
    "currentHouseInfo",
    "currentObservationsInfo",
    "currentHousingConditions",
    "currentCarerInfo",
    "currentInitialStatus",
    "familyInfo",
    "externalHelps",
  ];
  patientKeys.forEach((key) => {
    resultItem.push(patientData[key]);
  });
  if (resultItem === null || typeof resultItem === "undefined") {
    return;
  }
  resultItem.forEach((item, index) => {
    if (typeof item !== "undefined" && item !== null) {
      Object.keys(item).forEach((key) => {
        if (index === 6 && key === "documentTypeCare") {
          return (result["familyType"] = item[key]);
        } else if (index === 4 && key === "ageTime") {
          return;
        } else if (
          index === 2 &&
          (key === "observationsInfo" ||
            key === "observationsFinal" ||
            key === "observations")
        ) {
          if (item[key] !== "") {
            result["observationsFinal"] = item[key];
          }
        } else if (
          index === 0 &&
          (key === "observationsInfo" ||
            key === "observationsInitial" ||
            key === "observations" ||
            key === "observationsFinal")
        ) {
          if (item[key] !== "") {
            result["observations"] = item[key];
          }
        } else if (index === 3 && key === "observations") {
          if (item[key] === "") {
            result["observationsConditions"] = item["observationsConditions"];
          } else {
            result["observationsConditions"] = item[key];
          }
        } else if (key === "state" || key === "statehouse") {
          Object.keys(item[key]).forEach((keyState) => {
            result[keyState] = item[key][keyState];
          });
        } else {
          if (
            key === "bornDate" ||
            key === "dxDate" ||
            key === "treatmentStart" ||
            key === "fillingDate" ||
            key === "pspStartDate" ||
            (key.startsWith("vaccine") && key.endsWith("_fecha"))
          ) {
            result[key] = firebaseDate(item[key], key);
          } else {
            result[key] = item[key];
          }
        }
      });
    }
  });
  if (typeof result["needCarer"] === "undefined") {
    result["needCarer"] = false;
  }
  return result;
};

export default reorderPatient;
