import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  AppBar,
  Toolbar,
} from "@material-ui/core";

function DialogComponent(props) {
  return (
    <Dialog
      PaperProps={props.PaperProps}
      open={props.open}
      maxWidth={props.maxWidth ?? false}
      fullWidth={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          props.closeDialog();
        }
      }}
      disableEscapeKeyDown={props.disableEscapeKeyDown ?? true}
      fullScreen={props.fullScreen ?? false}
    >
      {props.showBar === true && props.title && props.actionsPosition === "up" && (
        <AppBar position="fixed" color="default">
          <Toolbar style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <span
              style={{
                width: "650px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={props.title?.toUpperCase()}
            >
              {props.title?.toUpperCase()}
            </span>
            <div style={{ justifySelf: "end" }}>{props.actions}</div>
          </Toolbar>
        </AppBar>
      )}
      {props.title && props.actionsPosition === "down" && (
        <DialogTitle>{props.title}</DialogTitle>
      )}
      {props.isForm === true && props.children}

      {props.isForm === false && (
        <div
          style={{
            marginTop:
              props.actionsPosition === "up"
                ? `${props.customMargin}px`
                : "1px",
          }}
        >
          <DialogContent>{props.children}</DialogContent>
          {props.actionsPosition === "down" && (
            <DialogActions>{props.actions}</DialogActions>
          )}
        </div>
      )}
    </Dialog>
  );
}

DialogComponent.defaultProps = {
  actionsPosition: "down",
  customMargin: "80",
  showBar: true,
};

DialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func,
  title: PropTypes.string,
  isForm: PropTypes.bool.isRequired,
  actions: PropTypes.element,
  maxWidth: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  children: PropTypes.element.isRequired,
  actionsPosition: PropTypes.string,
};

export default DialogComponent;
