import { createContext, useContext, useEffect, useState } from "react";
import { defaultRemoteConfigValues } from "../data/defaultRemoteConfigValues";
import { remoteConfig } from "../firebase";

const RemoteConfigContext = createContext({
  config: {},
  Loading: true,
});

export const RemoteConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(defaultRemoteConfigValues);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAndActivateConfig = async () => {
      try {
        await remoteConfig.fetchAndActivate();
        const fetchedConfigValues = defaultRemoteConfigValues;

        Object.keys(defaultRemoteConfigValues).forEach((key) => {
          const rawValue = remoteConfig.getValue(key);
          const rawValueLower = rawValue.asString().toLowerCase();
          if (rawValueLower === "true" || rawValueLower === "false") {
            // Boolean
            fetchedConfigValues[key] = rawValue.asBoolean();
          } else if (!isNaN(Number(rawValue)) && rawValueLower.trim() !== "") {
            // Number
            fetchedConfigValues[key] = rawValue.asNumber();
          } else {
            // Default to string
            fetchedConfigValues[key] = rawValue.asString();
          }
        });

        setConfig((prevConfig) => ({
          ...prevConfig,
          ...fetchedConfigValues,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndActivateConfig();
  }, []);

  return (
    <RemoteConfigContext.Provider value={{ config, loading }}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfig = () => useContext(RemoteConfigContext);
