const preparePatient = (
  patientInfo,
  userUid,
  vaccinesList,
  externalHelpsList
) => {
  return {
    userId: userUid,
    currentPatient: {
      photo: patientInfo.photo || "",
      fillingDate: patientInfo.fillingDate || new Date(),
      pspStartDate: patientInfo.pspStartDate || new Date(),
      programName: patientInfo.programName || "",
      id: patientInfo.id || "",
      status: patientInfo.status || "",
      email: patientInfo.email || "",
      name: patientInfo.name || "",
      age: patientInfo.age || "",
      ageTime: patientInfo.ageTime || "",
      genre: patientInfo.genre || "",
      documentType: patientInfo.documentType || "",
      identification: patientInfo.identification || "",
      bornDate: patientInfo.bornDate || new Date(),
      bornDepartment: patientInfo.bornDepartment || "",
      bornMunicipio: patientInfo.bornMunicipio || "",
      bornCity: patientInfo.bornCity || "",
      maritalStatus: patientInfo.maritalStatus || "",
      zone: patientInfo.zone || "",
      city: patientInfo.city || "",
      department: patientInfo.department || "",
      municipio: patientInfo.municipio || "",
      address: patientInfo.address || "",
      neighborhood: patientInfo.neighborhood || "",
      locality: patientInfo.locality || "",
      strate: patientInfo.strate || "",
      phone: patientInfo.phone || "",
      academic: patientInfo.academic || "",
      academicLevel: patientInfo.academicLevel || "",
      academicInstitutionLevel: patientInfo.academicInstitutionLevel || "",
      occupation: patientInfo.occupation || "",
      observations: patientInfo.observations || "",
      needCarer: patientInfo.needCarer || false,
      // New Fields
      academicInstitution: patientInfo.academicInstitution || "",
      academicType: patientInfo.academicType || "",
      academicHasHelp: patientInfo.academicHasHelp || "",
      academicIsSpecial: patientInfo.academicIsSpecial || "",
      labour: patientInfo.labour || "",
      labourType: patientInfo.labourType || "",
      labourEconomicContributor: patientInfo.labourEconomicContributor || "",
      labourIncome: patientInfo.labourIncome || "",
      patientAssociation: patientInfo.patientAssociation || "",
    },
    currentInitialStatus: {
      diagnosis: patientInfo.diagnosis || "",
      dxDate: patientInfo.dxDate || new Date(),
      dxsupport: patientInfo.dxsupport || "",
      eps: patientInfo.eps || "",
      regimen: patientInfo.regimen || "",
      doctor: patientInfo.doctor || "",
      speciality: patientInfo.speciality || "",
      getTreatment: patientInfo.getTreatment || "",
      notGetTreatment: patientInfo.notGetTreatment || "",
      treatmentStart: patientInfo.treatmentStart || new Date(),
      drugName: patientInfo.drugName || "",
      drugCode: patientInfo.drugCode || "",
      size: patientInfo.size || "",
      sizeInfo: patientInfo.sizeInfo || "",
      weight: patientInfo.weight || "",
      weightInfo: patientInfo.weightInfo || "",
      discapacidad: patientInfo.discapacidad || "",
      centroAplicacion: patientInfo.centroAplicacion || "",
      discapacidadCert: patientInfo.discapacidadCert || "",
      discapacidadType: patientInfo.discapacidadType || "",
      needHelp: patientInfo.needHelp || "",
      techHelp: patientInfo.techHelp || "",

      // New Fields
      dxVariant: patientInfo.dxVariant || "",
      dxIsOrphan: patientInfo.dxIsOrphan || "",
      dxNotSupport: patientInfo.dxNotSupport || "",
      dxMedicalCenter: patientInfo.dxMedicalCenter || "",
      dxApplicationCity: patientInfo.dxApplicationCity || "",
      dxMedicalType: patientInfo.dxMedicalType || "",
      dxMedicalTypeOther: patientInfo.dxMedicalTypeOther || "",
      dxDosis: patientInfo.dxDosis || "",
      dxMedicalFrequency: patientInfo.dxMedicalFrequency || "",
      dxAdministration: patientInfo.dxAdministration || "",
      dxAdministrationOther: patientInfo.dxAdministrationOther || "",
      dxOrganTransplant: patientInfo.dxOrganTransplant || "",
      dxOrganTransplantWhich: patientInfo.dxOrganTransplantWhich || "",
      discapacidadRegistered: patientInfo.discapacidadRegistered || "",

      // Vaccine Fields
      ...vaccinesList?.reduce((acc, vaccine) => {
        acc[`vaccine${vaccine.id}_status`] =
          patientInfo[`vaccine${vaccine.id}_status`] || "";
        acc[`vaccine${vaccine.id}_fecha`] =
          patientInfo[`vaccine${vaccine.id}_fecha`] || new Date();
        acc[`vaccine${vaccine.id}_refuerzo`] =
          patientInfo[`vaccine${vaccine.id}_refuerzo`] || "";
        return acc;
      }, {}),
    },
    externalHelps: {
      ...externalHelpsList?.reduce((acc, help) => {
        acc[`${help.name}`] = patientInfo[`${help.name}`] || false;
        acc[`${help.name}Which`] = patientInfo[`${help.name}Which`] || "";
        acc[`${help.name}HelpInstitution`] =
          patientInfo[`${help.name}HelpInstitution`] || "";
        return acc;
      }, {}),
    },
    familyInfo: {
      familyList:
        typeof patientInfo.familyList === "object"
          ? JSON.stringify(patientInfo.familyList)
          : patientInfo.familyList || "[]",
      familyType: patientInfo.familyType || "",
      personsNumber: patientInfo.personsNumber || "",
      yearRegisterVictim: patientInfo.yearRegisterVictim || "",
      whoFamily: patientInfo.whoFamily || "",
      whatPatology: patientInfo.whatPatology || "",
      whoPatology: patientInfo.whoPatology || "",
      nameTreatment: patientInfo.nameTreatment || "",
      treatmentObservations: patientInfo.treatmentObservations || "",
      state: {
        parteRegistro: patientInfo.parteRegistro || false,
        familiaMismaPatologia: patientInfo.familiaMismaPatologia || false,
        familiaOtrasPatologias: patientInfo.familiaOtrasPatologias || false,
        recibeTratamiento: patientInfo.recibeTratamiento || false,
      },
    },
    currentCarerInfo: {
      carerName: patientInfo.carerName || "",
      documentTypeCare: patientInfo.documentTypeCare || "",
      identificationCare: patientInfo.identificationCare || "",
      parentalCare: patientInfo.parentalCare || "",
      ageCare: patientInfo.ageCare || "",
      epsCare: patientInfo.epsCare || "",
      ageTimeCarer: patientInfo.ageTimeCarer || "",
      regimenCare: patientInfo.regimenCare || "",
      correoCare: patientInfo.correoCare || "",
      educativeCare: patientInfo.educativeCare || "",
      occupationCare: patientInfo.occupationCare || "",
      phoneCare: patientInfo.phoneCare || "",

      // New fields
      carerIsViable: patientInfo.carerIsViable || "",
      carerAbility: patientInfo.carerAbility || "",
      carerObservations: patientInfo.carerObservations || "",
    },
    currentHouseInfo: {
      vivienda: patientInfo.vivienda || "",
      viviendaOtro: patientInfo.viviendaOtro || "",
      nombreVereda: patientInfo.nombreVereda || "",
      observacionVivienda: patientInfo.observacionVivienda || "",
      tenenciaVivienda: patientInfo.tenenciaVivienda || "",
      tenenciaViviendaCual: patientInfo.tenenciaViviendaCual || "",
      statehouse: {
        agua: patientInfo.agua || false,
        luz: patientInfo.luz || false,
        telefono: patientInfo.telefono || false,
        alcantarillado: patientInfo.alcantarillado || false,
        gas: patientInfo.gas || false,
        internet: patientInfo.internet || false,
        basuras: patientInfo.basuras || false,
        educativas: patientInfo.educativas || false,
        bibliotecas: patientInfo.bibliotecas || false,
        transporte: patientInfo.transporte || false,
        salud: patientInfo.salud || false,
        parques: patientInfo.parques || false,
      },
    },
    currentHousingConditions: {
      movementForm: patientInfo.movementForm || "",
      observationsConditions: patientInfo.observationsConditions || "",
      conditionsList: patientInfo.conditionsList || "[]",
    },
    currentObservationsInfo: {
      observationsFinal: patientInfo.observationsFinal || "",
      signature: patientInfo.signature || "",
      // New fields
      observationsViolence: patientInfo.observationsViolence || "",
      observationsPsychosocial: patientInfo.observationsPsychosocial || "",
      signatureMaker: patientInfo.signatureMaker || "",
      observationsHelps: patientInfo.observationsHelps || "",
      hasAttachments: patientInfo.hasAttachments || false,
      hasAttachmentsWhich: patientInfo.hasAttachmentsWhich || "",
    },
    creationDate: new Date(),
    isActive: true,
    hasUser: false,
  };
};

export default preparePatient;
