import { firestore } from "../firebase";
import { useQuery } from "@tanstack/react-query";

const mapCollection = (result) => {
  return result.docs.map((docSnapshot) => {
    let itemEl = docSnapshot.data();
    itemEl.id = docSnapshot.id;
    return itemEl;
  });
};

const getData = async (collection, field, data) => {
  const collectionRef = firestore.collection(collection).where(field, "==", data);
  return collectionRef
    .get()
    .then((result) => {
      return mapCollection(result);
    })
    .catch((error) => {
      console.error(error);
    });
};

export default function useSubData(collection, field, data) {
  return useQuery(
    ["subData", collection, field, data],
    () => getData(collection, field, data),
    {
      enabled: !!data,
    }
  );
}
