import {Typography} from "@material-ui/core";
import logo from "../../assets/images/logo.png";
import React from "react";

export const AppInfo = () => {
    return (<div className="flex flex-1 w-full flex-col text-center content-center items-center">
        <Typography
            component="h1"
            gutterBottom
            className="select-none !font-bold text-md"
        >
            SISTEMA DE INFORMACIÓN
        </Typography>
        <div className="flex flex-1 flex-row w-full text-center justify-center">
            <Typography
                component="h1"
                gutterBottom
                color="primary"
                className="select-none !font-bold text-xl"
            >
                RS
            </Typography>
            <div className="w-1"></div>
            <Typography
                component="h1"
                gutterBottom
                className="select-none !font-bold text-xl"
            >
                P
            </Typography>
            <Typography
                component="h1"
                gutterBottom
                color="primary"
                className="select-none !font-bold text-xl"
            >
                ASI
            </Typography>
            <Typography
                component="h1"
                gutterBottom
                className="select-none !font-bold text-xl"
            >
                ENTES
            </Typography>
        </div>
        <img src={logo} alt="" className="w-[180px]"/>
    </div>)
}
