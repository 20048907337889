import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { isValid } from "date-fns";
import { isFunction, isUndefined } from "lodash";
import { Controller } from "react-hook-form";
import { firebaseDate } from "../../global/utils";

const ControlledDatePicker = (props) => {
  const {
    type,
    label,
    name,
    value,
    errors,
    control,
    handleFormValues,
    onChange,
    ...otherProps
  } = props;
  if (typeof type === "undefined") {
    const dateValue = isValid(value) ? value : firebaseDate(value);
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={dateValue}
        as={({ _onChange, value }) => {
          return (
            <KeyboardDatePicker
              onChange={(date) => {
                handleFormValues(name, date);
                if (!isUndefined(onChange) && isFunction(onChange)) {
                  onChange(date);
                }
              }}
              value={value}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label={label}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              helperText={errors?.[name]?.message}
              error={!!errors?.[name]}
              style={{ width: "100%" }}
              okLabel={"Aceptar"}
              cancelLabel={"Cancelar"}
              {...otherProps}
            />
          );
        }}
        onChange={(val) => {
          return val;
        }}
      />
    );
  } else {
    const timeValue = isValid(value) ? value : new Date(`01/01/2020 ${value}`);
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={timeValue}
        as={({ _onChange, value }) => {
          return (
            <KeyboardTimePicker
              onChange={(date) => {
                handleFormValues(name, date);
              }}
              value={value}
              inputVariant="outlined"
              format="hh:mm"
              label={label}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              helperText={errors?.[name]?.message}
              error={!!errors?.[name]}
              style={{ width: "100%" }}
              okLabel={"Aceptar"}
              cancelLabel={"Cancelar"}
              {...otherProps}
            />
          );
        }}
        onChange={(val) => {
          return val;
        }}
      />
    );
  }
};

export default ControlledDatePicker;
